import React, { useState } from 'react';
import { FaMdb } from 'react-icons/fa';
import TrialInfo from './TrialInfo';
import ParseEligibilityCriteria from './ParseEligibilityCriteria';
import TrialGroups from './TrialGroups';
import TrialElibiligyTable from './TrialElibiligyTable';
import GroupElibiligyTable from './GroupElibiligyTable';
import config from '../config';
import LoadingIcon from './LoadingIcon';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { renderToString } from 'react-dom/server';
const TrialCard = ({ data }) => {

    const [planning, setPlanning] = useState(data);
    const [loadingEligibilityCheck, setLoadingEligibilityCheck] = useState(false);
    const server_url = config.server_url;
    const [detachedWindow, setDetachedWindow] = useState(null);
    const [showData, setShowData] = useState(true); // Initially true
    
    const getFormattedDate = () => {
      const modifiedDate = new Date(planning.modifiedDate);
      const fd = modifiedDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      return fd;
    }


  const findModelTranslation = (model) => {
   // console.log("selected trial model: "+model);
    if (model == 'HUMAN_EXPERT') {
      return "Expert verified";
    }
    if (model == 'OPENAI_MODEL_4') {
      return "TrialMatchLLM-GPT-4-1106";
    }
    if(model=='OPENAI_MODEL_4_TURBO_PREVIEW'){
      return "TrialMatchLLM-GPT-4-turbo";
    }
    if (model == 'OPENAI_MODEL_35') {
      return "TrialMatchLLM-GPT-3.5";
    }
    if (model == 'OPENAI_MODEL_16k') {
      return "TrialMatchLLM-gpt-3.5-turbo-16k";
    }
    if (model == 'FINE_TUNED_GPT_CASE_SHORT_SYSTEM_PROMT') {
      return "TrialMatchLLM-ft:gpt-3.5-turbo-1106:8lCffJTJ";
    }
    if (model == 'MISTRAL_MODEL_MEDIUM') {
      return "TrialMatchLLM-mistral-medium";
    }
    return model;
  }
  
    const openInNewWindow = () => {
      const newWindow = window.open('', '_blank');
    const eligibilityContent = renderToString(
      <ParseEligibilityCriteria trial={planning.trial} showData={true} groups={planning.pgroups}  showIdentifier={true}/>
    );
    newWindow.document.write('<html><head><title>Detached from TrialMatchLLM</title></head><body>');
    newWindow.document.write(eligibilityContent);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    setDetachedWindow(newWindow);
    };

    const handleCheckElligbilityButtonClick = (e) => {
        e.preventDefault();
        setLoadingEligibilityCheck(true);
      //  console.log("click handleCheckElligibilityAgainClick!",  planning.trial);
        const requestID = 0;
        const trialID = planning.trial.id;



        fetch(`${server_url}/api/report/${requestID}/trial/${trialID}/forceAskAgain`)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            setPlanning(prevPlanning => ({
                ...prevPlanning, // Copy previous planning data
                trial: data // Update trial data
              }));
          //  console.log("data should be updated");
          
            setLoadingEligibilityCheck(false);
          })
    
    
      }
    
    

    return (
        <div class="trialCard">
       
        <div  style={{ backgroundColor: 'white', color: 'black', padding: '20px', margin: '8px' }}>

            <h2> <a href={`https://www.clinicaltrials.gov/study/${planning.trial.identifier}`} target='_blank'> {planning.trial.identifier}</a> : {planning.trial.title}</h2>
            <p>    </p>

            <a href={`https://classic.clinicaltrials.gov/ct2/show/${planning.trial.identifier}#studydesc`} target='_blank'>Go to Study Description on ct.gov</a>
            
            <TrialInfo data={planning.explain ? planning.explain : planning.trial.simple} />
            <br></br>


            <TrialGroups data={planning.pgroups} group={planning.groups}  />
            <div style= {{marginTop:'18px'}} className={` webpage-new${detachedWindow ? ' d-none' : ''}`}>
            <button onClick={openInNewWindow}>Annotated Eligibility Text <FaExternalLinkAlt />    </button>
 
            <p style={{color:'gray'}}>Tip: Position the windows next to each other and compare the raw text with the AI plan.</p>
        
            
          
          </div>
<br></br>
<a href={`https://classic.clinicaltrials.gov/ct2/show/${planning.trial.identifier}#eligibility`} target='_blank'>Go to Eligibility on ct.gov</a>

 
{/* 

    <TrialElibiligyTable trial={planning.trial} groups={planning.pgroups} ></TrialElibiligyTable> */}


<div style={{ fontSize: 'small', textAlign: 'right' }}>Version: {findModelTranslation(planning.llmmodel)} - {getFormattedDate()}     </div>

        </div>
        <div  class="webpage-column">
       
        </div>
        </div>
    
        
        )
};


export default TrialCard; 