
import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Col, Alert } from 'reactstrap'; // Import Bootstrap React components

const Home = (pageObj) => {
 
    return (<div>
         <h1 className='header d-md-none  text-center mt-8  text-3xl leading-relaxed mx-8 mb-4' style={{ marginTop: '50px' }} >Trial Match LLM</h1>
      <p className='subtitle d-md-none text-center mb-8 mx-16 leading-relaxed text-lg'>Harnessing LLM for Eligibility Assessment in Clinical Research</p>
     <div className="callout-container">
        <a href="/request" className="btn btn-primary  mb-2 inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 leading-6 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-11    " style={{ marginRight: '10px' }} >Check eligibility</a><a href="/curateTrials" className="btn btn-secondary mb-2">Simplify Trial</a>
        </div>
 
            
    </div>);   
};

export default Home;
