// CaseCardModal.js
import React, { useState, useEffect } from 'react';
import CaseCard from './CaseCard';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faThumbsUp, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from './LoadingIcon';


const server_url = config.server_url;

const CaseCardModal = ({ caseData, showModal, onClose }) => {

    const [structuredData, setStructuredData] = useState(null);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [confirmedCase, setConfirmedCase] = useState(null);
    const [reportID, setReportID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleShowFeedback = () => {
      //console.log("handleShowFeedback");
      setShowFeedbackForm(true);
    }


    const handleFeedbackSubmit = () => {
      // You can implement logic to handle the submitted feedback
      //console.log('Feedback submitted:', structuredData); 
  
      //FIXME verify that biomarker types is provided if there are any biomarker submitted. 

      const storedEmail = localStorage.getItem('userEmail');
      structuredData.email = storedEmail;
  
      fetch(`${server_url}/api/report/${reportID}/feedback/case_description/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(structuredData),
      })
        .then((response) => response.json())
        .then((data) => {
        //  console.log("feedback saved!", data);
          setConfirmedCase(true);
        });
  
  
      // Close the form after handling the feedback
      setShowFeedbackForm(false);
    };

    const handleTypeSelection = (selectedType, index) => {
      const updatedBiomarkers = [...structuredData.biomarkers];
      updatedBiomarkers[index].type = selectedType;
      setStructuredData((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
    };
  
    const handleNameChange = (name, index) => {
      const updatedBiomarkers = [...structuredData.biomarkers];
      updatedBiomarkers[index].name = name;
      setStructuredData((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
    };
  
    const handleValueChange = (name, index) => {
      const updatedBiomarkers = [...structuredData.biomarkers];
      updatedBiomarkers[index].value = name;
      setStructuredData((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
    };

    const addBiomarker = () => {
      setStructuredData((prevCaseD) => ({
        ...prevCaseD,
        biomarkers: [...prevCaseD.biomarkers, { type: '', name: '', value: '' }],
      }));
    }; 

    const handleExtractCaseGoodJob = () => {

      const storedEmail = localStorage.getItem('userEmail');
      var email = storedEmail;
  
      fetch(`${server_url}/api/report/${reportID}/case_description/${structuredData.id}/good_job/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
      })
        .then((response) => response.json())
        .then((data) => {
        //  console.log("feedback saved!", data);
          setConfirmedCase(true);
        });
  
    }
  

  useEffect(() => {

    const model = null;
    const casedesc = caseData.casedesc;
   // console.log("CaseCardModal casedesc",casedesc);
    var request = {        casedesc,        model      
    };
setIsLoading(true);
   // console.log("in use effect");
    // Call your API to get structured data
    fetch(`${server_url}/api/dataset/extractcase/single`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',         
        },    
        body: JSON.stringify(request)
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // This returns a Promise
      })
      .then((data) => {
        // Now 'data' is the parsed JSON object
        setStructuredData(data.caseD);
        setIsLoading(false);
        if (data.caseD.llmmodel == "HUMAN_EXPERT" || data.isConfirmed) {
          setConfirmedCase(true);
        }
        //console.log("data.caseD", data.caseD);
      })
      
      .catch((error) => console.error('Error fetching structured data:', error));
  }, [caseData.id]);

  return (
   
    <Modal show={showModal} onHide={onClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Send Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div  >
      <div  >
     
      {isLoading && <>  <p>{caseData.casedesc}</p>  <LoadingIcon /> 
     
      <p>Extracting data...</p></> }
  
        <p>{caseData.text}</p>
        {structuredData && <CaseCard data={structuredData} />}
        {  structuredData && !showFeedbackForm && !confirmedCase && <> <Button  onClick={() => handleShowFeedback()} title="Teach AI | Send feedback to make AI perform better for you"> <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} /> Teach AI </Button>
        
        <Button onClick={() => handleExtractCaseGoodJob()} className="mx-2" variant="success" title="Confirm that AI accurately extracted patient description">
        <FontAwesomeIcon icon={faThumbsUp} /> Good Job
      </Button></>

        }
   
    {  showFeedbackForm &&
        
        <div className="col-md-6" style={{   padding: "15px" }}>
                 
                  <p className="text-muted">Here, you teach AI to properly extract patient description, so that it can perform better next time. This is your expert feedback.</p>
                  <Form>
                    <Form.Group controlId="ageRangeDropdown">
                      <Form.Label>Age Range:</Form.Label>
                      <Form.Control
                        as="select"
                        value={structuredData.ageRange}
                        onChange={(e) => setStructuredData((prevCaseD) => ({
                          ...prevCaseD,
                          ageRange: e.target.value,
                        }))}
                      >
                        <option value="">Select Age Range</option>
                        <option value="CHILD">Child</option>
                        <option value="ADULT">Adult</option>
                        <option value="OLDER_ADULT">Older Adult</option>
                        <option value="NOT_PROVIDED">Not Provided</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select an age range.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label className="mt-3">Exact Age:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={structuredData.exactAge}
                        onChange={(e) => setStructuredData((prevCaseD) => ({ ...prevCaseD, exactAge: e.target.value }))}
                      />
                    <Form.Group controlId="feedbackTextArea" className="mt-3">
                      <Form.Label>Disease Category:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={structuredData.parentDisease}
                        onChange={(e) => setStructuredData((prevCaseD) => ({ ...prevCaseD, parentDisease: e.target.value }))}
                      />

                      <Form.Label className="mt-3">Main disease:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={structuredData.maincondition}
                        onChange={(e) => setStructuredData((prevCaseD) => ({ ...prevCaseD, maincondition: e.target.value }))}
                      />

                      {structuredData.parentDisease != null && structuredData.parentDisease.toLowerCase() === "cancer" && <>
                        <Form.Label className="mt-3">Metastases:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Type your feedback here, or leave empty if none in text..."
                          value={structuredData.metastases}
                          onChange={(e) => setStructuredData((prevCaseD) => ({ ...prevCaseD, metastases: e.target.value }))}
                        /></>}

                      <Form.Label className="mt-3">Disease subtype and qualifiers:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here (e.g. chronic, newly diagnosed, luminal A, refractory, ...), or leave empty if none provided in text..."
                        value={structuredData.subtypes}
                      
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, subtypes: rxPastArray }));
                        }}
                      />


                      <Form.Label className="mt-3">Biomarkers:</Form.Label>


                      {/* Display type in dropdown and name in input */}
                      {structuredData.biomarkers && structuredData.biomarkers.map((biomarker, index) => (
                        <div key={index}>
                          <Dropdown>
                            <Dropdown.Toggle variant="default" id={`dropdown-${index}`}>
                              {biomarker.type || 'Choose Type'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* Add your options for the type dropdown as needed */}
                              <Dropdown.Item onClick={() => handleTypeSelection('MOLECULAR', index)}>
                                MOLECULAR
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('MICROBIOME', index)}>
                                MICROBIOME
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('CLINICAL', index)}>
                                CLINICAL
                              </Dropdown.Item>
                              {/* Add other options as needed */}
                            </Dropdown.Menu>
                          </Dropdown>

                          <Form.Control
                            type="text"
                            placeholder="Biomarker Name"
                            value={biomarker.name}
                            onChange={(e) => handleNameChange(e.target.value, index)}
                          />
                          <Form.Control
                            type="text"
                            placeholder="Biomarker's Patient Value"
                            value={biomarker.value}
                            onChange={(e) => handleValueChange(e.target.value, index)}
                          />
                        </div>
                      ))}

                      <Button variant="secondary" onClick={addBiomarker}>
                        Add Biomarker
                      </Button>
                      <br></br>
                      <Form.Label className="mt-3">Genes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Type your genes here, separated by commas..."
                        value={(structuredData.genes && structuredData.genes.join(', ')) || ''}
                        onChange={(e) => {
                          const genesArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, genes: genesArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Past treatments:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's past or active treatments, separated by comma..."
                        value={structuredData.pastTreatments}
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, pastTreatments: rxPastArray }));
                        }}
                      />

<Form.Label className="mt-3">Co-conditions:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's other conditions (e.g. pregnancy, athma,...), separated by comma..."
                        value={structuredData.otherConditions}
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, otherConditions: rxPastArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Intervention(s) of interest:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type interventions of interest here..."
                        value={structuredData.treatmentOptions}
                        onChange={(e) => {
                          const rxArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, treatmentOptions: rxArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred country:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred countries here, separated by commas..."
                        value={(structuredData.countries && structuredData.countries.join(', ')) || ''}
                        onChange={(e) => {
                          const countriesArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, countries: countriesArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred city:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred cities here, separated by commas..."
                        value={(structuredData.cities && structuredData.cities.join(', ')) || ''}
                        onChange={(e) => {
                          const citiesArray = e.target.value.split(',').map(value => value.trim());
                          setStructuredData((prevCaseD) => ({ ...prevCaseD, cities: citiesArray }));
                        }}
                      />
                    </Form.Group>
                  </Form>
                  
           
                <Button style={{   marginTop: "35px" }} variant="primary" onClick={handleFeedbackSubmit}>
                  Submit Feedback
                </Button>
                  </div>}

             

      </div>
    </div>
            </Modal.Body>
    </Modal>
   

    
  );
};

export default CaseCardModal;
