
 
import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Col , Alert} from 'reactstrap'; // Import Bootstrap React components
import config from '../config';
const Contact = (pageObj) => {

   
 const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const isSubmitDisabled = message.trim() === ''; // Disable submit if the message is empty
  const server_url = config.server_url;


    const handleSubmit = (event) => {
        event.preventDefault();
        var page = pageObj.page;
        // Add your form submission logic here
        var formData = {
            message, email, page

        }

        fetch(`${server_url}/api/contact/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
              //  console.log("handleFormSubmit", data);
                // Set success status
                setSubmissionStatus('success');
                // Update the local state using setSelectedCriterion
                // setLoading(false);
            })
            .catch((error) => {
                // Set error status
                setSubmissionStatus('error');
                console.error('Error during form submission:', error);
            });


    };

  return (
    
        <div className="mt-2">
          {/* Your contact form component goes here */}
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label className="form-label" for="message">
                Message:
              </Label>
              <Input
                className="form-control"
                type="textarea"
                name="note"
                id="message"
                rows="5"
                placeholder={
                      pageObj.page === 'FEEDBACK'
                    ? "Missing info or loving TrialMatchLLM? Tell us your thoughts!"
                    : "Whether you're interested in exploring partnership opportunities, have thoughts to share, questions about our policies, or simply want to offer encouraging feedback or sponsor, we're all ear!"
                  }

            
                 value={message}
          onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="form-label" for="email">
                Email:
              </Label>
              <Input className="form-control" type="email" name="email" id="email" placeholder="Enter your email address..."  value={email}
          onChange={(e) => setEmail(e.target.value)}/>
            </FormGroup>
            {/* Add other form fields as needed */}
            <FormGroup>
              <Button color="primary" type="submit" disabled={isSubmitDisabled}>
                Submit
              </Button>
              {submissionStatus === 'success' && (
        <Alert color="success">Submission successful! Thank you for your message.</Alert>
      )}
      {submissionStatus === 'error' && (
        <Alert color="danger">Oops! Something went wrong. Please try again later.</Alert>
      )}

            </FormGroup>
          </Form>
        </div>
      
  );
}

export default Contact;
