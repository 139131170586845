 
import React, { useState } from 'react';
import Contact from './Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
const Privacy = () => {

      // State to manage the visibility of the contact form
      const [showContactForm, setShowContactForm] = useState(false);

      // Function to toggle the visibility of the contact form
      const toggleContactForm = () => {
        setShowContactForm(!showContactForm);
      };
 
      

  return (
    <div className="prose">
        <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      <section>
        <h2>Privacy Policy</h2>
        <p>Last updated on December 2nd, 2023</p>
        <p>Thank you for using TrialMatchLLM, available at <a style={{color:'rgb(121, 190, 213)'}} href="https://trialmatchllm.com" target="_blank">trialmatchllm.com <FontAwesomeIcon icon={faExternalLinkAlt} /> </a> and associated internet domains. Your privacy is important to us, and we are committed to protecting your personal information. This privacy policy explains how we collect, use, and share your data when you use TrialMatchLLM.</p>
      </section>

      <section>
        <h3>What information do we collect?</h3>
        <p>When you visit our website, we may collect certain information automatically from your device. This information may include your device type (mobile or web), browser-type (e.g. Chrome), broad geographic location (e.g. country or city-level location). We may also collect information about how your device has interacted with our website, including the pages accessed and links clicked.</p>
        <p>We also collect the information that you voluntarily provide through our website. This includes any personal information that you choose to share, such as your name and email address.</p>
        <p>Please note that if you use our contact form or feedback form without providing your email, you will remain fully anonymous, but we won't be able to reach out to you to answer your comments or feedback.</p>
      </section>

      <section>
        <h3>How do we use your information?</h3>
        <p>Collecting user devices and usage information enables us to better understand the visitors who come to our website, which technical setup we may need to support and what content is of interest to them. We use the information you provide to improve and provide TrialMatchLLM service and to communicate with you when necessary to fulfill that service. Additionally, we may use your information for marketing purposes, such as sending promotional emails about our services. However, we will only do so if you explicitly opt-in.</p>
      </section>

      <section>
        <h3>How do we protect your information?</h3>
        <p>We take appropriate measures to ensure the security of your personal information, including encryption and secure storage. We restrict access to your personal information to only those employees who need to access it to provide our services.</p>
      </section>

      <section>
        <h3>How do we share your information?</h3>
        <p>We do not sell or rent your personal information to third parties. We may share your personal information with our service providers and partners who help us provide our services. The disease information entered in TrialMatchLLM input form may be transmitted anonymously to third party APIs in order to serve requests. We may also share your personal information if required by law or legal process.</p>
      </section>

      <section>
        <h3>Google Analytics</h3>
        <p>We use Google Analytics to collect anonymous data about how TrialMatchLLM is used, in order to improve our service. This data is collected using cookies and other similar technologies. For more information about how Google Analytics collects and processes data, please see Google's privacy policy.</p>
      </section>

      <section>
        <h3>Your rights</h3>
        <p>You have the right to access, correct, or delete your personal information at any time. You also have the right to object to the processing of your personal information. To exercise these rights, please contact us using the information provided below.</p>
      </section>

      <section>
        <h3>Changes to this policy</h3>
        <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website and update the “Last updated” date above. We encourage you to review this policy periodically.</p>
      </section>

      <section>
        <h3>Contact us</h3>
        <p>If you have any questions or concerns about this privacy policy, please <a className="secondary-link" onClick={toggleContactForm}>contact us</a>.</p>
        {showContactForm && (
         <Contact page="PRIVACY"></Contact>
      )}
      </section>

      <p>Thank you for using TrialMatchLLM  👩‍🔬 </p>
    </div>
  );
}

export default Privacy;
