import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Form, Dropdown } from 'react-bootstrap';
import config from '../config';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faThumbsUp, faGraduationCap, faFastForward } from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from './LoadingIcon';
import TrialCard from './TrialCard';
import TrialFeedbackDialog from './TrialFeedbackDialog';  
import { FaRedo } from 'react-icons/fa';



const CurateTrials = () => {

    const [gameData, setGameData] = useState([]);
    // State to manage game metrics
    const [total, setTotal] = useState(0);
    const [verified, setVerified] = useState(0);
    const [corrected, setCorrected] = useState(0);
    const [remaining, setRemaining] = useState(0);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedTrial, setSelectedTrial] = useState(null);
    const [trialId, setTrialId] = useState('');

    const [trialLoading, setTrialLoading] = useState(false);
    const [redoLoading, setRedoLoading] = useState(false);

    const [goodJobSuccess, setGoodJobSuccess] = useState(false);

    const server_url = config.server_url;


    const [showTeachAIDialog, setShowTeachAIDialog] = useState(false);

    const handleButtonClick = () => {
      setShowTeachAIDialog(true);
    };
  
    const handleCloseTeachAIDialog = () => {
      setShowTeachAIDialog(false);
    };


    useEffect(() => {
        // Replace the URL with your actual backend endpoint
        const backendUrl = `${server_url}/api/trialdata/dataset/to_assess`;

        // Fetch game data from the backend
        fetch(backendUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set initial game data and update metrics
                setGameData(data.toAssess);
                if (data.toAssess != null) {
                  //  console.log("data.toAssess", data.toAssess.length);
                }
                setTotal(data.countTotal);
                setRemaining(data.countToAssess);
                setCorrected(data.countCorrected);
                setVerified(data.countConfirmed);
            })
            .catch((error) => {
                console.error('Error fetching game data:', error);
                // Handle error, e.g., show a message to the user
            });
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts

    // useEffect to update selectedTrial when currentCaseIndex changes
    useEffect(() => {
        if (gameData != null && gameData.length > 0) {
            //("found ", gameData[currentIndex])
            setSelectedTrial(gameData[currentIndex]);
            setTrialId('');
            //console.log("in SKIP! New Active selectedCTrial should be ", selectedTrial);
        }
    }, [currentIndex, gameData]);


    // useEffect to log when selectedCase changes
    useEffect(() => {
      //  console.log("selectedTrial changed:", selectedTrial);
        if(selectedTrial!= null && selectedTrial.llmmodel!= null && selectedTrial.llmmodel == 'HUMAN_EXPERT'){
            setGoodJobSuccess(true);
        }
    }, [selectedTrial]);


    const handleSkip = () => {
        // Update gameData to mark the case as skipped
        // Update metrics accordingly
      //  console.log("in SKIP!");
        setGoodJobSuccess(false);
        // Move to the next case
        setCurrentIndex((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));


    };

    const handleRedo = (trialId) => {
        setRedoLoading(true);
        const backendUrl = `${server_url}/api/trialdata/dataset/to_assess/${trialId}/forceGroups/true/forceTasks/true`;
        const storedEmail = localStorage.getItem('userEmail');
      //  console.log(storedEmail);
        const requestBody = {};
        requestBody.email = storedEmail;


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        fetch(backendUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setRedoLoading(false);

                setSelectedTrial({
                    ...selectedTrial,
                    pgroups: data.pgroups,
                    groups: data.groups
                  });
            });

    };


    const handleTrialPlanningGoodJob = (trialId) => {

        const backendUrl = `${server_url}/api/dataset/to_assess/${trialId}/goodjob`;
        const storedEmail = localStorage.getItem('userEmail');
      //  console.log(storedEmail);
        const requestBody = {};
        requestBody.email = storedEmail;


        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        fetch(backendUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setGoodJobSuccess(true);
                setVerified(verified+1);
            });


    }


    const handleFetchTrial = () => {

        setTrialLoading(true);
        const backendUrl = `${server_url}/api/trialdata/dataset/to_assess/${trialId}`;



        // Fetch game data from the backend
        fetch(backendUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set initial game data and update metrics
                setGameData((prevData) => {
                    // Create a new array by concatenating the new elements with the previous data
                    return data.toAssess.concat(prevData);
                });
                if (data.toAssess != null) {
                  //  console.log("data.toAssess", data.toAssess.length);
                }
                setTrialLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching trial data:', error);
                setTrialLoading(false);
                // Handle error, e.g., show a message to the user
            });

    };


    return (
        <div>
            <Helmet>
                <title>Simplify Trials</title>
            </Helmet>

            <h1>Simplify, Understand, Participate</h1>
            <p style={{ fontSize: 'larger', fontStyle: 'italic' }}>Explore studies, learn about eligibility criteria, and empower yourself with knowledge to make informed decisions about participation. </p>
            <div>

                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Enter Trial ID (or multiple trial IDs separated by comma)"
                        aria-label="Trial ID"
                        aria-describedby="trial-id"
                        value={trialId}
                        onChange={(e) => setTrialId(e.target.value)}
                    />

                    {trialLoading ? (
                        <span className="ml-3 text-info"> <LoadingIcon /> Fetching trial(s) and simplifying content... (this may take a while)</span>
                    ) : (
                        <Button onClick={handleFetchTrial} className="mx-2" variant="default">
                            Fetch Trial
                        </Button>
                    )}


                </InputGroup>

            </div>
            {selectedTrial != null && (

                <>
                    <p>TrialMatchLLM simplified the information, and planned how to verify eligibility. </p>
                    <TrialCard key={selectedTrial.id} data={selectedTrial} />
                    <p>Do you agree with TrialMatchLLM's interpretations and plans? </p>
                    <span>
      <Button
        onClick={() => handleTrialPlanningGoodJob(selectedTrial.trial.identifier)}
        className="mx-2"
        variant="success"
        title="Confirm that AI accurately interpreted trial info"
        disabled={goodJobSuccess||redoLoading}
      >
        <FontAwesomeIcon icon={faThumbsUp} /> Good Job!
      </Button>
      
      <TrialFeedbackDialog  disabled={goodJobSuccess||redoLoading} data={selectedTrial.trial.identifier} show={showTeachAIDialog} onHide={handleCloseTeachAIDialog} />
    
      {  redoLoading ? (
                        <span className="ml-3 text-info"> <LoadingIcon /> Processing again... (this may take a while)</span>
                    ) : (
                        <Button onClick={() => handleRedo(selectedTrial.trial.identifier)}   disabled={goodJobSuccess||redoLoading} className="mx-2" variant="default" title="Ask AI to regenerate the results"><FaRedo  /> Redo</Button>
                    )}


      <Button onClick={() => handleSkip()} className="mx-2" variant="default" title="Skip when you don't know"><FontAwesomeIcon icon={faFastForward} /> Next</Button>
   
      {goodJobSuccess && (
        <p style={{ color: 'green' }}>Expert Verified. AI accurately interpreted trial info</p>
      )}
    </span>
                  
                  
                    <p className="mt-3">Verified Trials: {verified} | Corrected Trials: {corrected} | Remaining Trials to Verify: {remaining}</p>
                </>
            )}

        </div>
    );

}

export default CurateTrials;

