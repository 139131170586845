
 
import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap'; // Import Bootstrap React components
import Contact from './Contact'
import { Helmet } from 'react-helmet';
import { OverlayTrigger, Popover } from 'react-bootstrap';


const About = () => {

    // State to manage the visibility of the contact form
  const [showContactForm, setShowContactForm] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);


  // Function to toggle the visibility of the contact form
  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };
  const handlePopoverShow = () => {
    setPopoverVisible(true);
  };

  const handlePopoverHide = () => {
    setPopoverVisible(false);
  };
  const handlePopoverToggle  = () => {
    // Toggle the visibility of the popover
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  

  const popoverFineTune = (
    <Popover id="popover-basic">
      {/*<Popover.Header as="h3">Patient Description</Popover.Header>*/}
      <Popover.Body>
        <p>fine-tuned models" refer to models that have undergone additional training on specific data to improve their performance on particular tasks or domains </p>
        
      </Popover.Body>
    </Popover>
  );


  return (
    <div className="prose">
      <Helmet>
        <title>About</title>
      </Helmet>

      <section>
      <h2>About</h2>
        <p style={{fontSize:'larger', fontStyle:'italic'}}> TrialMatchLLM is a pioneering initiative dedicated to improving the reliability of LLM (Language Model) for matching patients to clinical trials while upholding the principles of transparency and ethics.</p>
        <button onClick={toggleContactForm} className="btn btn-secondary px-2">Contact Us</button>
        {showContactForm && (
         <Contact page="ABOUT"></Contact>
      )}
      </section>

      <section>
        <h3>Our Mission</h3>
        <p>Our primary goal is to enhance LLM's accuracy in eligibility assessment for clinical research. By doing so, we aim to make a significant impact on the efficiency and inclusivity of clinical trials. 💪</p>
        <p>We're addressing concerns outlined by <a href="https://www.nature.com/articles/d41586-023-03803-y" target="_blank">Augustin Toma et al., 2023, Nature</a></p>        
       
      </section>
      <section> 
      <p>Healthcare organisations federating around improving smaller open-source models could offer a solution to strike the right balance between costs, latency, privacy, transparency, and effectiveness.</p>
      </section>
      <section>
      <h3>Why Automation of Trial Eligibility Assessment is Essential?</h3>
  <p>The manual evaluation of all potential trial-patient candidates is simply not feasible. Consequently, less than 3% of eligible patients have been able to participate in trials (Unger et al., 2019), with 38% of trial failures attributed to inadequate recruitment efforts (Stensland et al., 2014).</p>
  <p>By making the clinical trial recruitment process more reliable and efficient, patients can get access to new treatments faster, trials become more inclusive, and more money can be allocated to medical research rather than administrative processes. </p>
</section>
<section>
  
    <h2>What Contributors Can Expect</h2>
    <h3>Access to Datasets</h3>
    <p>As a significant contributor to TrialMatchLLM, you will gain privileged access to curated datasets that fuel the development and fine-tuning of language models. These datasets are meticulously compiled, ensuring a diverse representation of patient profiles and trial criteria. By providing access to such comprehensive datasets, we empower contributors to actively participate in shaping the future of clinical trial eligibility assessment.</p>
    <h3>Fine-Tuned Models</h3>
    <p>Healthcare organizations that contribute to our initiative will not only have access to datasets but will also benefit from fine-tuned language models. These models are honed and optimized based on the collective knowledge and expertise contributed by our collaborative network. The fine-tuned models aim to deliver unparalleled accuracy in matching patients to clinical trials, ultimately facilitating more precise and efficient recruitment processes.</p>
   <h3>Federated Learning</h3>
        <p>
        In addition to providing a user interface for collecting and reviewing expert-curated data, TrialMatchLLM leverages Federated Learning—a collaborative approach where language models are trained across decentralized data sources. This ensures that individual healthcare entities contribute to the improvement of the model without centrally sharing sensitive patient information. Federated Learning enhances privacy, security, and inclusivity in the development of accurate clinical trial eligibility assessments.
        </p>
      
</section>
<section>
<h2>Enhanced Trial Match for All</h2>
<p>TrialMatchLLM is designed to benefit all users, including those who explore the content but cannot contribute with data. By utilizing our language models, users can effortlessly identify relevant clinical trials tailored to their specific needs.</p>
<p> TrialMatchLLM screens enrollment criteria with the precision of large language models. Although perfection is an ongoing pursuit, the benefits of efficiently discovering trials aligned with individual preferences and health considerations are immediate and impactful.
</p>
</section>

<section><h2>What others are doing</h2>
      <p><ul>
      <li className="mb-3"><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7382632/" target="_blank">Evaluation of an artificial intelligence clinical trial matching system in Australian lung cancer patients (JAMIA Open. 2020 Jul)</a> "<b>10 trials, 102 lung cancer patients</b>. The AI-based clinical trial matching system allows efficient and reliable screening of cancer patients for clinical trials with 95.7% accuracy for exclusion and 91.6% accuracy for overall eligibility assessment"</li>
        <li  className="mb-3"><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10418514/" target='_blank'>Matching Patients to Clinical Trials with Large Language Models (Preprint. 2023 Jul 28)</a> "We evaluate TrialGPT on three publicly available cohorts of <b>184 patients and 18,238 annotated clinical trials</b> Overall, it achieves an overall accuracy of 0.86 and 0.84 for the inclusion and exclusion criteria, respectively."</li>
        <li  className="mb-3"><a href="https://ascopubs.org/doi/full/10.1200/CCI.23.00009" target="_blank">Automated Matching of Patients to Clinical Trials: A Patient-Centric Natural Language Processing Approach for Pediatric Leukemia ( JCO Clinical Cancer Informatics, 2023 July 10)</a>"Training data: 216 trial protocols, phase I, II, and III clinical trials enrolling children and adolescents with leukemia between 1987 and 2018; Validation: <b>5 trials</b>. <b>A synthetic cohort of 20 patients</b> was generated and used to assess the tool's top-3 accuracy in patient matching as compared with the manual tool. Results: The multilabel SVM demonstrated a pooled accuracy of 75%. The text processing pipeline was able to automatically extract 68% of eligibility criteria rules, as compared with 80% in a manual version of the tool. Automated matching takes 4 seconds, as compared with several hours using manual derivation."</li>
      <li  className="mb-3"><a href="https://www.outsourcing-pharma.com/Article/2023/09/21/belong.life-launches-ai-saas-to-match-cancer-patients-to-trials" target="_blank">Belong.life launches AI saas to match cancer patients to trials (OutsourcingPharma, 2023 Sept 21)</a></li>

      </ul> </p>
      </section>

      <section>
        <h2>Join Us in Transforming Healthcare Research</h2>
        <p>TrialMatchLLM is more than an initiative; it's a community-driven effort to propel the capabilities of language models in the realm of clinical research. By joining us, you become an integral part of a movement dedicated to advancing healthcare, one accurate trial match at a time.</p>
        <p>If you or your organization are passionate about improving the reliability of LLMs and making a lasting impact on healthcare research, we invite you to collaborate with TrialMatchLLM. Together, let's redefine the future of clinical trial eligibility assessment and pave the way for a more inclusive and efficient era of healthcare research.</p>
        <div>
        <div> <a className="btn btn-secondary" href="/">Getting Started</a>     <button onClick={toggleContactForm} className="btn btn-secondary px-2">Contact Us</button></div>

       
      {showContactForm && (
         <Contact page="ABOUT"></Contact>
      )}
      
        </div>
      </section>

      
    </div>
  );
}

export default About;
