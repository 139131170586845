import React, { useState } from 'react';
import { FaMdb } from 'react-icons/fa';



const CaseCard = ({ data }) => {
    const [showFullList, setShowFullList] = useState(false);
    const [caseD, setCaseD] = useState(data);
  
    if (caseD == null) {
        console.log("caseD is null")
        return;
    } else {
      //  console.log("caseD", caseD);



    }

  const findModelTranslation = (model) => {
    if (model == 'HUMAN_EXPERT') {
      return "Expert verified";
    }
    if (model == 'OPENAI_MODEL_4') {
      return "TrialMatchLLM-GPT-4-1106";
    }
           
    if(model=='OPENAI_MODEL_4_TURBO_PREVIEW'){
      return "TrialMatchLLM-GPT-4-turbo";
    }
    if (model == 'OPENAI_MODEL_35') {
      return "TrialMatchLLM-GPT-3.5";
    }
    if (model == 'OPENAI_MODEL_16k') {
      return "TrialMatchLLM-gpt-3.5-turbo-16k";
    }
    if (model == 'FINE_TUNED_GPT_CASE_SHORT_SYSTEM_PROMT') {
      return "TrialMatchLLM-ft:gpt-3.5-turbo-1106:8lCffJTJ";
    }
    if (model == 'MISTRAL_MODEL_MEDIUM') {
      return "TrialMatchLLM-mistral-medium";
    }
    return model;
  }


    const handleShowMore = () => {
        setShowFullList(!showFullList);
      };
      const highlightMatchingWords = () => {
        // Check if caseD is null
        if(!caseD){
            return '';
        }
//console.log("highlighted caseD", caseD);
        // Extracting biomarker names and values into separate arrays
        const biomarkerNames = (caseD.biomarkers || []).map(biomarker => biomarker.name).filter(Boolean);
        const biomarkerValues = (caseD.biomarkers || []).map(biomarker => biomarker.value).filter(Boolean);

        const rxNames = caseD.pastTreatments || [];
        const optionsNames = caseD.treatmentOptionsNext || [];

        const genes = caseD.genes || [];
        const coconditions = caseD.otherConditions || [];
        const subtypes = caseD.subtypes || [];
        const countries = caseD.countries || [];
        const cities = caseD.cities || [];

        const variableNameToTitleMap = new Map();
        const variableNameToIconMap = new Map();

        // Check and add entries for caseD properties that may be null
        if (caseD.maincondition) {
            variableNameToTitleMap.set(caseD.maincondition.toLowerCase(), 'Main Condition');
            variableNameToIconMap.set(caseD.maincondition.toLowerCase(), '🩺');
        }
        if (caseD.parentDisease) {
            variableNameToTitleMap.set(caseD.parentDisease.toLowerCase(), 'Disease Category');
        }
    

        if (caseD.subtypes) {
          subtypes.forEach(name => {
            variableNameToTitleMap.set(name.toLowerCase(), 'Disease Subtype or Qualifier');
            variableNameToIconMap.set(name.toLowerCase(), '🏷️');
          });
        } 


        // Add entries for biomarker names to the map
        biomarkerNames.forEach(biomarkerName => {
            variableNameToTitleMap.set(biomarkerName.toLowerCase(), 'Biomarker');
            variableNameToIconMap.set(biomarkerName.toLowerCase(), '🔬');
        });

        if (coconditions) {
          coconditions.forEach(name => {
          variableNameToTitleMap.set(name.toLowerCase(), 'Co-Condition');
          variableNameToIconMap.set(name.toLowerCase(), ' ');
      }); 
    }

        // Add entries for biomarker values to the map
        biomarkerValues.forEach(biomarkerValue => {
            variableNameToTitleMap.set(biomarkerValue.toLowerCase(), 'Biomarker Value');
        });

        rxNames.forEach(Value => {
            variableNameToTitleMap.set(Value.toLowerCase(), 'Past or Active Treatment');
        });

        optionsNames.forEach(Value => {
            variableNameToTitleMap.set(Value.toLowerCase(), 'Intervention of interest (future option)');

            variableNameToIconMap.set(Value.toLowerCase(), '💊');
        });


        genes.forEach(gene => {
            variableNameToTitleMap.set(gene.toLowerCase(), 'Gene');
        });


        countries.forEach(c => {
            variableNameToTitleMap.set(c.toLowerCase(), 'Country');
            variableNameToIconMap.set(c.toLowerCase(), '📌');
        });


        cities.forEach(c => {
            variableNameToTitleMap.set(c.toLowerCase(), 'City');
            variableNameToIconMap.set(c.toLowerCase(), '📌');
        });

        // Combining all values for case-insensitive search
        const regexString = [
            ...biomarkerNames,
            ...biomarkerValues,
            ...rxNames,
            ...optionsNames,
            ...genes,
            ...countries,
            ...cities,
            ...coconditions,
            ...subtypes,
            ...Object.values(caseD).filter(Boolean),
        ].join('|');

        const regex = new RegExp(regexString, 'gi');


        const description = caseD.rawtext || '';


        const descriptionReplaced = description.replace(regex, match => {
            const title = variableNameToTitleMap.get(match.toLowerCase());
            var icon = variableNameToIconMap.get(match.toLowerCase());
            if (icon == null) {
                icon = '';
            }
            return `<span class="underlined" title="${icon}${title || match}">${match}</span>`;
        });


        return descriptionReplaced;
    };


  const getAgeRangeDescription = (ageRange) => {
    switch (ageRange) {
      case 'CHILD':
        return 'Child';
      case 'ADULT':
        return 'Adult';
      case 'OLDER_ADULT':
        return 'Older Adult';
      default:
        return ageRange;
    }
  };
  const getFormattedDate = () => {
    const modifiedDate = new Date(caseD.modifiedDate);
    const fd = modifiedDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return fd;
  }

    return (
      <div>
            <div style={{ backgroundColor: "purple", color: "white", padding: "15px" }} >  
   <h5>Patient Description</h5>
                            <span dangerouslySetInnerHTML={{ __html: highlightMatchingWords() }} />
       </div>
       <div style={{  padding: "15px" }}> 
       <h5>Extracted Data</h5>
  
        <table className="table" style={{ marginBottom: '5px' }} >

<thead className="thead-dark"  > {/* You can use thead-light for a light background */}
  <tr>
  <th>
<div style={{ whiteSpace: 'nowrap' }}>
🩺
<br />
Main Disease
</div>
</th>
<th>
<div style={{ whiteSpace: 'nowrap' }}>
⚕️
<br />
Category
</div>
</th>
<th>
<div style={{ whiteSpace: 'nowrap' }}>
🔬
<br />
Biomarkers
</div>
</th>
<th>
<div style={{ whiteSpace: 'nowrap' }}>
🧬
<br />
Genes
</div>
</th>
<th>
<div style={{ whiteSpace: 'nowrap' }}>
💊
<br />
Interest
</div>
</th>
<th>
<div style={{ whiteSpace: 'nowrap' }}>
📌
<br />
Location
</div>
</th>
  </tr>
</thead>
<tbody>
  <tr>

    <td> {caseD.ageRange != null ? getAgeRangeDescription(caseD.ageRange) + (caseD.exactAge != null ? " (" + caseD.exactAge + "y), " : ", ") : ""}
  {caseD.maincondition || 'none found'}  {caseD.subtypes && caseD.subtypes.length > 0 && (
    <span>  <br />
        {'🏷️'}
        {caseD.subtypes.map((subtype, index) => (
            <span key={index}>{subtype}</span>
        ))}
    </span>
)}
      {Array.isArray(caseD.metastases) && caseD.metastases.length > 0 && (
        <span>
          <br />
          Metastases: {caseD.metastases.filter(Boolean).join(', ')}
        </span>
      )}  </td>
    <td> {caseD.parentDisease}</td>
    <td>
      {Array.isArray(caseD.biomarkers) ? (
        caseD.biomarkers.length > 0 ? (
          caseD.biomarkers.filter(Boolean).map((biomarker, index) => (
            <div key={index}>{biomarker.name}: {biomarker.value}</div>
          ))
        ) : (
          <span className="text-muted font-italic">none found</span>
        )
      ) : (
        <span className="text-muted font-italic">none found</span>
      )}
    </td>
    <td>{Array.isArray(caseD.genes) ? (caseD.genes.length > 0 ? caseD.genes.filter(Boolean).join(', ') : <span className="text-muted font-italic">none found</span>) : <span className="text-muted font-italic">none found</span>}</td>
    <td> {Array.isArray(caseD.treatmentOptions) ? (
      caseD.treatmentOptions.length > 0 ? (
        <>
        {showFullList
          ? caseD.treatmentOptions.filter(Boolean).join(', ')
          : caseD.treatmentOptions.slice(0, 4).filter(Boolean).join(', ')}
        {caseD.treatmentOptions.length > 4 && !showFullList && (
          <>
            {' '}
            <span
              className="text-primary cursor-pointer" style={{cursor:'pointer'}}
              onClick={handleShowMore}
            >
              show more... 
            </span>
          </>
        )}
      </>
      ) : (
        <span className="text-muted font-italic">none found</span>
      )
    ) : (
      <span className="text-muted font-italic">none found</span>
    )}</td>
    <td>
      {Array.isArray(caseD.countries) ? (caseD.countries.length > 0 ? caseD.countries.filter(Boolean).join(', ') : <span className="text-muted font-italic">none found</span>) : <span className="text-muted font-italic">none found</span>}{' '}
      {Array.isArray(caseD.cities) ? (caseD.cities.length > 0 ? caseD.cities.filter(Boolean).join(', ') : null) : null}
    </td>

  </tr>
</tbody>
</table>
<div style={{ fontSize: 'small', textAlign: 'right' }}>Version: {findModelTranslation(caseD.llmmodel)} - {getFormattedDate()}     </div>
</div>
      </div>
    );
  };

export default CaseCard;
