
 
import React, { useState } from 'react';
import Contact from './Contact'
import { Helmet } from 'react-helmet';
const Terms = () => {

  
    // State to manage the visibility of the contact form
    const [showContactForm, setShowContactForm] = useState(false);

    // Function to toggle the visibility of the contact form
    const toggleContactForm = () => {
      setShowContactForm(!showContactForm);
    };
 
    
  return (
    <div className="prose">
 <Helmet>
        <title>Terms of use</title>
      </Helmet>
<section>
        <h2>Terms of Use</h2>
        <p>Last updated on December 2nd, 2023</p>
        <p style={{fontSize:'larger', fontStyle:'italic'}}>Welcome to TrialMatchLLM. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. </p>

        <h3>1. Acceptance of Terms</h3>
        <p>By using TrialMatchLLM, you agree to abide by these Terms of Use and all applicable laws and regulations. If you do not agree to these terms, please discontinue use immediately.</p>

        <h3>2. User Conduct</h3>
        <p>You agree to use TrialMatchLLM for lawful purposes only. You are prohibited from violating any applicable laws, rules, or regulations in your use of our services.</p>

        <h3>3. Privacy Policy</h3>
        <p>Your use of TrialMatchLLM is also governed by our <a href="/privacy">Privacy Policy</a>. Please review this policy to understand how we collect, use, and protect your information.</p>

        <h3>4. Intellectual Property</h3>
        <p>All content and materials available on TrialMatchLLM, including but not limited to text, graphics, logos, images, and software, are the property of TrialMatchLLM and are protected by applicable intellectual property laws.</p>

        <h3>5. Disclaimer of Warranties</h3>
        <p>TrialMatchLLM is provided "as is" and without any warranty or condition, express, implied, or statutory. We do not warrant that our services will be uninterrupted, secure, or error-free.</p>

        <h3>6. Limitation of Liability</h3>
        <p>In no event shall TrialMatchLLM be liable for any direct, indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of our services.</p>

        <h3>7. Changes to Terms</h3>
        <p>TrialMatchLLM reserves the right to update or modify these Terms of Use at any time without prior notice. We will notify you of any changes by updating this page and the “Last updated” date above. It is your responsibility to review these terms periodically for changes.</p>

        <h3>8. Contact Information</h3>
        <p>If you have any questions or concerns regarding these Terms of Use, please <a className="secondary-link" onClick={toggleContactForm}>contact us</a>.</p>
        {showContactForm && (
         <Contact page="TERMS"></Contact>
      )}

    </section>


    </div>
 
  );
}

export default Terms;
