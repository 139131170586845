import React, { useState, useEffect } from 'react';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faGraduationCap } from '@fortawesome/free-solid-svg-icons';
function ClassifiedTrialFeedbackForm({ requestID, trialID, email, aiEligibility, hasEligibilityCritera }) {
  const [trialRelevance, setTrialRelevance] = useState('RELEVANT');
  const [patientEligibility, setPatientEligibility] = useState('yes');
  const [note, setNote] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);
  const [modifiedDate, setModifiedDate] = useState(null);

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const server_url = config.server_url;
 // console.log("hasEligibilityCritera", hasEligibilityCritera);

  const getFormattedDate = () => {
    const modifiedDateAsDate = new Date(modifiedDate);
    const fd = modifiedDateAsDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return fd;
  }

  const getRelevanceTranslation = () => {
   if(trialRelevance!=null){
   
    if(trialRelevance == 'NOT_RELEVANT'){
        return "Not relevant";
    }
    if(trialRelevance == 'MAYBE_RELEVANT'){
        return "Maybe relevant";
    }
    if(trialRelevance == 'RELEVANT'){
        return "Relevant";
    }
   }
  }
  const getEligibilityTranslation = () => {
    if(patientEligibility!=null){
     if(patientEligibility == 'Eligible'){
         return "Eligible";
     }
     if(patientEligibility == 'NotEligible'){
         return "Not eligible";
     }
    return "";
    }
   }

  const getRelevanceColor = () => {
    if(trialRelevance!=null){
     if(trialRelevance == 'NOT_RELEVANT'){
         return "darkorange";
     }
     if(trialRelevance == 'MAYBE_RELEVANT'){
         return "black";
     }
     if(trialRelevance == 'RELEVANT'){
         return "green";
     }
    }
   }
 

  useEffect(() => {  

    fetchFeedback();
    
    // Update patientEligibility state if aiEligibility is provided
    if (aiEligibility != null && hasEligibilityCritera==true) {
 //console.log(aiEligibility, aiEligibility);

      setPatientEligibility(aiEligibility);
    }else{
        setPatientEligibility("Not assessed");
    }

    // Update trialRelevance state if aiEligibility is 'NotEligible'
    if (aiEligibility != null && aiEligibility === 'NotEligible') {
      setTrialRelevance('NOT_RELEVANT');
    }
  }, [aiEligibility]); // Only run this effect when aiEligibility changes


  const handleTrialRelevanceChange = (event) => {
    setTrialRelevance(event.target.value);
  };

  const handlePatientEligibilityChange = (event) => {
    setPatientEligibility(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  }; 


  const fetchFeedback = ( ) => {
    var feedback = {};
    feedback.requestID= requestID;
    feedback.trialIdentifier= trialID;
    feedback.email = email;

    ///api/report/{reportID}/trial/{trialID}/assessmentfeedback
   // console.log("calling fetchFeedback");
  

    fetch(`${server_url}/api/report/${requestID}/trial/${trialID}/fetchAssessmentfeedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedback),
      })
        .then((response) => response.json())
        .then((data) => {
           // console.log("response data", data);
          if(data!=null){
            setPatientEligibility(data.eligibility);
            setTrialRelevance(data.relevance);
            setNote(data.note);
            setModifiedDate(data.modifiedDate);
            setShowFeedbackForm(false);
          }
  
        })  .catch((error) => {
            // Handle fetch error
            console.error('Error fetching assessment feedback:', error);
            // You can display an error message or handle the error in another way
          });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can handle form submission here, such as sending data to a backend
  //  console.log('Trial Relevance:', trialRelevance);
   // console.log('Patient Eligibility:', patientEligibility);
    // You may want to perform further actions like sending data to a server or displaying a thank you message
    
    var feedback = {};
    feedback.relevance = trialRelevance;
    if (patientEligibility != 'Not assessed') {
        feedback.eligibility=patientEligibility;
    }

    feedback.note= note;
    feedback.requestID= requestID;
    feedback.trialIdentifier= trialID;
    feedback.email = email;

    ///api/report/{reportID}/trial/{trialID}/assessmentfeedback
   
    fetch(`${server_url}/api/report/${requestID}/trial/${trialID}/assessmentfeedback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedback),
      })
        .then((response) => response.json())
        .then((data) => {
         // console.log("feedback saved!", data);
          setSuccessMsg(true);
          setModifiedDate(data.modifiedDate);
          setShowFeedbackForm(false);
    //FIXME set confirmed case
        });

  };

  return (<>
  {modifiedDate && <div  style={{ maxWidth: '600px' }} className="alert alert-info" title="Reviewed by Expert"><b>Reviewed by Expert<br></br></b>Patient Relevance: <span style={{color:getRelevanceColor(), fontWeight:'bold'}}>{getRelevanceTranslation()}. {getEligibilityTranslation()}</span><br></br>{note}  <br></br><small>{getFormattedDate()} </small><div className="d-flex justify-content-end">
  {!showFeedbackForm && <button title="Teach AI" className="btn btn-primary" onClick={() => setShowFeedbackForm(true)}><FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} /></button>}
</div></div>}

{!showFeedbackForm && !modifiedDate && <button title="Teach AI | Help assess and improve AI Performance" className="btn btn-primary mb-4 " onClick={() => setShowFeedbackForm(true)}><FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} /></button>}

 {showFeedbackForm &&   <form onSubmit={handleSubmit} className="p-4 bg-light mb-4" style={{ maxWidth: '600px' }}>
    <h5 className="mb-3">Submit Expert Feedback</h5>
    <p className="form-text text-muted mb-4">Help assess and improve AI Performance</p>
     <div className="mb-3">
       <label className="d-block">
         Is the trial relevant for the patient?{' '}
         <select className="form-select" value={trialRelevance} onChange={handleTrialRelevanceChange}>
           <option value="RELEVANT">Relevant</option>
           <option value="MAYBE_RELEVANT">Maybe Relevant</option>
           <option value="NOT_RELEVANT">Not Relevant</option>
         </select> 
       </label>
     </div>
     <div className="mb-3">
       <label className="d-block">
         Is the patient likely eligible based on info available?{' '}
         <select className="form-select" value={patientEligibility} onChange={handlePatientEligibilityChange}>
           <option value="Eligible">Yes</option>
           <option value="NotEligible">No</option>
           <option value="Unknown">Unknown</option>
           <option value={null}>Not assessed</option>
         </select>
       </label>
     </div>
     <div className="mb-3">
       <label className="d-block">
         Additional Notes / Reasons:
         <textarea
           className="form-control"
           value={note}
           onChange={handleNoteChange}
           placeholder="Here, you can explain your evaluation if applicable"
           rows="4"
         />
       </label>
     </div>
     <button type="submit" className="btn btn-primary">Submit Feedback</button>
     {successMsg && <div className="alert alert-success">Feedback sent! Thank you.</div>
     
     }



   </form>}

   </>
  );
}

export default ClassifiedTrialFeedbackForm;
