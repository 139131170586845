// Import necessary dependencies
import React , { useEffect, useState }  from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const server_url = config.server_url;

const ICONS = {
    BIOMARKER: '🔬',
    PAST_TREATMENT: '',
    INTERVENTION: '💊',
    COUNTRY: '📌',
    CITY: '📌',
  };

  const fetchCaseD = async (caseDesc) => {
    try {
        const model=null; //use default model

        var requestData= {
            caseDesc,
            model
            // Additional data from your LLM response
          } ;
     // Call your backend or LLM logic here and set the result in App state
   


      // Make your API call here and return the result
      const response = await fetch(`${server_url}/api/dataset/extractcase/single`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        // Add your POST request body here
        body: JSON.stringify({requestData
          // Your request payload
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };


const ExtractCaseFeedbackModal = ({
    showModal,
    handleCloseModal,
    handleExtractCaseGoodJob,
    patientDescription,
    handleFeedbackSubmit
}) => {




    const [caseD, setCaseD] = useState({});
   // console.log("patientDescription", patientDescription, caseD);

    useEffect(() => {
        const fetchData = async () => {
          const result = await fetchCaseD(patientDescription);
          if (result) {
            setCaseD(result.caseD);
          }
        };
    
        fetchData();
      }, []); // Run the effect only once when the component mounts

      
   
 
   
    // Import any additional components, styles, or functions used in the modal
    const highlightMatchingWords = () => {
        // Check if caseD is null
        if(!caseD){
            return '';
        }

        // Extracting biomarker names and values into separate arrays
        const biomarkerNames = (caseD.biomarkers || []).map(biomarker => biomarker.name).filter(Boolean);
        const biomarkerValues = (caseD.biomarkers || []).map(biomarker => biomarker.value).filter(Boolean);

        const rxNames = caseD.pastTreatments || [];
        const optionsNames = caseD.treatmentOptionsNext || [];

        const genes = caseD.genes || [];
        const countries = caseD.countries || [];
        const cities = caseD.cities || [];

        const variableNameToTitleMap = new Map();
        const variableNameToIconMap = new Map();

        // Check and add entries for caseD properties that may be null
        if (caseD.maincondition) {
            variableNameToTitleMap.set(caseD.maincondition.toLowerCase(), 'Main Condition');
            variableNameToIconMap.set(caseD.maincondition.toLowerCase(), '🩺');
        }
        if (caseD.parentDisease) {
            variableNameToTitleMap.set(caseD.parentDisease.toLowerCase(), 'Disease Category');
        }
        if (caseD.subtype) {
            variableNameToTitleMap.set(caseD.subtype.toLowerCase(), 'Disease Subtype');
        }
        // Add entries for biomarker names to the map
        biomarkerNames.forEach(biomarkerName => {
            variableNameToTitleMap.set(biomarkerName.toLowerCase(), 'Biomarker');
            variableNameToIconMap.set(biomarkerName.toLowerCase(), '🔬');
        });

        // Add entries for biomarker values to the map
        biomarkerValues.forEach(biomarkerValue => {
            variableNameToTitleMap.set(biomarkerValue.toLowerCase(), 'Biomarker Value');
        });

        rxNames.forEach(Value => {
            variableNameToTitleMap.set(Value.toLowerCase(), 'Past or Active Treatment');
        });

        optionsNames.forEach(Value => {
            variableNameToTitleMap.set(Value.toLowerCase(), 'Intervention of interest (future option)');

            variableNameToIconMap.set(Value.toLowerCase(), '💊');
        });


        genes.forEach(gene => {
            variableNameToTitleMap.set(gene.toLowerCase(), 'Gene');
        });


        countries.forEach(c => {
            variableNameToTitleMap.set(c.toLowerCase(), 'Country');
            variableNameToIconMap.set(c.toLowerCase(), '📌');
        });


        cities.forEach(c => {
            variableNameToTitleMap.set(c.toLowerCase(), 'City');
            variableNameToIconMap.set(c.toLowerCase(), '📌');
        });

        // Combining all values for case-insensitive search
        const regexString = [
            ...biomarkerNames,
            ...biomarkerValues,
            ...rxNames,
            ...optionsNames,
            ...genes,
            ...countries,
            ...cities,
            ...Object.values(caseD).filter(Boolean),
        ].join('|');

        const regex = new RegExp(regexString, 'gi');


        const description = patientDescription || '';


        const descriptionReplaced = description.replace(regex, match => {
            const title = variableNameToTitleMap.get(match.toLowerCase());
            var icon = variableNameToIconMap.get(match.toLowerCase());
            if (icon == null) {
                icon = '';
            }
            return `<span class="underlined" title="${icon}${title || match}">${match}</span>`;
        });


        return descriptionReplaced;
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Send Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8" style={{ padding: "15px" }}>
                        <div style={{ backgroundColor: "purple", color: "white", padding: "15px" }}>
                            <h5>Patient Description</h5>
                            <span dangerouslySetInnerHTML={{ __html: highlightMatchingWords() }} />
                        </div><div style={{ padding: "15px" }}> If the extracted information shown on the right is already correct, please confirm AI accuracy:
                            <Button onClick={() => handleExtractCaseGoodJob()} className="mx-2" variant="success" title="Confirm that AI accurately extracted patient description">
                                <FontAwesomeIcon icon={faThumbsUp} /> Good Job
                            </Button></div>
                    </div>
                    {/* Right side with form controls */}
                    <div className="col-md-4">
                        {/* Right side content as provided in your code */}
                        {/* ... (the content you provided) */}
                        <Form>
                            {/* Form controls and input fields as provided in your code */}
                            {/* ... (the content you provided) */}
                        </Form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleFeedbackSubmit}>
                    Submit Feedback
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExtractCaseFeedbackModal;
