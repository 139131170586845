import React, { useState, useEffect } from 'react';
import config from '../config';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import { useNavigate   } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const PatientForm = ({ setPatientData, onSubmit }) => {
  const [patientDescription, setPatientDescription] = useState('');
  const [trialIDs, setTrialIDs] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('TML'); // Default method
  const [formCollapsed, setFormCollapsed] = useState(false);
  const server_url = config.server_url;
  const [email, setEmail] = useState(''); // Added email state
  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const navigate = useNavigate();
  const [isValidPatientDescription, setIsValidPatientDescription] = useState(false);


  const handlePopoverToggle  = () => {
    // Toggle the visibility of the popover
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  

  const [popoverVisible, setPopoverVisible] = useState(false);

    const [rememberMe, setRememberMe] = useState(false); // State for "Remember me"

  useEffect(() => {
    // When the component mounts, check local storage for the email
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
      setIsValidEmail(true);
    }

    const params = new URLSearchParams(window.location.search);
    const casedescParam = params.get('casedesc');
    const trialsParam = params.get('trialIds');

    // Set the casedesc in your state
    if (casedescParam) {
      setPatientDescription(decodeURIComponent(casedescParam));
      setIsValidPatientDescription(validatePatientDescription(casedescParam));
    }

    if(trialsParam && trialsParam!='undefined'){
      setTrialIDs(decodeURIComponent(trialsParam));
    }
 
  }, []);

  const handleRememberMeChange = () => {
    // Toggle the state of "Remember me" checkbox
    setRememberMe(!rememberMe);

    // If checked, store the user's email in local storage
    if (!rememberMe && isValidEmail) {
      localStorage.setItem('userEmail', email);
    } else {
      localStorage.removeItem('userEmail');
    }
  };


  const handlePopoverShow = () => {
    setPopoverVisible(true);
  };

  const handlePopoverHide = () => {
    setPopoverVisible(false);
  };


  const popover = (
    <Popover id="popover-basic" className="patient-input-popover">
      <Popover.Header as="h3">What to write in Patient Description?</Popover.Header> 
      <Popover.Body>
  <p>Here, you can provide the following information:</p>
  <ul>
    <li>Age or Age range (child, adult, older adult) </li>
    <li>Gender</li>
    <li>Pregnancy and breastfeeding status (if applicable)</li>
    <li>Diagnosis</li>
    <li>Disease stage or characteristics</li>
    <li>Comorbidities</li>
    <li>Tested biomarkers</li>
    <li>Active and past treatments (timespan since the last dose)</li>
    <li>Previous participation in studies</li>
    <li>Preferred city/country/area or ability to travel</li>
    <li>Interests (e.g., microbiome therapeutics, biologics, immunotherapy, behavioral therapy)</li>
  </ul>
  <p>This information will be used to assess the patient's eligibility to participate in the trial(s).</p>
  <p>If you do not specify an interest, and if you leave the Trial IDs input empty, only recruiting trials investigating Drugs and Biologicals are returned.</p>
  <p>For more examples, please refer to the <strong>Datasets page</strong>.</p>
</Popover.Body>
    </Popover>
  );
  const popoverTrialIDs = (
    <Popover id="popover-basic-trialIds">
      {/*<Popover.Header as="h3">Patient Description</Popover.Header>*/}
      <Popover.Body>
        <p>Enter clinical trial identifiers separated by commas. For example: IMPRESS-N, NCT03606967, NCT04493853.  </p>
        <p>Leave empty if you would like TrialMatchLLM to suggest trials for this patient description.</p>
      </Popover.Body>
    </Popover>
  );
  const popoverEmail = (
    <Popover id="popover-basic-email">
      {/*<Popover.Header as="h3">Patient Description</Popover.Header>*/}
      <Popover.Body>
        <p>Enter your email to receive a notification once the results are ready. </p> <p>After submitting the form, feel free to close the page. You can return to the results using the link provided in the email.</p>     
      </Popover.Body>
    </Popover>
  );
  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setPatientDescription(description);
    setIsValidPatientDescription(validatePatientDescription(description));
  };

  const validatePatientDescription = (description) => {
    // You can customize the validation logic based on your requirements
    return description.trim() !== ''; // Check if the description is not empty after trimming whitespaces
  };

  const validateEmail = (input) => {
    if (input.trim() === '') {
      return false; // Treat empty email as invalid
    }
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsValidEmail(validateEmail(enteredEmail));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);


       // If checked, store the user's email in local storage
       if (rememberMe && isValidEmail) {
        localStorage.setItem('userEmail', email);
      } else {
        localStorage.removeItem('userEmail');
      }


   var caseDesc=patientDescription;
    var formData= {
        caseDesc,
        trialIDs,
        email
        // Additional data from your LLM response
      } ;
 // Call your backend or LLM logic here and set the result in App state
 fetch(`${server_url}/api/findTrials/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
       // console.log("handleFormSubmit", data);
        setLoading(false);

         

// For now, a dummy result is used.
setPatientData({
patientDescription,
trialIDs,
email
// Additional data from your LLM response
});


// Trigger any additional form submission logic
onSubmit && onSubmit(data.trials);

  
 const reportID = data.request.id;
    // Use the navigate function to navigate to the specified route
    navigate(`/trials/${reportID}`);

// Collapse the form after submission
setFormCollapsed(true);


    });


   
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {!formCollapsed && (
        <><h3 className=" d-md-none ">Assess Eligibility for Trial(s)</h3><h3 className="d-none d-md-block">Assess Eligibility for Clinical Trial(s)</h3>
          <p className=''>
            Example:{' '}
            <a
              href="#" style={{    color:'#79bed5'}}  
              onClick={(e) => {
                e.preventDefault();
                setPatientDescription(
                  'Middle-aged woman with moderate Rheumatoid Arthritis, hypertension, osteoporosis, elevated DAS28 score. Location: Oslo, Norway.'
                );
                setIsValidPatientDescription(true);
                setTrialIDs('NCT05496855, NCT05772325, NCT04586933');
              }}
            >
              Middle-aged woman with moderate Rheumatoid Arthritis, hypertension, osteoporosis, elevated DAS28 score. Location: Oslo, Norway.
            </a>{' '}
          </p>

          
          <div className="mb-3 mt-4">
            <label className="form-label">Patient Description</label>{' '}
            <OverlayTrigger
        trigger={['hover', 'focus']} 
        placement="right"
        overlay={popover}
      >
        <span
          className="info-icon"
          onMouseOver={handlePopoverShow}
          onMouseOut={handlePopoverHide}
          onFocus={handlePopoverShow}  // Add onFocus event handler
          onBlur={handlePopoverHide}   // Add onBlur event handler
          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
          onClick={handlePopoverToggle} 
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </OverlayTrigger>  
            <textarea
             
              className={`form-control ${isValidPatientDescription ? 'is-valid' : 'is-invalid'}`}
              rows="4"
              value={patientDescription}
              onChange={handleDescriptionChange}
              placeholder="Enter the patient information (tap the white info icon for help)... "
              required  
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Trial IDs or Aliases</label>{' '}
            <OverlayTrigger
        trigger={['hover', 'focus']} 
        placement="right"
        overlay={popoverTrialIDs}
      >
        <span
          className="info-icon"
          onMouseOver={handlePopoverShow}
          onMouseOut={handlePopoverHide}
          onFocus={handlePopoverShow}  // Add onFocus event handler
          onBlur={handlePopoverHide}   // Add onBlur event handler
          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
          onClick={handlePopoverToggle} 
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </OverlayTrigger> 
            <textarea
              className="form-control"
              rows="3"
              value={trialIDs}
              onChange={(e) => setTrialIDs(e.target.value)}
              placeholder="Enter clinical trial identifiers (optional)..."
            />
          </div>
          <div className="mb-3" >
            <label htmlFor="email" className="form-label">Your email</label>{' '}
            <OverlayTrigger
        trigger={['hover', 'focus']} 
        placement="right"
        overlay={popoverEmail}
      >
        <span
          className="info-icon"
          onMouseOver={handlePopoverShow}
          onMouseOut={handlePopoverHide}
          onFocus={handlePopoverShow}  // Add onFocus event handler
          onBlur={handlePopoverHide}   // Add onBlur event handler
          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
          onClick={handlePopoverToggle} 
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      </OverlayTrigger>  
            <input
              type="email" id="email" name="email"
              className={`form-control ${isValidEmail ? 'is-valid' : 'is-invalid'}`}
              value={email}
               onChange={handleEmailChange}

               
              placeholder="Enter your email..."
            />
            
          </div>
          <div   className="mb-3 form-check">
            <input type="checkbox" id="remember" name="remember"  className="form-check-input form-check-xl" checked={rememberMe}
          onChange={handleRememberMeChange} />
            <label htmlFor="remember" className=" form-check-label">Remember me (Stay signed in)</label>
          </div>
          {loading ? (
        <span> <LoadingIcon /> Fetching trial(s) and assessing each eligibility criteria... (this may take a while)
        
        { email && (
  <p style={{ color: 'darkgoldenrod', backgroundColor: '#ffc10724',   textAlign:'center', padding:'8px' }}>
    You will receive an email at <b>{email}</b><br></br> once TrialMatchLLM has completed classifying the trials. 
  </p>
)}

        </span>
      ) : (
        <button type="submit" className="btn btn-primary" disabled={!isValidEmail || !isValidPatientDescription}>
          Assess Trials
        </button>
      )}
        </>
      )}
    </form>
  );
};

export default PatientForm;
