import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faGraduationCap  } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const TrialFeedbackDialog = ({ data,   onHide }) => {
    const [trialID, setTrialID] = useState(data);
    const [show, setShow] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [comment, setComment] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const server_url = config.server_url;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    // Send feedback to the server
    // Upon successful submission, set feedbackSent to true

    const backendUrl = `${server_url}/api/dataset/to_assess/${trialID}/feedback`;
        const storedEmail = localStorage.getItem('userEmail');
      //  console.log(storedEmail);
        const requestBody = {};
        requestBody.email = storedEmail;
        requestBody.feedbackType = feedbackType;
        requestBody.comment = comment;
        requestBody.trialID = trialID;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        fetch(backendUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setFeedbackSent(true);
           
            });


   
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
      <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} />    Teach AI
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Provide Feedback for {trialID}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!feedbackSent ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="feedbackType">
                <Form.Label>Feedback Type</Form.Label>
                <Form.Control
                  as="select"
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value)}
                >
                  <option value="">Select Feedback Type</option>
                  <option value="TRIAL_INFO">
                    Study description incorrect
                  </option>
                  <option value="PARTICIPANTS_INCORRECT">
                    Groups of participants incorrect
                  </option>
                  <option value="ELIGBILITY_MISSING">
                    Eligibility Criteria incorrect
                  </option>
                  <option value="OTHER">
                   Other
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="comment">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Send Feedback
              </Button>
            </Form>
          ) : (
            <p>Feedback sent!</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TrialFeedbackDialog;
