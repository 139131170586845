import React, { useState } from 'react';
import { FaMdb } from 'react-icons/fa';
import GroupElibiligyTable from './GroupElibiligyTable';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';

const TrialGroups = ({ data, group = null }) => {

    const [pgroups, setPgroups] = useState(data);
    const [groups, setGroups] = useState(group);
    const colors = ['blue', 'orange', 'purple', 'yellow', 'cyan', 'magenta', 'pink', 'teal', 'indigo', 'maroon'];
    const [currentGroupPhrases, setCurrentGroupPhrases] = useState([]);
    const [currentGroupName, setCurrentGroupName] = useState();

    const [expandedGroups, setExpandedGroups] = useState({});

    const [popoverVisibleMap, setPopoverVisibleMap] = useState({});

    

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverShow = () => {
    setPopoverVisible(true);
  };

  const handlePopoverHide = () => {
    setPopoverVisible(false);
    setPopoverVisibleMap({});
   
  };

  const handlePopoverVisibility = (groupName, isVisible) => {
    setPopoverVisibleMap(prevState => ({
        ...prevState,
        [groupName]: isVisible // Set the visibility state for the specified group
    }));
};

  const handlePopoverToggle  = (groupName) => {
    // Toggle the visibility of the popover
    setPopoverVisible((prevVisible) => !prevVisible);

    
  };
  const handleGroupClick = (group) => {
   
    // Update currentGroupPhrases with phrases of the clicked group
    setCurrentGroupPhrases(group.phrases);
    setCurrentGroupName(group.name);

      // Close all other popovers
      const newPopoverVisibleMap = {};
      Object.keys(popoverVisibleMap).forEach(name => {
          newPopoverVisibleMap[name] = false;
      });
  
      setPopoverVisibleMap(prevState => ({
          ...newPopoverVisibleMap,
          [group.name]: !prevState[group.name]
      }));
};

// Function to close all popovers
const handleCloseAllPopovers = () => {
    setPopoverVisibleMap({});
};
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{currentGroupName}</Popover.Header>  
      <Popover.Body>
        <p>The various manner this participant group is mentioned in the trial text</p>
        <ul>
                    {currentGroupPhrases.map((phrase, idx) => (
                        <li key={idx}>{phrase}</li>
                    ))}
                </ul>
                <div style={{marginTop: '10px', textAlign: 'right'}}>
                <button onClick={() => { setCurrentGroupPhrases([]); handlePopoverVisibility(currentGroupName, false); }}>Close</button>
            </div>
      </Popover.Body>
   
    </Popover>
  );



    // Function to toggle the expanded state of a group
    const toggleExpand = (groupName) => {
      setExpandedGroups(prevState => ({
        ...prevState,
        [groupName]: !prevState[groupName] // Toggle the expanded state of the group
      }));
    };


    function findTasksByGroupName(groupName) {
        if (groups == null) {
           console.log("group is null");
            return null;
        }
    
        // Find all groups with the given groupName
        const matchingGroups = groups.filter(group => 
            group.groupName.trim().toLowerCase() === groupName.trim().toLowerCase()
          );
    
        if (matchingGroups.length === 0) {
            console.log("No group found with name", groupName);
            return null;
        }
    
        // Merge tasks from all matching groups into a single list
        const mergedTasks = matchingGroups.reduce((accumulator, currentGroup) => {
            return accumulator.concat(currentGroup.tasks);
        }, []);
    
        return mergedTasks;
    }


    return (
        <div>
            <h5>Groups of participants </h5>
            <p style={{ color: 'gray' }}>Expand/Collapse each group to view AI's eligibility assessment plan.</p>
            {pgroups.length === 0 ? (
                <p><i>Not parsed yet.</i></p>
            ) : (

                <div >
                <ul style={{ listStyleType: 'none', padding: 0 }} >
                    {pgroups.map((group, index) => (
                        <li key={index} style={{ borderLeft: `4px solid ${colors[index % colors.length]}`  }}>
                            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => toggleExpand(group.name)}>
                                <span style={{ marginRight: "5px" }}>{expandedGroups[group.name] ? "-" : "+"}</span> {/* Indicator for expanded/collapsed state */}
                                <h6>{group.name} 
                                <OverlayTrigger
        trigger="click" // Only trigger on click
        placement="right"
        show={popoverVisibleMap[group.name]}
       
        overlay={popover}
        
      >
        <span
          className="info-icon"
          style={{ paddingLeft: '5px', color: '#999999' }}
          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
          onClick={(e) => {
            e.stopPropagation(); // This prevents the click event from propagating to the parent elements
            e.preventDefault();
            handleGroupClick(group);
        }} 
        >
          <FontAwesomeIcon icon={faTags} />
        </span>
      </OverlayTrigger> 
                                 </h6>
                            </div>
    
                   
                      

                              {/* Render GroupElibiligyTable only if the group is expanded */}
                        {expandedGroups[group.name] && (
<>


                            <GroupElibiligyTable tasks={findTasksByGroupName(group.name)} groups={pgroups}></GroupElibiligyTable></>
                        )}
<div  style={{   marginBottom:'16px'}}></div>
                        </li>
                        
                    ))}
                </ul>
            </div>

 
              
            )}
        </div>
    );

}
  

export default TrialGroups;