import React from 'react';
import TrialResult from './TrialResult';
const TrialResults = ({ patientData, trials, isToClassify, queries }) => {

//console.log("inside trial results ", queries);

    if ( trials ==null ) {
        return <p className="mt-3">No matching trials found yet.</p>;
    }

    if (isToClassify && trials.length === 0) {
      return <p className="mt-3">Nothing to show here. Check the classified categories!</p>;
  }

    if (!isToClassify && trials.length === 0) {
        return <p className="mt-3">No matching trials found.</p>;
    }

  return (
    <div className="mt-3">
 
   
      <div>
      {trials.map((trial) => (
        <TrialResult key={trial.id} trialInput={trial} patientData={patientData} trialQueries={queries}/>
      ))}
    </div>
    </div>
  );
};

export default TrialResults;
