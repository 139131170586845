import React, { useState,useEffect  } from 'react';
import { BsCaretDownFill, BsCaretUpFill,BsQuestionCircle  } from 'react-icons/bs';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button ,Popover} from 'react-bootstrap';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap,faExternalLinkAlt, faBrain ,faMicrochip   } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TrialInfo from './TrialInfo'; 
import Reasons from './Reasons'; 
import { useParams } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import config from '../config';
import { FaRedo } from 'react-icons/fa';
import ParseEligibilityCriteria from './ParseEligibilityCriteria'; 
import ClassifiedTrialFeedbackForm from './ClassifiedTrialFeedbackForm';   
import ReactGA from 'react-ga4';

const TrialResult = ({ trialInput, patientData, trialQueries }) => {
    const server_url = config.server_url;
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [selectedCriterion, setSelectedCriterion] = useState(null);
  const [email, setEmail] = useState(null);
  const [goodFitVisible, setGoodFitVisible] = useState(true);
  const [badFitVisible, setBadFitVisible] = useState(true);
  const { reportID } = useParams();
  const toggleGoodFit = () => setGoodFitVisible(!goodFitVisible);
  const toggleBadFit = () => setBadFitVisible(!badFitVisible);
  const [trial, setTrial] = useState(trialInput);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const GoodFitIcon = () => <FontAwesomeIcon icon={faCheckCircle} />;
  const NotGoodFitIcon = () => <FontAwesomeIcon icon={faTimesCircle} />;
  const [hideEligibility, setHideEligibility] = useState(true);
  const [loadingEligibilityCheck, setLoadingEligibilityCheck] = useState(false);
  const [popoverState, setPopoverState] = useState({});


  const handleButtonClick = (criteriaId) => {
   // console.log("should show ",criteriaId )

    
      setPopoverState(prevState => ({
        ...prevState,
        [criteriaId]: prevState[criteriaId] === null ? true : !prevState[criteriaId]
      }));
   
  
  };
  let matchFound = false;

  

  const [selectedGroups, setSelectedGroups] = useState([]);
  
// Function to get count of eligibility criteria for a specific group name
function getEligibilityCriteriaCount(groupName) {
  return trial.eligibilityCriteria.filter(criteria => criteria.groupName.toLowerCase()  === groupName.toLowerCase() && criteria.type != 'NOT_A_CRITERION' ).length;
}
 
  const handleGroupSelection = (groupName) => {
    const index = selectedGroups.indexOf(groupName);
    if (index === -1) {
      setSelectedGroups([...selectedGroups, groupName]);
    } else {
      setSelectedGroups(selectedGroups.filter((group) => group !== groupName));
    }

 
  };



  useEffect(() => {
    if (trial != null && trial.agroups != null && trial.agroups.length > 0) {
      // Initialize selectedGroups based on group relevance
      const initiallySelectedGroups = trial.agroups
        .filter(group => group.relevance === "YES" || group.relevance === "MAY_BE")
        .map(group => group.groupname);

      setSelectedGroups(initiallySelectedGroups);
    //  console.log("updated agroups", initiallySelectedGroups)
    }
  }, [trial]); // Run this effect whenever trial.agroups changes


  const colors = ['blue', 'orange', 'purple', 'yellow', 'cyan', 'magenta', 'pink', 'teal', 'indigo', 'maroon'];

  function getRelevanceColor(relevance) {
  
    if (relevance == 'YES') {
      return "green";
    }
    if (relevance == 'MAY_BE') {
      return "black";
    }
    if (relevance == 'NO') {
      return "orange";
    }
    return relevance;
  }

  function getRelevanceTranslation(relevance) {
  
    if (relevance == 'YES') {
      return "Applicable";
    }
    if (relevance == 'MAY_BE') {
      return "Maybe applicable";
    }
    if (relevance == 'NO') {
      return "Not applicable";
    }
    return relevance;
  }
  
  
  function findCriteriaByGroupName(groupName) {

 

    const filteredCriteria = trial.eligibilityCriteria.filter(criteria => criteria.name === groupName);

    return filteredCriteria;
}



  const shouldHideEligibilityCriteria = trialInput.eligibilityStatus !== null &&
  trialInput.eligibilityStatus === 'NotEligible' &&
                     (trialInput.eligibilityCriteria == null || trialInput.eligibilityCriteria.length === 0);

                   
  // Function to convert enum type to human-friendly text
  const getFriendlyText = (type) => {
    // Add more cases as needed
     switch (type) {
      case 'WITH_COUNTRIES_AND_SUBTYPE_NO_INTEREST':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 📌Preferred location';

      case 'WITH_DISTANCE_AND_SUBTYPE_NO_INTEREST':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🛣️1700km from preferred location';

      case 'WITHOUT_COUNTRY_AND_SUBTYPE_NO_INTEREST':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🌍Everywhere';


      case 'WITH_COUNTRIES_AND_SUBTYPE':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';

      case 'WITH_DISTANCE_AND_SUBTYPE':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🚗1700km from preferred location + 💊intervention of interest';

      case 'WITHOUT_COUNTRY_AND_SUBTYPE':
        return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';



      case 'WITH_GENES':
        return '🩺Main Disease + 🧬 Genes + 📌Preferred location + 💊intervention of interest';
      case 'WITH_GENES_AND_DISTANCE':
        return '🩺Main Disease + 🧬 Genes + 🚗1700km from preferred location + 💊intervention of interest';
      case 'WITHOUT_COUNTRY':
        return '🩺Main Disease + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_GENE_WITHOUT_COUNTRY':
        return '🩺Main Disease + 🧬 Genes + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_DISTANCE':
        return '🩺Main Disease + 🔬Biomarkers + 🚗1700km from preferred location + 💊intervention of interest';
      case 'WITH_COUNTRIES':
        return '🩺Main Disease + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_COUNTRIES_BROADER_DISEASE':
        return '⚕️Disease Category + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_PARENT_DISEASE':
        return '⚕️Disease Category + 🔬Biomarkers + 🚗1700km from preferred location + 💊intervention of interest';
      case 'WITH_PARENT_DISEASE_EVERYWHERE':
        return '⚕️Disease Category + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_GENES_DISTANCE_BROADER_DISEASE':
        return '⚕️Disease Category + 🧬Genes + 1700km from preferred location + 💊intervention of interest';

      case 'WITH_BIOMARKERS_NO_DISEASE':
        return '🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_BIOMARKERS_AND_DISTANCE':
        return '🔬Biomarkers + 🚗1700km from preferred location + 💊intervention of interest';
      case 'WITH_BIOMARKERS_EVERYWHERE':
        return '🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';


      case 'WITH_COUNTRIES_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 📌Preferred location';
      case 'WITH_DISTANCE_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 🚗1700km from preferred location';
      case 'WITHOUT_COUNTRY_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 🌍Everywhere';


        case 'WITH_COUNTRIES_NO_BIOMARKERS':
          return '🩺Main Disease + 📌Preferred location + 💊intervention of interest ';
        case 'WITH_DISTANCE_NO_BIOMARKERS':
          return '🩺Main Disease + 🛣️ 1700km from preferred location + 💊intervention of interest';
        case 'WITHOUT_COUNTRY_NO_BIOMARKERS':
          return '🩺Main Disease + 🌍Everywhere + 💊intervention of interest';

          case 'WITH_COUNTRIES_DISEASE_ONLY_AND_SUBTYPE':
            return '🩺Main Disease + 🏷️Subtype|Qualifiers + 📌Preferred location';
          case 'WITH_DISTANCE_DISEASE_ONLY_AND_SUBTYPE':
            return '🩺Main Disease + 🏷️Subtype|Qualifiers + 🛣️ 1700km from preferred location';
          case 'WITHOUT_COUNTRY_DISEASE_ONLY_AND_SUBTYPE':
            return '🩺Main Disease + 🏷️Subtype|Qualifiers + 🌍Everywhere';
  
  
 


          case 'WITH_COUNTRIES_DISEASE_ONLY':
            return '🩺Main Disease + 📌Preferred location';
          case 'WITH_DISTANCE_DISEASE_ONLY':
            return '🩺Main Disease + 🛣️ 1700km from preferred location';
          case 'WITHOUT_COUNTRY_DISEASE_ONLY':
            return '🩺Main Disease + 🌍Everywhere';

                
     
      default: 
        return type;
    }
  };


                     

  const getFriendlySymbols = (type) => {
    // Add more cases as needed
    switch (type) {



      case 'WITH_COUNTRIES_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬 + 📌+ 💊';

      case 'WITH_DISTANCE_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬 + 1700 km + 💊 ';

      case 'WITHOUT_COUNTRY_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬  + 🌍 + 💊';


      case 'WITH_GENES':
        return '🩺 + 🧬 + 📌 + 💊';
      case 'WITH_GENES_AND_DISTANCE':
        return '🩺 + 🧬 + 1700 km + 💊';
      case 'WITHOUT_COUNTRY':
        return '🩺 + 🔬 + 🌍 + 💊';
      case 'WITH_GENE_WITHOUT_COUNTRY':
        return '🩺 + 🧬 + 🌍 + 💊';
      case 'WITH_DISTANCE':
        return '🩺 + 🔬 + 1700 km + 💊';
      case 'WITH_COUNTRIES':
        return '🩺 + 🔬 + 📌 + 💊';
      case 'WITH_COUNTRIES_BROADER_DISEASE':
        return '⚕️ + 🔬 + 📌 + 💊';
      case 'WITH_PARENT_DISEASE':
        return '⚕️ + 🔬 + 1700 km + 💊';
      case 'WITH_PARENT_DISEASE_EVERYWHERE':
        return '⚕️ + 🔬 + 🌍 + 💊';
      case 'WITH_GENES_DISTANCE_BROADER_DISEASE':
        return '⚕️ + 🧬 + 1700 km + 💊';
      case 'WITH_BIOMARKERS_NO_DISEASE':
        return '🔬 + 📌 + 💊';
      case 'WITH_BIOMARKERS_AND_DISTANCE':
        return '🔬 + 1700 km + 💊';
      case 'WITH_BIOMARKERS_EVERYWHERE':
        return '🔬 + 🌍 + 💊';
      case 'WITH_COUNTRIES_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 📌';
      case 'WITH_DISTANCE_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 1700 km';
      case 'WITHOUT_COUNTRY_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 🌍';
      case 'WITH_COUNTRIES_NO_BIOMARKERS':
        return '🩺 + 📌 + 💊';
      case 'WITH_DISTANCE_NO_BIOMARKERS':
        return '🩺 + 1700 km + 💊';
      case 'WITHOUT_COUNTRY_NO_BIOMARKERS':
        return '🩺 + 🌍 + 💊';


        case 'WITH_COUNTRIES_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 📌';
        case 'WITH_DISTANCE_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 1700 km';
        case 'WITHOUT_COUNTRY_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 🌍';
  
        
          case 'WITH_COUNTRIES_AND_SUBTYPE_NO_INTEREST':
            return '🩺 + 🏷️ + 🔬 + 📌';
    
          case 'WITH_DISTANCE_AND_SUBTYPE_NO_INTEREST':
            return '🩺 + 🏷️ + 🔬 + 1700 km';
    
          case 'WITHOUT_COUNTRY_AND_SUBTYPE_NO_INTEREST':
            return '🩺 + 🏷️ + 🔬  + 🌍';
  

      case 'WITH_COUNTRIES_DISEASE_ONLY':
        return '🩺 + 📌';
      case 'WITH_DISTANCE_DISEASE_ONLY':
        return '🩺 + 1700 km';
      case 'WITHOUT_COUNTRY_DISEASE_ONLY':
        return '🩺 + 🌍';

        

      default:
        return type;
    }
  };

  const togglePanelVisibility = () => {
    setIsPanelCollapsed(!isPanelCollapsed);
  };


  if (email == null) {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      
    }
  }

  const toggleFeedbackVisibility = (criterion) => {
    setSelectedCriterion(selectedCriterion === criterion ? null : criterion);
 };


  const handleCheckElligbilityButtonClick = (e) => {
    e.preventDefault();
    setLoadingEligibilityCheck(true);
    //("click handleCheckElligibilityAgainClick!", trial);
    const requestID = reportID;
    const trialID = trial.id;

    fetch(`${server_url}/api/report/${requestID}/trial/${trialID}/forceAskAgain`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTrial(data);
       // console.log("data should be updated");
        setHideEligibility(data.eligibilityStatus !== null &&
          data.eligibilityStatus === 'NotEligible' &&
          (data.eligibilityCriteria == null || data.eligibilityCriteria.length === 0));
        setLoadingEligibilityCheck(false);
      })


  }



 const askAgain = (criterion) => {
   const requestID = reportID;
   const trialID = trial.id;
   const atID = criterion.id;

     // Set loading to true for the specific criterion
  setLoadingStates((prevLoadingStates) => ({
    ...prevLoadingStates,
    [criterion.text]: true,
  }));


 
  fetch(`${server_url}/api/report/${requestID}/trial/${trialID}/at/${atID}/askAgain`)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    // Handle the fetched trial data
    //console.log('Fetched Redo:', data);
   //TODO refresh criteria
    
      // Update the criteria content in the state
      const updatedCriteria = trial.eligibilityCriteria.map((c) =>
        c.id === criterion.id ? { ...c, matchStatus : data.matchStatus, explanation:data.explanation } : c
     
      );
      //console.log("updated critera", updatedCriteria);

      // Update the state with the modified criteria
      setTrial((prevTrial) => ({
        ...prevTrial,
        eligibilityCriteria: updatedCriteria,
      }));
      
 


  })
  .catch((error) => {
    console.error('Error during fetch:', error);
    // Handle errors as needed
  }).finally(() => {
    // Set loading back to false for the specific criterion
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [criterion.text]: false,
    }));
  });
};


  const handleSubmitFeedback = (selectedCriterion, correctedMatchStatus, note) => {
    // Send feedback for the selected criterion to the server or perform any other action
    //console.log('Feedback submitted:', feedback[selectedCriterion.text]);
    // Reset feedback state and selected criterion
   var selectedCriterionID = selectedCriterion.id;
 
   var reportId= reportID;
    var formData = {
      correctedMatchStatus,
      note, selectedCriterionID,
      reportId, email
    }

//console.log("patientData", patientData);
    // Call your backend or LLM logic here and set the result in App state
    fetch(`${server_url}/api/feedback/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
     //   console.log("handleFormSubmit", data);
       // Update the local state using setSelectedCriterion
       setSelectedCriterion((prevSelectedCriterion) => ({
        ...prevSelectedCriterion,
        matchStatus: correctedMatchStatus,
        explanation: note
      }));

const newExplaination = 'AI: '+selectedCriterion.explanation+'; Human feedback: '+note;
      
      // Update the criteria content in the state
      const updatedCriteria = trial.eligibilityCriteria.map((c) =>
        c.id === selectedCriterionID ? { ...c, matchStatus : data.correctedMatchStatus,explanation: newExplaination } : c
     
      );
    //  console.log("newExplaination", newExplaination);

      // Update the state with the modified criteria
      setTrial((prevTrial) => ({
        ...prevTrial,
        eligibilityCriteria: updatedCriteria,
      }));



     //   setLoading(false);
      });

    setFeedback({});
    setSelectedCriterion(null);
  };


 // const handleShowModal = () => setShowModal(true);

 const handleShowModal = async () => {
  try {
    // Perform any pre-modal actions if needed
    if (trial.id == null || trial.reasons == null  || trial.eligibilityCriteria == null || trial.eligibilityStatus == null) {
      // Call fetchTrialData with the required parameters
      const requestID = reportID;
      const identifier = trial.identifier;
      await fetchTrialData(requestID, identifier);
    }
    // Set showModal to true after fetching trial data
    setShowModal(true);

    ReactGA.event({
      category: 'Link',
      action: 'Click',
      label: 'openTrial'
    });

    // Perform any post-modal actions if needed
  } catch (error) {
    console.error('Error in handleShowModal:', error);
    // Handle errors as needed
  }
};
const typeToColor = {
  'INCLUSION': 'green',
  'EXCLUSION': 'red',
  'NOT_A_CRITERIA': 'gray'
};

  const handleCloseModal = () => setShowModal(false);
  const [groupingCriteria, setGroupingCriteria] = useState('type'); // Default grouping by Type

  // Function to toggle the grouping criteria
  const toggleGroupingCriteria = () => {
    setGroupingCriteria((prevCriteria) => (prevCriteria === 'type' ? 'matchStatus' : 'type'));
  };

 
  const fetchTrialData = (requestID, identifier) => {

    if(requestID == null){
      console.log("cannot  call server with ", requestID, identifier);
      return;
    }
   // console.log("will call server with ", requestID, identifier);
    fetch(`${server_url}/api/report/${requestID}/fetchTrial/${identifier}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the fetched trial data
     //   console.log('Fetched Trial Data:', data);
      

        setTrial((prevTrial) => ({
          ...prevTrial,
          id:data.id,
          reasons: data.reasons,
          eligibilityCriteria: data.eligibilityCriteria, 
        closestLocation: data.closestLocation,
        eligibilityStatus: data.eligibilityStatus
        }));
        // You might want to update your component state with the fetched data
        // For example, assuming you have a state variable called "fetchedTrialData"
        // setFetchedTrialData(data);
        if (data && data.agroups) {
        const initiallySelectedGroups = data.agroups
        .filter(group => group.relevance === "YES" || group.relevance === "MAY_BE")
        .map(group => group.groupname);
   setSelectedGroups(initiallySelectedGroups);
 
        }
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
        // Handle errors as needed
      });

     
  };
  


  // Helper function to get color based on matchStatus
const getMatchStatusColor = (matchStatus) => {
    switch (matchStatus) {
      case 'MET':
        return 'green';
      case 'NOT_MET':
        return 'red';
      default:
        return 'black'; // You can set a default color for 'UNKNOWN' or other statuses
    }
  };
  
  // Helper function to get icon based on matchStatus
  const getMatchStatusIcon = (matchStatus) => {
    if(matchStatus==null){
      return  <span style={{ color: 'gray' }}>Not set yet</span>; 
    }

    switch (matchStatus) {
      case 'MET':
        return <span style={{ color: 'green' }}>&#10003; Met</span>; // Checkmark
      case 'NOT_MET':
        return <span style={{ color: 'red' }}>&#10007; Excludes</span>; // Cross
      case 'NOT_APPLICABLE':
          return <span style={{ color: 'gray' }}>o N/A</span>; // Cross
      default:
        return  <span style={{ color: 'gray' }}>{matchStatus.toLowerCase()}</span>;  // Show unchanged value for 'UNKNOWN' or other statuses
    }
  };
  // Helper function to get icon based on matchStatus
  const getEligibleStatusIcon = (matchStatus) => {
    if(matchStatus==null){
      return  <span style={{ color: 'gray' }}>Not set yet</span>; 
    }

    switch (matchStatus) {
      case 'Eligible':
        return <span style={{ color: 'green' }}>&#10003; Likely eligible</span>; // Checkmark
      case 'NotEligible':
        return <span style={{ color: 'red' }}>&#10007; Not Eligible</span>; // Cross
      case 'Unknown':
          return <span style={{ color: 'gray' }}>?</span>; // Cross
      default:
        return  <span style={{ color: 'gray' }}>{matchStatus.toLowerCase()}</span>;  // Show unchanged value for 'UNKNOWN' or other statuses
    }
  };

    
  // Helper function to get icon based on matchStatus
  const getMatchStatusIconMobile = (matchStatus) => {
    switch (matchStatus) {
      case 'MET':
        return <span style={{ color: 'green' }}>&#10003; Met</span>; // Checkmark
      case 'NOT_MET':
        return <span style={{ color: 'red' }}>&#10007; Excludes</span>; // Cross
   
    }
  };

  const renderAdditionalBiomarkersInfo = () => {
    // You can customize the content and presentation of the help information here
    return (
      <div>
        <p>
          Testing these biomarkers would help assess patient eligibility for the trial.
        </p>
      </div>
    );
  };
 
  return (
    <div className="mb-4">
         <div onClick={handleShowModal} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <h2 style={{ marginRight: '18px' }}>{trial.identifier} {trial.alias && ` (${trial.alias})`}<br></br>
        <small style={{ fontSize: 'medium'}}>
     

{trialQueries && trialQueries.map((query) => {
  // Check if a match hasn't been found yet and the current query has a match
  if (!matchFound && query.resultIdentifiers && query.resultIdentifiers.includes(trial.identifier)) {
    // Set the flag to true to indicate that a match has been found
    matchFound = true;
    return (
      <div className="mt-1" key={query.id}>
        <div title={'This trial was found by the query: ' + getFriendlyText(query.type)}>
          {getFriendlySymbols(query.type)}
        </div>
      </div>
    );
  }
  return null; // Skip rendering if a match has been found in a previous query
})}
        </small>
        </h2>
              
     
        <p>
      {trial.title}<br></br>
      {trial.closestLocation && trial.closestLocation.institution && (
        <div className="mt-3" style={{color:'#bdd0e0'}} title="Nearest recruiting location">
          {trial.closestLocation.institution} {trial.closestLocation.city}, {trial.closestLocation.country} is recruiting patients
        </div>
        
      )}



    </p>
      
      </div>
 

       {/* Modal Dialog */}
       <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title><a href={`https://www.clinicaltrials.gov/study/${trial.identifier}`} target="_blank">{trial.identifier}  {trial.alias && ` (${trial.alias})`}  <FontAwesomeIcon icon={faExternalLinkAlt} /> </a> </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative' }}>
 
      {/* Sticky Assessed Case */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          position: 'sticky',
          top: 0,
          zIndex: 100,
          backgroundColor: 'purple',
          color: 'white',
          border: '1px solid #80008029',
          padding: '10px',
          borderRadius: '8px',
          marginBottom: '12px',
        }}
      >
        <div style={{display: isPanelCollapsed ? 'none' : 'block'}}>
          {(trial.eligibilityCriteria && patientData!=null) && (
            <p>
              <b>Assessed Patient Description:</b>
              <br /> {patientData.patientDescription}
            </p>
          )}

          {(trial.eligibilityCriteria == null && patientData!=null) && (
            <p>
              <b>Patient Description to Assess:</b>
              <br /> {patientData.patientDescription}
            </p>
          )}
        </div>

        {/* Toggle Button */}
        <button onClick={togglePanelVisibility}>
          {isPanelCollapsed ? 'Show Patient Description' : 'Collapse'}
        </button>
      </div>
    

          <h3>{trial.title}</h3>
      

          {trial.simple && <TrialInfo data={trial.simple} />}
          <Reasons trial={trial} />  

         <ClassifiedTrialFeedbackForm trialID={trial.identifier} requestID={reportID} email={email} aiEligibility={trial.eligibilityStatus} hasEligibilityCritera={trial.eligibilityCriteria!=null && trial.eligibilityCriteria.length>0}></ClassifiedTrialFeedbackForm>
         
          {(shouldHideEligibilityCriteria && hideEligibility ) &&    <button 
            onClick={handleCheckElligbilityButtonClick}> {loadingEligibilityCheck &&<LoadingIcon /> } Force Check Elligibility</button>}
         
            <ParseEligibilityCriteria trial={trial} />

      


            {trial && trial.agroups && trial.agroups.length >0 && 
            <>
            <h3>Participant groups</h3>

            <ul style={{ listStyleType: 'none', padding: 0 }} >

              <>
                {trial.agroups.map((group, index) => (
                  <li key={index} style={{ borderLeft: `4px solid  ${colors[index % colors.length]}`, paddingLeft:'5px'}}>
                    <h6>  <input title="check this group to view the associated enrollement criteria"
              type="checkbox"
              value={group.groupname}
              onChange={(e) => handleGroupSelection(e.target.value)}
              checked={selectedGroups.includes(group.groupname)} 
                          /> {group.groupname} </h6>
                     
                    <p className="prose ai-writing " >  <span style={{fontWeight: "normal"}}>{getRelevanceTranslation(group.relevance)} to this patient </span><br></br>
                    {group.explanation} <br></br>
                      {trial.groupToEligibilityStatus && (

                        <span> Group status: {getEligibleStatusIcon(trial.groupToEligibilityStatus[group.groupname])} </span>)} ({getEligibilityCriteriaCount(group.groupname)} criteria) </p>
                  </li>))}
              </>
            </ul><br></br> </>}

            {(shouldHideEligibilityCriteria && hideEligibility )? null : <>
 

          {/* Eligibility Criteria */}
          <h3>Eligibility Criteria</h3>
          {selectedGroups.length > 0 && (
  <p className="text-muted"><small>Filtered by Selected Participant Groups</small></p>
)}
<div className="d-sm-none">
  <p>green: inclusion, red: exclusion. Statuses "Met" and "Excludes" are indicated when it could be deducted from the patient description. Tap for details.</p>
          {trial.eligibilityCriteria &&
  trial.eligibilityCriteria.map((criteria) => (
    criteria.task !== null && ( <>
      <button style={{margin:'4px', padding:'4px'}}  onClick={() => handleButtonClick(criteria.id)}  title={criteria.type === "INCLUSION" ? "This criterion is for inclusion" : "This criterion is for exclusion"}  className={criteria.type === "INCLUSION" ? "inclusion-button" : "exclusion-button"} key={criteria.id}>
        {criteria.task.criterion} {getMatchStatusIconMobile(criteria.matchStatus)} 
      </button>
      {popoverState[criteria.id] && (
         <div> {criteria.type === 'INCLUSION'
         ? 'Inclusion'
         : 'Exclusion'}: {criteria.text} ({criteria.groupName})<br></br>
 

        <p className='prose ai-writing mt-2'> {criteria.explanation} </p>
         
         
          </div>    

             

              )}
</>
    )
  ))}</div>
          
          <table className="table table-responsive d-none d-md-table">
            <thead>
              <tr >
                <th>Text</th>
                <th>Type</th>
             
                <th>  AI thoughts and observations</th>
                <th>Final AI Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {trial.eligibilityCriteria &&  trial.eligibilityCriteria.map((criteria) => (
                
               ( (selectedGroups.length === 0 || selectedGroups.some(group => group.toLowerCase() === criteria.groupName.toLowerCase())) ) && // Filter based on selectedGroups or show all if none selected
                <tr key={criteria.id}>
                  <td> {criteria.text}  </td>
                  <td>{criteria.type === 'NOT_A_CRITERION' ? 'not a criterion' : criteria.type}</td>
              
                  <td className="ai-writing">  <div  style={{ 
                   marginBottom: '5px',  color: '#795548' 
               }}>{criteria.task !== null && (
                <b>  {criteria.task.criterion}   {(criteria.groupName != null && criteria.groupName.trim() !== '') && (
                  <>
                 <span    className={`enrollementGroupName${criteria.groupName === 'All Participants' ? ' allParticipants' : ''}`}
 title={`${criteria.groupName === 'All Participants' ? 'This criterion applies to all participants' : 'This criterion applies to participants in a specified group: ' + criteria.groupName}`}>{criteria.groupName}</span>
                  </>
                )  }</b>                
              )}
            
              </div>    

 


              <div  style={{ 
                   marginBottom: '5px', 
                   color: '#795548' 
               }}>{criteria.task !== null && (<i> {criteria.task.prompt}</i> )}</div>  

{!criteria.explanation && criteria.type != 'NOT_A_CRITERIA'  ? (
        <span style={{ fontSize: 'larger', color: '#585151' }}> <LoadingIcon /> </span>
      ) : null}

                <div>  {criteria.explanation}</div>    
          
             
                  {criteria.patient_biomarkers_missing && criteria.patient_biomarkers_missing.length > 0 && (
                   <div style={{ 
                   marginTop: '5px', 
                   color: 'purple' 
               }}> <span>Missing biomarker status:</span>
                    <ul>
                          {criteria.patient_biomarkers_missing.map((biomarker, index) => (
                              <li key={index}      >{biomarker} 
                                </li> 
                          ))}
                      </ul></div>
                  )}
                  </td>
                  <td style={{ color: getMatchStatusColor(criteria.matchStatus) }}>{criteria.type === 'NOT_A_CRITERION' ? 'not a criterion' : getMatchStatusIcon(criteria.matchStatus)} 
                  {!criteria.matchStatus && criteria.type != 'NOT_A_CRITERION'    ? (
        <span style={{ fontSize: 'larger', color: '#585151' }}> <LoadingIcon /> </span>
      ) : null}
                  
                  </td>
                  <td>
         {selectedCriterion === criteria ? (
          <div>
          <select
            value={(feedback[criteria.text] && feedback[criteria.text].feedbackType) || ''}
            onChange={(e) => {
              setFeedback((prevFeedback) => ({
                ...prevFeedback,
                [criteria.text]: {
                  ...prevFeedback[criteria.text],
                  feedbackType: e.target.value,
                },
              }));
            }}
          >
            <option value="" disabled>Select expert assessment</option>
            <option value="MET">Met</option>
            <option value="NOT_MET">Excludes</option>
            <option value="UNKNOWN">Unknown</option>
            <option value="NOT_APPLICABLE">Not Applicable</option>
          </select>
          <textarea
            value={(feedback[criteria.text] && feedback[criteria.text].explanation) || ''}
            placeholder="Enter your corrected explanation"
            onChange={(e) =>
              setFeedback((prevFeedback) => ({
                ...prevFeedback,
                [criteria.text]: {
                  ...prevFeedback[criteria.text],
                  explanation: e.target.value,
                },
              }))
            }
          />
          <button
            onClick={() =>
              handleSubmitFeedback(
                criteria,
                feedback[criteria.text]?.feedbackType,
                feedback[criteria.text]?.explanation
              )
            } disabled={
              !(feedback[criteria.text]?.feedbackType || feedback[criteria.text]?.explanation)
            }
          >
            Submit Feedback
          </button>
          <button onClick={() => toggleFeedbackVisibility(criteria)}>Cancel</button>
        </div>
        
         ) : (
            <button onClick={() => toggleFeedbackVisibility(criteria)} title="Teach AI | Send feedback to make AI perform better for you"> <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} />  </button>
         )}

<button  onClick={() => askAgain(criteria)} title="Ask Again AI"  disabled={loadingStates[criteria.text]}  >   {loadingStates[criteria.text] ? (
    // Display loading icon while loading
    <span style={{ fontSize: 'larger', color: '#585151' }}> <LoadingIcon /> </span>
  ) : (
    // Display FaRedo icon when not loading
    <FaRedo style={{ marginRight: '9px' }} />
  )} </button>
      </td>
                </tr>
              ))}





            </tbody>
          </table>

          {!trial.eligibilityCriteria || trial.eligibilityCriteria.length ==0  || !trial.eligibilityStatus ? (<div className="callout" style={{backgroundColor:'antiqueWhite', padding:'8px'}}>
        <span style={{ fontSize: 'larger', color: '#585151' }}> <LoadingIcon /> MatchTrialLLM is assessing each eligibility criteria (the tasks are queued)... 
        <p style={{   color: '#585151' }}>You can close this screen and return later. This process may take some time, and we will send you an email notification once it's ready for your review. Thank you for your patience!</p></span></div>
      ) : null}
        </>}
          {trial.additional_biomarkers && trial.additional_biomarkers.length > 0 && ( <div>
          {/* Additional Biomarkers */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 style={{ marginRight: '8px' }}>Biomarkers need testing</h3>
            <BsQuestionCircle
              data-bs-toggle="popover"
              data-bs-placement="top"
              title={renderAdditionalBiomarkersInfo()}
              data-bs-content={renderAdditionalBiomarkersInfo()}
            />
          </div>
                 
                      <ul>
                          {trial.additional_biomarkers.map((biomarker, index) => (
                              <li key={index}>{biomarker}</li>
                          ))}
                      </ul>
                      </div>    )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


   
      <hr />
    </div>
  );
};

export default TrialResult;
