import React from 'react';
import { Accordion, Card, OverlayTrigger, Button, Popover } from 'react-bootstrap';
 
import Tooltip from 'react-bootstrap/Tooltip';
const TrialInfo = ({ data }) => {

    //console.log(data);

    if (data == null) {
        return;

    }


    const renderPopover = (term, definition) => (
        <Popover id={`popover-${term}`}>
            <Popover.Title as="h3">{term}</Popover.Title>
            <Popover.Content>{definition}</Popover.Content>
        </Popover>
    );

    const GlossaryTooltip = ({ term, definition }) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${term}`}>{definition}</Tooltip>}
        >
          <span style={{ borderBottom: '1px dotted underline', cursor: 'pointer' }}>
            {term}
          </span>
        </OverlayTrigger>
      );
      const GlossaryHighlighter = ({ content, glossary }) => {
        const glossaryTerms = glossary.map((item) => item.term);
        const regex = new RegExp(glossaryTerms.join('|'), 'gi');
        const contentReplaced = content.replace(regex, (match) => {
          const glossaryItem = glossary.find((item) => item.term.toLowerCase() === match.toLowerCase());
          if (glossaryItem) {
            return <GlossaryTooltip key={glossaryItem.id} term={match} definition={glossaryItem.definition} />;
          } else {
            return match;
          }
        });
      
        return <p dangerouslySetInnerHTML={{ __html: contentReplaced }} />;
      };
      
    const highlightMatchingWords = (text) => {
    
      
        const glossaryTerms = data.glossary.map(item => item.term);

        const regex = new RegExp(glossaryTerms.join('|'), 'gi'); 
         const description = text || '';

         var descriptionReplaced = description.replace(regex, (match) => {
            const glossaryItem = data.glossary.find((item) => item.term.toLowerCase() === match.toLowerCase());
            return glossaryItem 
              ? `<span class="underlined-dark" title="${glossaryItem.definition}">${match}</span>`
              : match;
          });
  
    return descriptionReplaced;
      };
    
    


     return(
        <div className='mt-4'> 
            <div className='mt-3'><h5>{'What is being studied and why'}</h5><div>  <span dangerouslySetInnerHTML={{ __html: highlightMatchingWords(data.what_is_being_studied_and_why) }} /></div>
            </div>
             
      
    
            <div className='mt-3'>
            <h5>{'Drug administration and study size'}</h5><div>    <span dangerouslySetInnerHTML={{ __html: highlightMatchingWords(data.drug_administration_and_study_size) }} />  </div>
            </div>
        </div>


    );
};

export default TrialInfo;
