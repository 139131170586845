import React, { useState, useEffect } from 'react';
import PatientForm from './components/PatientForm';
import TrialTabs from './components/TrialTabs';
import About from './components/About';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import ComingSoonPage from './components/ComingSoonPage';
 import Home from './components/Home';
 import ReactGA from 'react-ga4';
 import config from './config';

import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter as Router, Routes, Route      } from 'react-router-dom';
import DatasetCase from './components/DatasetCase';
import DatasetCSIRO from './components/DatasetCSIRO';
import CurateCases from './components/CurateCases';

import CurateTrials from './components/CurateTrials';
 

function App() {
  const [patientData, setPatientData] = useState(null);
  const [trials, setTrials] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [evalData, setEvalData] = useState(null);
  // Initialize Google Analytics with your GA4 measurement ID
ReactGA.initialize(config.ga4_ID);
const server_url = config.server_url;


const handlePopoverShow = () => {
  setPopoverVisible(true);
};

const handlePopoverHide = () => {
  setPopoverVisible(false);
};


const handlePopoverToggle  = () => {
  // Toggle the visibility of the popover
  setPopoverVisible((prevVisible) => !prevVisible);
};


const [popoverVisible, setPopoverVisible] = useState(false);

const popover = (
  <Popover id="popover-basic">
    {/*<Popover.Header as="h3">Patient Description</Popover.Header>*/}
    <Popover.Body>     
      <p>These metrics represent the performance of TrialMatchLLM in assessing the overall eligibility of patients to enroll in trials.</p>
      <p>Continuous monitoring, the last measurement is shown here. See also the Datasets page.</p>
    </Popover.Body>
  </Popover>
);


const formatDateDifference = () => {
  if (evalData ==null || !evalData.modifiedDate) return '';
  const now = new Date();
  const updateDate = new Date(evalData.modifiedDate);
  const diffMilliseconds = now - updateDate;
  const diffSeconds = Math.floor(diffMilliseconds / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
      return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
  } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours === 1 ? '' : 's'} ago`;
  } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes === 1 ? '' : 's'} ago`;
  } else {
      return `${diffSeconds} second${diffSeconds === 1 ? '' : 's'} ago`;
  }
};

const formatShortDate = () => {
  if (evalData == null || !evalData.modifiedDate) return '';

  const now = new Date();
  const updateDate = new Date(evalData.modifiedDate);
  const diffMilliseconds = now - updateDate;
  const diffSeconds = Math.floor(diffMilliseconds / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    return updateDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  } else if (diffHours > 0) {
    return updateDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  } else if (diffMinutes > 0) {
    return updateDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  } else {
    return updateDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }
};

useEffect(() => {
  // Function to fetch evaluation data
  const fetchEvalData = async () => {
      try {
          const response = await fetch(`${server_url}/api/assessmentMetrics`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              },
            
          });
          if (!response.ok) {
              throw new Error('Failed to fetch evaluation data');
          }
          const data = await response.json();
          setEvalData(data);
      } catch (error) {
          console.error(error);
          // Handle error
      }
  };

  fetchEvalData(); // Call the fetchEvalData function when the component mounts
}, []); // Empty dependency array ensures that this effect runs only once after the initial render


  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
 

  const handleFormSubmit = (data, reportID) => {

    // Perform any additional logic, such as fetching trials based on patientData
    setTrials(data);
   // console.log("trial data updated", data);
    setFormSubmitted(true);
  };


  const handleClearPatientDescription = () => {
    setFormSubmitted(false);
    setPatientData(null);
  };
  const handleModifyPatientDescription = () => {
    setFormSubmitted(false);
   
  };
  return (  <Router>




    <div className="app-container" >
 


    <header  onClick={handleClearPatientDescription}  className=" custom-header" >
    <Navbar color="light" light expand="md" className="fixed-top d-md-none">
      <NavbarBrand href="/">Trial Match LLM</NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
        <NavItem>
            <NavLink href="/">New Assess Job</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/federate">Federate</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/datasets">Datasets</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/about">About and Contact</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/privacy">Privacy</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/terms">Terms</NavLink>
          </NavItem>
       
        </Nav>
      </Collapse>
    </Navbar>
    <div   className="d-none d-md-block"  >
    <a href="/"><img src="/TML-black-vertical.png" alt="TrialMatchLLM Logo" className="logo"  style={{ maxWidth: '100%' }} /></a>
  </div>
  <div  className="mt-5 mb-2 d-md-none"    >
    <a href="/"><img src="/TML-forest-horizontal.png" alt="TrialMatchLLM Logo" className="logo"  style={{ maxWidth: '100%' }} /></a>
  </div>
 
        <div className="d-none d-md-block">
          <a className="secondary-link" href="/about">About </a> |  <a href="/privacy" className="secondary-link"> Privacy</a> |  <a className="secondary-link" href="/terms"> Terms</a> |  <a className="secondary-link" href="/federate">Federate </a>  |  <a className="secondary-link" href="/datasets">Datasets </a>
        </div>
        {evalData && (
          <div className="accuracy-panel d-none d-md-block">
            <h5 >Eligibility Evaluation Metrics <OverlayTrigger
        trigger={['hover', 'focus']} 
        placement="right"
        overlay={popover}
      >
        <span
          className="info-icon"
          onMouseOver={handlePopoverShow}
          onMouseOut={handlePopoverHide}
          onFocus={handlePopoverShow}  // Add onFocus event handler
          onBlur={handlePopoverHide}   // Add onBlur event handler
          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
          onClick={handlePopoverToggle} 
        >
          <FontAwesomeIcon style={{paddingLeft:'30px'}} icon={faInfoCircle} />
        </span>
      </OverlayTrigger> </h5>
          
            <div className="accuracy-info">

              <span>{evalData.modifiedDate &&
                <span title={formatDateDifference()}>Computed: {formatShortDate()} |{' '}</span>
              }Total items:  {evalData.total}</span><br />
              <span>Human reviewed items: {evalData.totalFeedback}</span><br />
              <span>Correct: {evalData.correct}</span> | <span>Ambigous: {evalData.unknown}</span> | <span>Rejected: {evalData.rejected}</span><br />

              <div><span role="img" aria-label="accuracy">🎯</span><b>Accuracy: {evalData.accuracy.toFixed(2) * 100}%</b>{evalData.accuracyConservative !== null && evalData.accuracyConservative !== 0 ? ` (conservative: ${evalData.accuracyConservative.toFixed(2) * 100}%)` : ''}</div>

            </div>
          </div>
            )}
       
  
    </header>

    <main className="content-container px-3 mb-5"   >
      <h1 className='header d-none d-md-block'>Trial Match LLM</h1>
      <p className='subtitle d-none d-md-block'>Harnessing LLM for Eligibility Assessment in Clinical Research</p>
     
  
        <Routes>
          <Route
            path="/request"
            element={<PatientForm setPatientData={setPatientData} onSubmit={handleFormSubmit} />}
          />

          <Route
            path="/trials/:reportID"
            element={<TrialTabs patientData={patientData} trials={trials} />}
          />

<Route path="/" element={<Home  />} />

      
          <Route path="/about" element={<About   />} />
          <Route path="/terms" element={<Terms   />} />
          <Route path="/privacy" element={<Privacy   />} />
          <Route path="/federate" element={<ComingSoonPage   />} />
          <Route path="/datasets" element={<DatasetCase   />} />
          <Route path="/datasets-csiro" element={<DatasetCSIRO   />} />
          <Route path="/curate" element={<CurateCases   />} />
          <Route path="/curateTrials" element={<CurateTrials   />} />
        </Routes>

     
        

    </main> 

     {/* Footer */}
     <footer className=" d-none d-md-block fixed-bottom footer-container mt-5 text-center" style={{backgroundColor:"#11111f", color:"#bdd0e0"}}>
          <p>&copy; {new Date().getFullYear()} TrialMatchLLM. Made in Norway. </p>
        </footer>

        <footer className=" d-md-none  footer-container mt-5 text-center" style={{backgroundColor:"#11111f", color:"#bdd0e0"}}>
          <p>&copy; {new Date().getFullYear()} TrialMatchLLM. Made in Norway ✨</p>
        </footer>

  </div></Router>
  );
}

export default App;
