import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaSpinner } from 'react-icons/fa';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingIcon = () => {
  // You can customize the loading icon styles here
  const iconStyles = {
    fontSize: '24px', // Adjust the size as needed
    margin: 'auto', // Center the icon
    display: 'block',
    color:'white'
  };

  return (
    <span>
    <FontAwesomeIcon icon={faSpinner} spin />
       
  
    </span>
  );
};

export default LoadingIcon;
