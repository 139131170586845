import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Alert } from 'reactstrap';
import config from '../config';
import { Helmet } from 'react-helmet';
const ComingSoonPage = () => {
  const [email, setEmail] = useState('');
  const server_url = config.server_url;
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleSubscribe = async () => {
    // Placeholder for subscription logic
    // You should implement the actual logic to handle email subscriptions, e.g., send a request to your backend
   // console.log(`Subscribed with email: ${email}`);
    // Reset the email input after subscription

    var message = null;
    var page ="FEDERATE";
    var formData = {
        message, email, page

    }

    fetch(`${server_url}/api/contact/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
          //  console.log("handleFormSubmit", data);
            // Set success status
            setSubmissionStatus('success');
            // Update the local state using setSelectedCriterion
            // setLoading(false);
        })
        .catch((error) => {
            // Set error status
            setSubmissionStatus('error');
            console.error('Error during form submission:', error);
        });

        
    setEmail('');
  };

  return (
    <Container>
          <Helmet>
        <title>Federate</title>
        <meta name="description" content="Subscribe to the TrialMatchLLM Federated Server and Stay Updated with its Newsletter." />
      </Helmet>
      <Row className="mt-md-5">
      <h2>Federate</h2>
          <p className='subtitle  d-md-none'>Harnessing LLM for Eligibility Assessment in Clinical Research</p>
      
          <p className="prose">Interested in accessing TrialMatchLLM Federated Server? Stay informed? 

Simply enter your email address associated with your healthcare or life science organization.</p>
        <Col sm="12" md={{ size: 8  }}>
         
          <Form>
            <FormGroup>
            
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" onClick={handleSubscribe}>
              Subscribe
            </Button>
            {submissionStatus === 'success' && (
        <Alert color="success">Submission successful! Thank you for subscribing.</Alert>
      )}
      {submissionStatus === 'error' && (
        <Alert color="danger">Oops! Something went wrong. Please try again later.</Alert>
      )}

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ComingSoonPage;
