import React, { useState , useEffect} from 'react';
 
import config from '../config';
 
const ParseEligibilityCriteria = ({ trial, showData = false , groups=null, showIdentifier = false}) => {
    const [pgroups, setPgroups] = useState(groups);
  const [showHighlightedText, setShowHighlightedText] = useState(showData);
  const server_url = config.server_url;
  const [detachedWindow, setDetachedWindow] = useState(null);

  const openInNewWindow = () => {
    const newWindow = window.open('', '_blank');
    newWindow.document.write('<html><head><title>Detached Content</title></head><body>');
    newWindow.document.write(document.querySelector('.webpage-new').innerHTML);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    setDetachedWindow(newWindow);
  };

  const colors = ['blue', 'orange', 'purple', 'yellow', 'cyan', 'magenta', 'pink', 'teal', 'indigo', 'maroon'];

  useEffect(() => {
    //console.log(showHighlightedText);
  }, [showHighlightedText]);

  const highlightText = (rawText, inclusionList, exclusionList, eligibilityStatusMap) => {
    let highlightedText = rawText;
  
    const highlightWithColor = (text, color) => {
      return `<span style="background-color: ${color};">${text}</span>`;
    };

    const underlineWithColor = (text, color) => {
      return `<span style="border-bottom: 8px solid   ${color};">${text}</span>`;
    };
  
    const addStartEndIcons = (text, startIcon, endIcon) => {
        return `${startIcon}${text}${endIcon}`;
      };

    if (inclusionList != null && inclusionList.length > 0) {

      inclusionList.forEach((keyword) => {
        const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`(?<=\\W|^)${escapedKeyword}(?=\\W|$)`, 'gi');
        highlightedText = highlightedText.replace(regex, (match) =>
          addStartEndIcons(highlightWithColor(match, 'lightgreen'), '🟢 ', ' 🟢')
        );

      });
    }
    if (exclusionList != null && exclusionList.length > 0) {
      exclusionList.forEach((keyword) => {
        const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`(?<=\\W|^)${escapedKeyword}(?=\\W|$)`, 'gi');
        highlightedText = highlightedText.replace(regex, (match) =>
          addStartEndIcons(highlightWithColor(match, 'lightcoral'), '🔴 ', ' 🔴')
        );
      });
    }

 /*
  Object.keys(eligibilityStatusMap).forEach((group) => {
    const escapedGroup = group.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    console.log(escapedGroup)
    const regex = new RegExp(`(?<=\\W|^)${escapedGroup}(?=\\W|$)`, 'gi');
    highlightedText = highlightedText.replace(regex, (match) =>
      addStartEndIcons(highlightWithColor(match, 'orange'), '🟠 ', ' 🟠')
    );
  });*/

      if (pgroups != null) {
          pgroups.forEach((group, index) => {
              const groupName = group.name;
              const escapedGroupName = groupName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
              const phrasesRegex = group.phrases.map(phrase => `(${phrase})`).join('|');
              const groupRegex = new RegExp(`(?<=\\W|^)(${escapedGroupName}|${phrasesRegex})(?=\\W|$)`, 'gi');

              highlightedText = highlightedText.replace(groupRegex, (match) =>

                  addStartEndIcons(underlineWithColor(match, getColorByIndex(index)), ` `, ` `)
              );
          });

        };
          return { __html: highlightedText.replace(/\n/g, '<br/>') };
     
  }

function getColorByIndex(index){
  return colors[index % colors.length];
}
    function getColor(groupName) {

      

        switch (groupName) {
            case "AD Cohort":
                return 'orange'; // Use a different color for each group
            case "PS Cohort":
                return 'red';
            case "Control Group":
                return 'lightblue';
            case "Healthy Volunteers":
                return 'lightblue';

            case "Active-Controlled group":
                return 'lightblue';

            case "All participants":
                return 'transparent';

            case "WOCBP":
                return 'pink';
                case "Females of Childbearing Potential":
                    return 'pink';
            case "Women of childbearing potential":
                return 'pink';

            default:
                return 'orange'; // Default to orange if the group name is not recognized
        }
    }


  ///report/{reportID}/trial/{trialID}/pgroups

    const handleGroupsUpdate = () => {

        const trialID = trial.id;

        fetch(`${server_url}/api/trial/${trialID}/pgroups/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
              //  console.log("handleGroupsUpdate", data);
                setPgroups(data);
            });

    };


  // Add the following code to request an update when exclusion criteria are not extracted correctly
  const handleExclusionCriteriaUpdate = () => {
    const newRegex = prompt("Please provide the updated regex for exclusion criteria:");

    if (newRegex) {
       fetch(`${server_url}/updateExclusionCriteriaRegex`, { newRegex })
        .then(response => {
         // console.log(response.data);
        })
        .catch(error => {
          console.error('Error updating regex:', error);
        });
    }
  };

 

  const handleButtonClick = () => {
    setShowHighlightedText(!showHighlightedText);
  };

  return (
    <div  style={{ backgroundColor: 'lightgray', padding: '8px' , marginBottom:'8px'}} >
  { showIdentifier &&     <h2>{trial.identifier}</h2> }
    { !showIdentifier && <button onClick={handleButtonClick}>
        {showHighlightedText ? 'Hide Eligibility Text' : 'Show Annotated Eligibility Text'}
  </button> }


      {showHighlightedText && (        <> {trial.studyPopulation && <p><b>Study population: </b>{trial.studyPopulation}</p>}
  <div
    dangerouslySetInnerHTML={highlightText(trial.raw_eligibility_criteria, trial.inclusion, trial.exclusion, trial.groupToEligibilityStatus)}
    style={{ whiteSpace: 'pre-line' }}
  /></>
)}

 {/* Add a button for the user to request an update for exclusion criteria regex 
 <button onClick={handleExclusionCriteriaUpdate}>
        Request Update for Exclusion Criteria Regex
      </button>

      <button onClick={handleGroupsUpdate}>Fetch Groups</button>
*/}
    </div>
  );
};

export default ParseEligibilityCriteria;
