import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const Reasons = ({ trial }) => {
  const GoodFitIcon = () => <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }}/>;
  const NotGoodFitIcon = () => <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'darkorange' }} />;
  
   // console.log("reason trial", trial);

    if (trial == null) {
        return;

    }


    const highlightMatchingWords = (text) => {
      if (trial == null || trial.reasons == null || trial.reasons.glossary == null) {
        return text;
      }
      
        const glossaryTerms = trial.reasons.glossary.map(item => item.term);

        const regex = new RegExp(glossaryTerms.join('|'), 'gi'); 
         const description = text || '';
         
      

         var descriptionReplaced = description.replace(regex, (match) => {
            const glossaryItem = trial.reasons.glossary.find((item) => item.term.toLowerCase() === match.toLowerCase());
            return glossaryItem 
              ? `<span class="underlined-dark" title="${glossaryItem.definition}">${match}</span>`
              : match;
          });
  
    return descriptionReplaced;
      };
    
    


     return(
      <div className="mb-5">
      
      {trial.reasons &&  trial.reasons.good_fit_reasons && trial.reasons.good_fit_reasons.length>0 && (
<div className="prose reasons ai-writing mt-4" >
{!trial.eligibilityStatus && (
<p><i>The assessment by TrialMatchLLM is currently underway. Please note that, at this stage, the assessment may not fully incorporate eligibility criteria. </i></p>
)}
<h5 className="mb-3 fw-bold"><GoodFitIcon  style={{ color: 'green' }} /> This trial might be a good fit: </h5>
{trial.reasons!= null && trial.reasons.good_fit_reasons!=null &&  trial.reasons.good_fit_reasons.length > 0 ? (
<ul >
  {trial.reasons.good_fit_reasons.map((reason) => (
   ( reason.label !== null || reason.description !=null) && (
      <li key={reason.id} className="ai-writing mb-3">
        <strong>{reason.label}: </strong><span dangerouslySetInnerHTML={{ __html: highlightMatchingWords(reason.description) }} />
      </li>
    )
  ))}
</ul> ) : (
  <p className="ai-writing mb-3">None provided</p>
)}

</div>
)}

{trial.reasons &&  trial.reasons.bad_fit_reasons && trial.reasons.bad_fit_reasons.length>0 && (
<div className="prose reasons ai-writing  " >
<h5 className="mb-3 fw-bold mt-5"><NotGoodFitIcon   style={{ color: 'orange' }} /> This trial might not be a good fit:</h5>
{trial.reasons.bad_fit_reasons.length > 0 ? (
<ul >
  {trial.reasons && trial.reasons.bad_fit_reasons.map((reason) => (
     ( reason.label !== null || reason.description !=null) && (
      <li key={reason.id} className="ai-writing mb-3">
        <strong>{reason.label}: </strong><span dangerouslySetInnerHTML={{ __html: highlightMatchingWords(reason.description) }} /> 
      </li>
    )
  ))}
</ul> ) : (
  <p className="ai-writing mb-3">None provided</p>
)}
</div>
)}
</div>


    );
};

export default Reasons;
