import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {   Form, Dropdown } from 'react-bootstrap';
import config from '../config';
import ExtractCaseFeedbackModal from './ExtractCaseFeedbackModal';
import { Helmet } from 'react-helmet';
import CaseCard from './CaseCard';
import CaseCardModal from './CaseCardModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faThumbsUp, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import LoadingIcon from './LoadingIcon';
import { FaRedo } from 'react-icons/fa';
  

const CurateCases = () => {
    // State to manage game metrics
    const [totalCases, setTotalCases] = useState(0);
    const [verifiedCases, setVerifiedCases] = useState(0);
    const [correctedCases, setCorrectedCases] = useState(0);
    const [remainingCases, setRemainingCases] = useState(0);
    const server_url = config.server_url;
    const [reportID, setReportID] = useState(null);
    // State to manage game data (text paragraphs, extracted content, user corrections, etc.)
    const [gameData, setGameData] = useState([]);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    // State to manage the current displayed case
    const [currentCaseIndex, setCurrentCaseIndex] = useState(0);
    const [selectedCase, setSelectedCase] = useState(null);
    const [showRedoCase, setShowRedoCase] = useState(false);
    const [loadingCase, setLoadingCase] = useState(false);
    const [formattedDate, setFormattedDate] = useState();

    const handleRequestJobClick = () =>  {


    }


    const askAgain = () => {
      const requestID = reportID;
      const casedID = selectedCase.id;
  
      setLoadingCase(true);
  
  
      fetch(`${server_url}/api/report/${requestID}/casedesc/${casedID}/askAgain`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          // Handle the fetched trial data
        //  console.log('Fetched Redo:', data);
          //TODO refresh criteria
          setSelectedCase(data);
          const modifiedDate = new Date(data.modifiedDate);
          const fd = modifiedDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          setFormattedDate(fd);
  
        })
        .catch((error) => {
          console.error('Error during fetch:', error);
          // Handle errors as needed
        }).finally(() => {
          setLoadingCase(false);
          setShowRedoCase(false);

        });
    };

    

    // useEffect to fetch game data from the backend when the component mounts
    useEffect(() => {
        // Replace the URL with your actual backend endpoint
        const backendUrl = `${server_url}/api/syntheticdata/dataset/to_assess`;

        // Fetch game data from the backend
        fetch(backendUrl)
            .then((response) => response.json())
            .then((data) => {
                // Set initial game data and update metrics
                setGameData(data.toAssess);
                setTotalCases(data.countTotal);
                setRemainingCases(data.countToAssess);
                setCorrectedCases(data.countCorrected);
                setVerifiedCases(data.countConfirmed);
            })
            .catch((error) => {
                console.error('Error fetching game data:', error);
                // Handle error, e.g., show a message to the user
            });
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts


    const handleTypeSelection = (selectedType, index) => {
        const updatedBiomarkers = [...selectedCase.biomarkers];
        updatedBiomarkers[index].type = selectedType;
        setSelectedCase((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
      };

      const handleNameChange = (name, index) => {
        if (name !== null && name !== undefined) {
          const updatedBiomarkers = [...selectedCase.biomarkers];
          updatedBiomarkers[index].name = name.trim(); // Trim the new name
          setSelectedCase((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
        }
      };
      
      const handleValueChange = (value, index) => {
        if (value !== null && value !== undefined) {
          const updatedBiomarkers = [...selectedCase.biomarkers];
          updatedBiomarkers[index].value = value.trim(); // Trim the new value
          setSelectedCase((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
        }
      };
  
      const addBiomarker = () => {
        setSelectedCase((prevCaseD) => ({
          ...prevCaseD,
          biomarkers: [...prevCaseD.biomarkers, { type: '', name: '', value: '' }],
        }));
      }; 
       const removeBiomarker = () => {
        setSelectedCase((prevCaseD) => {
            const updatedBiomarkers = [...prevCaseD.biomarkers];
            updatedBiomarkers.pop(); // Remove the last biomarker
            return { ...prevCaseD, biomarkers: updatedBiomarkers };
          });
      }; 

      const shouldDisplayRemoveButton = () => {
        const { biomarkers } = selectedCase;
      
        // Check if the last biomarker has empty values
        const lastBiomarker = biomarkers[biomarkers.length - 1];
    
        return  lastBiomarker== null || lastBiomarker.type === '' && lastBiomarker.name === '' && lastBiomarker.value === '';
      };


    const handleFeedbackSubmit = () => {
        // You can implement logic to handle the submitted feedback  --- gameData[currentCaseIndex].caseD
      //  console.log('Feedback submitted:', selectedCase); 
    
        //FIXME verify that biomarker types is provided if there are any biomarker submitted. 
  
        const storedEmail = localStorage.getItem('userEmail');
        selectedCase.email = storedEmail;
    
        fetch(`${server_url}/api/report/${reportID}/feedback/case_description/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedCase),
        })
          .then((response) => response.json())
          .then((data) => {
           // console.log("feedback saved!", data);
      //FIXME set confirmed case
          });
    
          setCorrectedCases((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
        
          // Close the form after handling the feedback
        setShowFeedbackForm(false);


        setCurrentCaseIndex((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
        
        setRemainingCases(remainingCases - 1);


      };

 

    // useEffect to update selectedCase when currentCaseIndex changes
    useEffect(() => {
        if (gameData != null && gameData.length > 0) {
         //   console.log("found ", gameData[currentCaseIndex].caseD)
            setSelectedCase(gameData[currentCaseIndex].caseD);
           // console.log("in SKIP! New Active Case should be ", selectedCase);
        }
    }, [currentCaseIndex, gameData]);
 // useEffect to log when selectedCase changes
 useEffect(() => {
 //   console.log("selectedCase changed:", selectedCase);

   if (selectedCase!=null && selectedCase.llmmodel != 'HUMAN_EXPERT') {
     setShowRedoCase(true);

   }

}, [selectedCase]);


    // Handler for when a case is verified or corrected
    const handleVerification = (isVerified, userCorrection) => {
        // Update gameData with verification/correction information
        // Update metrics accordingly

        // Move to the next case
        setCurrentCaseIndex((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
    };
    const handleShowFeedback = () => {
    //    console.log("handleShowFeedback");
        setShowFeedbackForm(true);
      }
    // Handler for skipping a case
    const handleSkipCase = () => {
        // Update gameData to mark the case as skipped
        // Update metrics accordingly
//console.log("in SKIP!");

        // Move to the next case
        setCurrentCaseIndex((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
       
      
    };


    const handleExtractCaseGoodJob = () => {

        const storedEmail = localStorage.getItem('userEmail');
        var email = storedEmail;
    
        fetch(`${server_url}/api/report/${reportID}/case_description/${gameData[currentCaseIndex].caseD.id}/good_job/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(email),
        })
          .then((response) => response.json())
          .then((data) => {
           // console.log("feedback saved!", data);
            gameData[currentCaseIndex].isConfirmed=true;

             // Move to the next case
    setCurrentCaseIndex((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
    setSelectedCase(gameData[currentCaseIndex].caseD);
    setVerifiedCases((prevIndex) => (prevIndex + 1 < gameData.length ? prevIndex + 1 : prevIndex));
    setRemainingCases(remainingCases - 1);
          });
    
      }

    return (
        <div>
            <h1>Curate Microbiome Biomarker Cases</h1>

            <div>

                <p> Good job Cases: {verifiedCases} | Corrected Cases: {correctedCases} | Remaining Cases to Assess: {remainingCases}</p>
 
            </div>
            {selectedCase!=null   && (

              <>  <CaseCard key={selectedCase.id} data={selectedCase}   /> 
                <Button onClick={() => handleExtractCaseGoodJob()} className="mx-2  " variant="success" title="Confirm that AI accurately extracted patient description"><FontAwesomeIcon icon={faThumbsUp} /> Good Job!</Button>               
             {!showFeedbackForm &&    <Button  onClick={() => handleShowFeedback()} title="Teach AI | Send feedback to make AI perform better for you"> <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} /> Teach AI </Button>               }
             {showRedoCase && (<Button className="mx-2  "  title="Ask again AI" onClick={askAgain} disabled={loadingCase}>  {loadingCase ? <LoadingIcon /> : null}
                        <FaRedo style={{ marginRight: '3px', marginLeft: '3px'  }} /></Button>)} 
                <Button onClick={() => handleSkipCase()}  variant="default" title="Skip when you don't know"><FontAwesomeIcon icon={faThumbsUp} /> Skip</Button>               

                { (gameData[currentCaseIndex].metrics == null || 
  ((gameData[currentCaseIndex].metrics.toAssessRequestIds == null || gameData[currentCaseIndex].metrics.toAssessRequestIds.length === 0) && 
  (gameData[currentCaseIndex].metrics.totalFeedback == null || gameData[currentCaseIndex].metrics.totalFeedback === 0))) && <Button className="mx-4" title="create a trial report for this case" href={`/request?casedesc=${encodeURIComponent(selectedCase.rawtext)}`}>Request trials</Button> }

{ (gameData[currentCaseIndex].metrics != null &&
    gameData[currentCaseIndex].metrics.toAssessRequestIds != null && gameData[currentCaseIndex].metrics.toAssessRequestIds.length > 0) && 
    <Button  className="mx-2" href={`/trials/${gameData[currentCaseIndex].metrics.toAssessRequestIds[0]}`} variant="default" title="Open existing trial report for this case">Open Report</Button>}

                     <span>Evaluated trials: {gameData[currentCaseIndex].metrics.totalFeedback}</span>          
                 </> 
            )}

               
    {  showFeedbackForm &&
        
        <div className="col-md-10" style={{  margin:'8px', padding: "15px", backgroundColor: 'white', color:'gray' }}>
                 
                  <p className="text-muted">Here, you teach AI to properly extract patient description, so that it can perform better next time. This is your expert feedback.</p>
                  <Form>
                    <Form.Group controlId="ageRangeDropdown">
                      <Form.Label>Age Range:</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedCase.ageRange}
                        onChange={(e) => setSelectedCase((prevCaseD) => ({
                          ...prevCaseD,
                          ageRange: e.target.value,
                        }))}
                      >
                        <option value="">Select Age Range</option>
                        <option value="CHILD">Child</option>
                        <option value="ADULT">Adult</option>
                        <option value="OLDER_ADULT">Older Adult</option>
                        <option value="NOT_PROVIDED">Not Provided</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select an age range.
                      </Form.Control.Feedback>
                    </Form.Group>

                    
                    <Form.Label className="mt-3">Exact Age:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={selectedCase.exactAge}
                        onChange={(e) => setSelectedCase((prevCaseD) => ({ ...prevCaseD, exactAge: e.target.value }))}
                      />



                    <Form.Group controlId="feedbackTextArea" className="mt-3">
                      <Form.Label>Disease Category:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={selectedCase.parentDisease}
                        onChange={(e) => setSelectedCase((prevCaseD) => ({ ...prevCaseD, parentDisease: e.target.value }))}
                      />

                      <Form.Label className="mt-3">Main disease:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={selectedCase.maincondition}
                        onChange={(e) => setSelectedCase((prevCaseD) => ({ ...prevCaseD, maincondition: e.target.value }))}
                      />

                      {selectedCase.parentDisease != null && selectedCase.parentDisease.toLowerCase() === "cancer" && <>
                        <Form.Label className="mt-3">Metastases:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Type your feedback here, or leave empty if none in text..."
                          value={selectedCase.metastases}
                          onChange={(e) => setSelectedCase((prevCaseD) => ({ ...prevCaseD, metastases: e.target.value }))}
                        /></>}

                      <Form.Label className="mt-3">Disease subtype and qualifiers:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here (e.g. chronic, newly diagnosed, luminal A, refractory, ...), or leave empty if none provided in text..."
                        value={selectedCase.subtypes}
                        onChange={(e) => {
                          const oCondArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, subtypes: oCondArray }));
                        }}
                    
                      />
 

                      <Form.Label className="mt-3">Biomarkers:</Form.Label>


                      {/* Display type in dropdown and name in input */}
                      {selectedCase.biomarkers && selectedCase.biomarkers.map((biomarker, index) => (
                        <div key={index}>
                          <Dropdown>
                            <Dropdown.Toggle variant="default" id={`dropdown-${index}`}>
                              {biomarker.type || 'Choose Type'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* Add your options for the type dropdown as needed */}
                              <Dropdown.Item onClick={() => handleTypeSelection('MOLECULAR', index)}>
                                MOLECULAR
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('MICROBIOME', index)}>
                                MICROBIOME
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('CLINICAL', index)}>
                                CLINICAL
                              </Dropdown.Item>
                              {/* Add other options as needed */}
                            </Dropdown.Menu>
                          </Dropdown>

                          <Form.Control
                            type="text"
                            placeholder="Biomarker Name"
                            value={biomarker.name}
                            onChange={(e) => handleNameChange(e.target.value, index)}
                          />
                          <Form.Control
                            type="text"
                            placeholder="Biomarker's Patient Value"
                            value={biomarker.value}
                            onChange={(e) => handleValueChange(e.target.value, index)}
                          />
                        </div>
                      ))}

                      <Button variant="secondary" onClick={addBiomarker}>
                        Add Biomarker
                      </Button>
                      <Button variant="secondary" onClick={removeBiomarker} style={{ display: shouldDisplayRemoveButton() ? 'block' : 'none' }}>
                        Remove Biomarker
                      </Button>
                      <br></br>
                      <Form.Label className="mt-3">Genes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Type your genes here, separated by commas..."
                        value={(selectedCase.genes && selectedCase.genes.join(', ')) || ''}
                        onChange={(e) => {
                          const genesArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, genes: genesArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Past treatments:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's past or active treatments, separated by comma..."
                        value={selectedCase.pastTreatments}
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, pastTreatments: rxPastArray }));
                        }}
                      />


<Form.Label className="mt-3">Other Conditions:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's co-conditions, separated by comma... (e.g. pregnancy, athma..)"
                        value={selectedCase.otherConditions}
                        onChange={(e) => {
                          const oCondArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, otherConditions: oCondArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Intervention(s) of interest:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type interventions of interest here..."
                        value={selectedCase.treatmentOptions}
                        onChange={(e) => {
                          const rxArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, treatmentOptions: rxArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred country:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred countries here, separated by commas..."
                        value={(selectedCase.countries && selectedCase.countries.join(', ')) || ''}
                        onChange={(e) => {
                          const countriesArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, countries: countriesArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred city:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred cities here, separated by commas..."
                        value={(selectedCase.cities && selectedCase.cities.join(', ')) || ''}
                        onChange={(e) => {
                          const citiesArray = e.target.value.split(',').map(value => value.trim());
                          setSelectedCase((prevCaseD) => ({ ...prevCaseD, cities: citiesArray }));
                        }}
                      />
                    </Form.Group>
                  </Form>
                  
           
                <Button style={{   marginTop: "35px" }} variant="primary" onClick={handleFeedbackSubmit}>
                  Submit Feedback
                </Button>
                  </div>}

        </div> 
    );  
};

export default CurateCases;