import React, { useState } from 'react';
import { FaMdb } from 'react-icons/fa';
 
const GroupElibiligyTable = ({ tasks, groups = null  }) => { 
  
  const colors = ['blue', 'orange', 'purple', 'yellow', 'cyan', 'magenta', 'pink', 'teal', 'indigo', 'maroon'];
  const [pgroups, setPgroups] = useState(groups);
 

  const typeToColor = {
    'INCLUSION': 'green',
    'EXCLUSION': 'red',
    'NOT_A_CRITERIA': 'gray'
  };
  
  function getColorByGroupName(groupName){
    if (pgroups == null){
     //console.log("group is null")
      return "gray";
    }
    // Find the index of the group with the given groupName
    const index = pgroups.findIndex(group => group.name === groupName);
    // If group not found, return a default color or handle it as needed
    if (index === -1) {
      return "gray";
    }
    // Return the color corresponding to the index
    return colors[index % colors.length];
  }


return (
<table className="table table-responsive d-md-table">
<thead>
  <tr >
    <th className='d-none d-md-table-cell '>Text</th>
    <th className='d-none d-md-table-cell '>Type</th>
    <th>AI plan</th>
  </tr>
</thead>
<tbody>
 

  {tasks &&  tasks.map((criteria) => (
    <tr key={criteria.id}>
      <td className='d-none d-md-table-cell '> {criteria.text}  </td>
      <td className='d-none d-md-table-cell ' style={{ color: typeToColor[criteria.type] }}>{criteria.type}   {(criteria.groupName != null && criteria.groupName.trim() !== '') && (
      <>
     <span    className={`enrollementGroupName${criteria.groupName === 'All Participants' ? ' allParticipants' : ''}`}
title={`${criteria.groupName === 'All Participants' ? 'This criterion applies to all participants' : 'This criterion applies to participants in a specified group: ' + criteria.groupName}`}    style={{ color: getColorByGroupName(criteria.groupName), borderColor: getColorByGroupName(criteria.groupName) }}>{criteria.groupName}</span>
      </>
    )  }</td>
  
      <td className="ai-writing">  <div  style={{ 
       marginBottom: '5px',  color: '#795548' 
   }}>
     <span className='d-md-none' style={{ color: typeToColor[criteria.type] }}>{criteria.type}: </span>       <b>  {criteria.criterion} </b>          


  </div>    




  <div  style={{ 
       marginBottom: '5px', 
       color: '#795548' 
   }}>{criteria.prompt!== null && (<i> {criteria.prompt}</i> )}</div>  
  <div  style={{ 
       marginBottom: '5px', 
       color: '#795548' 
   }}>{criteria.expected_answer_for_eligibility !== null && (<><span style={{color:'black'}}>Expected answer for eligibility:</span> <i>   {criteria.expected_answer_for_eligibility}</i></> )}</div>  
 
      </td>
     
     
    </tr>
  ))}





</tbody>
</table>);

};

export default GroupElibiligyTable;  