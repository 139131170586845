import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Alert } from 'reactstrap';
import config from '../config';
import ExtractCaseFeedbackModal from './ExtractCaseFeedbackModal'; 
import { Helmet } from 'react-helmet';
import CaseCardModal from './CaseCardModal';
 

const DatasetCase = () => {
    const [email, setEmail] = useState('');
    const server_url = config.server_url;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedCase, setSelectedCase] = useState(null);
    const [loading, setLoading] = useState(false);
    const [evalData, setEvalData] = useState(null);

    
  const handleExtractData = (caseData) => {
    setSelectedCase(caseData);
    //console.log(caseData);
  };
   
    useEffect(() => {
      if (selectedCase) {
        setShowModal(true);
        //console.log("show modal");
      }
    }, [selectedCase]);


    
useEffect(() => {
  // Function to fetch evaluation data
  const fetchEvalData = async () => {
      try {
          const response = await fetch(`${server_url}/api/atWithoutFeedback`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              },
            
          });
          if (!response.ok) {
              throw new Error('Failed to fetch evaluation data');
          }
          const data = await response.json();
          setEvalData(data);
      } catch (error) {
          console.error(error);
          // Handle error
      }
  };

  fetchEvalData(); // Call the fetchEvalData function when the component mounts
}, []); // Empty dependency array ensures that this effect runs only once after the initial render



  const handleOpenModal = (caseItem) => {
    setSelectedCase(caseItem);
    // Other logic to handle opening the modal
  };


  const handleCloseModal = () => {
    setSelectedCase(null);
  };

    

    const dataset ={
      cases: [
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 35-year-old male, newly diagnosed with Crohn\'s disease, resides in Oslo, Scandinavia. The patient, in consultation with their gastroenterologist at the Oslo University Hospital, is actively exploring alternative treatments and seeking trials for microbiome-based therapeutics. The patient exhibits elevated levels of Prevotella and reduced diversity of Firmicutes. The search is focused on clinical trials in Scandinavia.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 28-year-old female with a history of ulcerative colitis, based in Stockholm, Scandinavia, has undergone multiple rounds of immunosuppressive treatments at the Karolinska University Hospital. In collaboration with her gastroenterologist, she is actively looking for trials exploring microbiome-targeted therapies to manage IBD symptoms. The search is centered around available clinical trials in Scandinavia.',
        },
        {
          category: 'Type 2 Diabetes',
          casedesc:
            'A 50-year-old female managing long-term type 2 diabetes in Munich, Europe, has been on insulin therapy for several years. Under the guidance of her endocrinologist at Ludwig Maximilian University Hospital, the patient is proactively investigating complementary approaches and is interested in trials focusing on microbiome interventions. The patient exhibits an imbalance in the Bacteroidetes to Firmicutes ratio. The search is particularly relevant to clinical trials available in Europe.',
        },
        {
          category: 'Type 2 Diabetes',
          casedesc:
            'A 45-year-old male with newly diagnosed type 2 diabetes in Chicago, USA, is seeking alternatives to standard medications. In collaboration with his healthcare team at Northwestern Memorial Hospital, he is actively looking for trials exploring microbiome-based interventions. The patient exhibits dysbiosis in gut microbiota, emphasizing the need for personalized approaches. The search is centered around available clinical trials in the USA.',
        },
        {
          category: 'Recurrent Urinary Tract Infections (UTIs)',
          casedesc:
            'A 28-year-old female in Sydney, Australia, experiencing recurrent UTIs, has a history of antibiotic treatment. In collaboration with her urologist at Royal Prince Alfred Hospital, she is actively looking for trials exploring microbiome-targeted therapies to prevent UTI recurrence. The patient exhibits dysbiosis in urogenital microbiota with a low abundance of Lactobacillus. The search is centered around available clinical trials in Australia.',
        },
        {
          category: 'Recurrent Urinary Tract Infections (UTIs)',
          casedesc:
            'A 35-year-old pregnant female in Seattle, USA, is dealing with recurrent UTIs during pregnancy. In collaboration with her obstetrician and urologist at Swedish Medical Center, she is actively looking for microbiome-based trials to prevent UTI recurrence, considering the unique circumstances of pregnancy. The search is centered around available clinical trials in the USA.',
        },
        {
          category: 'Rheumatological Conditions',
          casedesc:
            'A 45-year-old male diagnosed with rheumatoid arthritis resides in Copenhagen, Scandinavia. In consultation with his rheumatologist at Rigshospitalet, the patient is exploring emerging therapies and actively searching for trials investigating the role of microbiome modulation. The patient shows an altered composition of gut microbiota with low levels of Faecalibacterium prausnitzii, emphasizing the need for trials targeting this specific microbiome profile. The search is directed towards clinical trials in Scandinavia.',
        },
        {
          category: 'Rheumatological Conditions',
          casedesc:
            'A 60-year-old female with long-standing rheumatoid arthritis in Paris, Europe, has a history of multiple biologic treatments at Pitié-Salpêtrière Hospital. In collaboration with her rheumatologist, she is actively looking for trials exploring microbiome-based interventions. The patient exhibits a unique microbiome profile, emphasizing the need for personalized approaches. The search is centered around available clinical trials in Europe.',
        },
        {
          category: 'Mental Health',
          casedesc:
            'A 30-year-old female with a history of major depressive episodes in Melbourne, Australia, has been on various antidepressant medications. Working with her psychiatrist at Royal Melbourne Hospital, the patient is exploring novel avenues for managing MDD and actively seeking microbiome-focused trials as a complementary approach. The patient exhibits a reduced abundance of Bifidobacterium and Lactobacillus. The search is focused on clinical trials available in Australia.',
        },
        {
          category: 'Mental Health',
          casedesc:
            'A 40-year-old male in San Diego, USA, diagnosed with treatment-resistant depression, has undergone electroconvulsive therapy. In collaboration with his psychiatrist at UC San Diego Health, he is actively looking for microbiome-focused trials to explore novel therapeutic options. The patient exhibits a unique microbiome profile, emphasizing the need for personalized approaches. The search is centered around available clinical trials in the USA.',
        },
        {
          category: 'Mental Health',
          casedesc:
            'In the heart of London, Alex, a 28-year-old finance professional, finds himself entangled in the relentless grip of chronic worry, restlessness, and an ever-mounting tension that disrupts his daily life. Seeking relief, Alex undergoes microbiome sequencing, uncovering an imbalance in gut bacteria linked to inflammation—an association with anxiety disorders. Enrolled in a clinical study in the heart of Westminster, Alex becomes a participant in an investigation into the efficacy of a prebiotic intervention. This intervention aims to foster the growth of beneficial gut bacteria, potentially alleviating the weight of anxiety that burdens his daily existence.',
        },
        {
          category: 'Mental Health',
          casedesc:
            'In the suburban landscape of Los Angeles, Ethan, a 6-year-old boy diagnosed with Autism Spectrum Disorder (ASD), grapples with challenges in social interaction and communication. Microbiome sequencing sheds light on an altered microbial composition in Ethan\'s gut, notably deficient in bacteria crucial for neurodevelopment. Participating in a research project at a leading medical center in Beverly Hills, Ethan becomes part of an investigation exploring the potential impact of fecal microbiota transplantation (FMT). The study, nestled in the heart of the city of angels, aims to evaluate whether restoring microbial balance through FMT can positively influence the symptoms of ASD.',
        },
        {
          category: 'Mental Health',
          casedesc:
            'Olivia, a 30-year-old woman, confronts the complex tapestry of schizophrenia—a condition that disrupts her thought processes, emotions, and social functioning. Microbiome sequencing exposes dysbiosis in her gut, with an overabundance of certain bacteria linked to inflammation and immune dysregulation: an overabundance of Firmicutes and a depletion of Bacteroidetes. From the bustling Shibuya district in Tokyo, Olivia wishes to become a participant in an exploration of a personalized microbiome-targeted diet and probiotic supplementation, to modulate the gut microbiome and potentially ease the challenging symptoms of schizophrenia.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 40-year-old male diagnosed with celiac disease resides in Helsinki, Scandinavia. In collaboration with his gastroenterologist at Helsinki University Hospital, the patient is investigating potential adjunctive therapies and actively looking for trials exploring microbiome interventions. The patient shows an imbalance in gut microbiota, particularly low levels of Bifidobacterium. The search is concentrated on clinical trials in Scandinavia.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 25-year-old female in Sydney, Australia, recently diagnosed with celiac disease, is exploring dietary changes as part of her treatment. In collaboration with her healthcare team at Westmead Hospital, she is actively looking for microbiome-focused trials to enhance gluten tolerance. The patient exhibits a unique microbiome profile, emphasizing the need for personalized approaches. The search is centered around available clinical trials in Australia.',
        },
        {
          category: 'Skin Conditions',
          casedesc:
            'A 28-year-old male in Stockholm, Scandinavia, has been dealing with chronic psoriasis for several years. Previous treatments include topical steroids and phototherapy with limited success at Karolinska University Hospital. Microbiome sequencing reveals an imbalance in skin microbiota, with increased levels of Staphylococcus aureus. The patient, in collaboration with a dermatologist, is actively seeking clinical trials for microbiome-based therapeutics to address psoriasis. The search is focused on available trials in Scandinavia.',
        },
        {
          category: 'Skin Conditions',
          casedesc:
            'A 10-year-old female in Berlin, Europe, has a history of atopic dermatitis (eczema) since infancy. Topical corticosteroids and emollients have been the mainstay of treatment. Microbiome sequencing indicates a dysbiosis in skin microbiota, with reduced diversity and an overabundance of Staphylococcus epidermidis. The patient\'s pediatrician, in collaboration with a dermatologist at Charité – Universitätsmedizin Berlin, is actively exploring microbiome-focused trials for eczema. The search is centered around available trials in Europe.',
        },
        {
          category: 'Skin Conditions',
          casedesc:
            'a 28-year-old woman from San Francisco, underwent microbiome NGS sequencing at UCSF Medical Center, specifically targeting the skin microbiota. The analysis revealed an imbalance in Cutibacterium and Staphylococcus species, contributing to her recurrent episodes of atopic dermatitis. She was a known case of atopic dermatitis, having received topical steroids and antihistamines. The Multidisciplinary Team is challenged to explore microbiome-based interventions for managing refractory cases of atopic dermatitis.           ',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 35-year-old female in Sydney, Australia, has been managing IBS symptoms for several years with dietary modifications and antispasmodic medications. Microbiome sequencing reveals an altered gut microbiota, with decreased levels of Bifidobacterium and increased levels of Firmicutes. The patient, in collaboration with a gastroenterologist at Royal Prince Alfred Hospital, is actively seeking clinical trials for microbiome-based therapeutics to alleviate IBS symptoms. The search is focused on available trials in Australia.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            ' a 42-year-old female from San Francisco, underwent 5-site microbiome NGS sequencing as part of her diagnostic workup for unexplained gastrointestinal symptoms. The results revealed a dysbiosis characterized by a significant decrease in Bacteroides and an overabundance of Firmicutes. She has been newly diagnosed with irritable bowel syndrome with predominant constipation (IBS-C), and her disease is currently at an early stage.',
        },
        {
          category: 'Skin Conditions',
          casedesc:
            'A 45-year-old female in Paris, Europe, has recently developed psoriasis with severe flare-ups. Traditional treatments include topical corticosteroids and oral immunosuppressants at Pitié-Salpêtrière Hospital. Microbiome sequencing indicates an imbalance in gut microbiota, with reduced levels of Akkermansia muciniphila. The patient\'s dermatologist, in collaboration with a gastroenterologist, is actively exploring microbiome-focused trials to address both skin and gut manifestations of psoriasis. The search is centered around available trials in Europe.',
        },
        {
          category: 'Skin Conditions',
          casedesc:
            'A 30-year-old male in Sydney, Australia, experiences chronic eczema exacerbated by environmental triggers. Previous treatments include topical immunomodulators and antihistamines. Microbiome sequencing indicates dysbiosis in gut microbiota, with reduced levels of beneficial bacteria. The patient, working with a dermatologist and an allergist at Westmead Hospital, is actively seeking clinical trials for microbiome-based interventions for eczema. The search is concentrated on available trials in Australia.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 40-year-old male in Dallas, USA, has been dealing with IBS-D (diarrhea-predominant) symptoms. Previous treatments include dietary modifications and probiotics. Microbiome sequencing reveals dysbiosis in gut microbiota, with an overabundance of Enterobacteriaceae. The patient, in collaboration with a gastroenterologist at UT Southwestern Medical Center, is actively exploring microbiome-focused trials for IBS management. The search is centered around available trials in the USA.',
        },
        {
          category: 'Respiratory Conditions',
          casedesc:
            'A 55-year-old male residing in New York City, had 5-site microbiome NGS sequencing performed following recurrent respiratory infections. The analysis identified a depletion of beneficial commensals and an increased presence of potential pathogens in his respiratory microbiome. He has a longstanding diagnosis of chronic obstructive pulmonary disease (COPD), classified as moderate stage. The patient has undergone bronchodilator therapy in the past but seeks alternative microbiome-based interventions.',
        },
        {
          category: 'Oral Health',
          casedesc:
            'A 60-year-old man residing in New York, underwent microbiome NGS sequencing at Mount Sinai Hospital, focusing on the oral cavity. The results indicated a dysregulation in the oral microbiota, marked by an overgrowth of Streptococcus mutans. He was diagnosed with chronic periodontitis and had undergone traditional periodontal treatments with limited success. The Multidisciplinary Team is tasked with assessing investigational microbiome therapeutics to address the persistent periodontal issues',
        },
        {
          category: 'Cancer',
          casedesc:
            'The microbiome sequencing results for the 55-year-old male with colorectal cancer revealed a significant reduction in the abundance of beneficial commensal bacteria, particularly Bacteroides fragilis, and an elevated presence of Fusobacterium nucleatum. This unique microbial profile has sparked suspicions within the Multidisciplinary Team regarding its potential impact on drug response. Emerging research suggests that Fusobacterium nucleatum may play a role in promoting resistance to certain chemotherapy drugs used in colorectal cancer treatment. The team is exploring investigational treatments that specifically target the microbiome to modulate the tumor microenvironment and potentially enhance the response to chemotherapy. Trials focusing on microbiome-based interventions, particularly those assessing the relationship between Fusobacterium nucleatum and drug resistance, are being actively sought in Europe to inform personalized treatment strategies for this patient.',
        },
        {
          category: 'Cancer',
          casedesc:
            'Microbiome sequencing results for the 38-year-old female with invasive ductal carcinoma, a form of breast cancer, indicated a depletion of beneficial bacteria such as Akkermansia muciniphila and an overabundance of Escherichia coli. The Multidisciplinary Team suspects that this unique microbial composition may influence the patient\'s response to traditional chemotherapy agents. Recent studies have suggested a potential link between specific microbiome profiles and the efficacy of certain chemotherapy drugs in breast cancer. The team is actively exploring clinical trials in the USA that investigate microbiome-targeted therapies to modulate the tumor microenvironment and improve drug response. By tailoring the treatment approach based on the patient\'s microbiome profile, the team aims to enhance the overall effectiveness of breast cancer treatment for this individual.',
        },
        {
          category: 'Cancer',
          casedesc:
            'A 60-year-old male from Copenhagen, Scandinavia, has recently been diagnosed with advanced colorectal cancer. Microbiome sequencing has unveiled a unique microbial composition characterized by a reduced diversity of Bifidobacterium species and an increased presence of Clostridium difficile. Suspecting that this microbiome profile may influence the patient\'s response to chemotherapy, the Multidisciplinary Team at Rigshospitalet is actively searching for ongoing clinical trials in Scandinavia. The team aims to identify trials specifically investigating microbiome-targeted interventions that can synergize with traditional chemotherapy and enhance treatment outcomes for patients with advanced colorectal cancer.',
        },
        {
          category: 'Cancer',
          casedesc:
            'A 45-year-old female in Paris, Europe, has been diagnosed with HER2-positive breast cancer. Microbiome sequencing has revealed an imbalance in gut microbiota, characterized by a low abundance of Lactobacillus species. Recognizing the potential impact of the microbiome on drug response, the Multidisciplinary Team at Pitié-Salpêtrière Hospital is actively searching for ongoing clinical trials in Europe. The team is particularly interested in trials exploring microbiome-modulating interventions that may complement HER2-targeted therapies and traditional chemotherapy. By participating in such trials, the team aims to gain insights into the interplay between the microbiome and drug response in HER2-positive breast cancer, ultimately guiding more personalized and effective treatment strategies for the patient.',
        },
        {
          category: 'Gastrointestinal Disorders',
          casedesc:
            'A 30-year-old male, recently diagnosed with Crohn\'s disease in Copenhagen, Scandinavia, is collaborating with his gastroenterologist at Rigshospitalet. Microbiome sequencing has unveiled an altered composition characterized by increased levels of Escherichia coli. The Multidisciplinary Team suspects a potential correlation between this microbial profile and responses to immunosuppressive medications commonly used in Crohn\'s disease treatment. Actively seeking clinical trials in Scandinavia, the team aims to explore microbiome-based interventions that could enhance drug response and improve outcomes for individuals with inflammatory bowel disorders',
        },
        {
          category: 'Type 2 Diabetes',
          casedesc:
            'A 45-year-old male in Paris, Europe, newly diagnosed with type 2 diabetes, is working closely with his endocrinologist at Pitié-Salpêtrière Hospital. Microbiome sequencing has identified an imbalance in gut microbiota, marked by a reduced abundance of Akkermansia muciniphila. The Multidisciplinary Team suspects that this microbiome composition may impact responses to insulin therapy. Actively searching for clinical trials in Europe, the team is exploring microbiome-focused interventions to modulate drug responses and enhance glycemic control in individuals with type 2 diabetes.',
        },
        {
          category: 'Rheumatological Conditions',
          casedesc:
            'In San Francisco, Jane, a 35-year-old woman recently diagnosed with lupus, grapples with joint pain, fatigue, and skin rashes alongside a family history of autoimmune diseases. Microbiome sequencing reveals a notable dysbiosis, with a Firmicutes to Bacteroidetes ratio of 1.5, indicating an imbalance associated with autoimmune conditions. Jane, an avid advocate for holistic wellness, maintains a vegetarian lifestyle and practices daily yoga. While she explores her diagnosis, the Lupus Research Institute in San Francisco initiates a search for clinical trials exploring microbiome-based interventions for lupus, recognizing the potential benefit in addressing her gut dysbiosis.',
        },

        {
          category: 'Rheumatological Conditions',
          casedesc:
            'Michael, a 40-year-old man in New York, has managed lupus symptoms with medication for years, seeking new approaches for an improved quality of life. Microbiome sequencing uncovers low microbial diversity, with a Shannon Diversity Index of 3.2, indicative of reduced beneficial commensal bacteria. A fitness enthusiast, Michael incorporates a nutrient-rich diet and regular exercise into his lifestyle. Recognizing the need for innovative solutions, the Lupus Foundation of New York takes the lead in identifying clinical trials focused on microbiome-targeted therapies for lupus. Michael, appreciative of this proactive approach, considers participation to restore a healthy microbial balance.',
        },

        {
          category: 'Rheumatological Conditions',
          casedesc:
            'In Oslo, 28-year-old Sarah, initially diagnosed with rheumatoid arthritis, experiences lupus-like symptoms and explores unconventional treatments. Microbiome sequencing indicates gut dysbiosis, with a significant elevation in pathogenic species such as Escherichia coli and a decrease in anti-inflammatory Bifidobacterium. Sarah, a wellness blogger, follows a gluten-free and dairy-free diet and practices mindfulness meditation. The Autoimmune Research Center in Oslo, recognizing the significance of microbiome interventions, actively searches for clinical trials addressing gut dysbiosis. Sarah, appreciative of this initiative, eagerly considers participation, hoping for positive outcomes for both her rheumatoid arthritis and lupus symptoms.',
        },
        {
          category: 'Rheumatological Conditions',
          casedesc:
            '32-year-old Alex in Bergen faces a recent diagnosis of undifferentiated connective tissue disease, exhibiting symptoms akin to lupus and other autoimmune conditions. Microbiome sequencing unveils an altered gut microbiota composition, with a significant decrease in anti-inflammatory Faecalibacterium prausnitzii and an increase in pro-inflammatory Prevotella. Alex, a dedicated cyclist, attributes their well-being to an active lifestyle and a Mediterranean-inspired diet. Taking a proactive approach, the Bergen Autoimmune Institute initiates a search for clinical trials exploring the role of the microbiome in autoimmune diseases. Alex, keen to contribute to advancements, embraces the opportunity to participate, hoping for personalized treatment options for undifferentiated connective tissue disease.',
        },
      ] 
  
    
    
      

    }
    const dataset_back = {
   
        cases: [
          {
            category: 'Hematologic cancers',
            casedesc:
              'A 55-year-old female presents with persistent fatigue and night sweats. A lymph node biopsy reveals a diagnosis of follicular lymphoma. Molecular testing identifies the presence of a t(14;18) translocation involving the BCL2 gene. The patient\'s disease is characterized by overexpression of the BCL2 protein. Given this molecular profile, the medical team in Paris, France, is actively seeking clinical trials exploring novel BCL2 inhibitors for follicular lymphoma.',
        
          },
          {
            category: 'Hematologic cancers',
            casedesc:
              'A 60-year-old male is diagnosed with chronic lymphocytic leukemia (CLL) following routine blood tests. Flow cytometry and FISH testing reveal a deletion of the 17p chromosome (del17p). Additionally, TP53 sequencing detects a pathogenic mutation - Variant c.581C>A (p.Pro194Thr). Due to the high-risk molecular profile, the oncology team in New York City, USA, is searching for clinical trials investigating novel therapies for CLL with del17p and TP53 mutations.',
     
          },
          {
            category: 'Various Cancers with NGS and VUS',
            casedesc:
              'A 50-year-old male with pancreatic adenocarcinoma underwent NGS, revealing a Variant of Unknown Significance (VUS) in the TP53 gene - Variant c.123A>G (p.Thr41Ala). The patient is exploring clinical trials tailored to tumors with TP53 alterations. He resides in Tokyo, Japan.',
         
          },
          {
            category: 'Various Cancers with NGS and VUS',
            casedesc:
              'A 55-year-old female with ovarian cancer underwent NGS, identifying a VUS in the BRCA1 gene - Variant c.5417C>T (p.Ser1806Leu). Clinical trial options focusing on BRCA1-related therapies are being considered. She is based in Sydney, Australia.',
         
          },
          {
            category: 'Various Cancers with NGS and VUS',
            casedesc:
              'A 45-year-old male with metastatic prostate cancer had NGS, revealing a VUS in the PTEN gene - Variant c.355G>A (p.Arg119His). The patient is actively seeking clinical trials targeting PTEN pathway alterations. He is located in New York City, USA.',
         
          },
          {
            category: 'Various Cancers with NGS and VUS',
            casedesc:
              'A 60-year-old female with glioblastoma underwent NGS, identifying a VUS in the IDH1 gene - Variant c.394C>T (p.Arg132Trp). The patient is exploring clinical trials for glioblastoma with IDH1 mutations. She resides in Paris, France.',
         
          },
          {
            category: 'Lung Cancer Variations',
            casedesc:
              'A 60-year-old male with squamous cell carcinoma of the lung, previously treated with platinum-based chemotherapy, is now exploring clinical trials targeting novel immune checkpoint inhibitors. The patient resides in Tokyo, Japan.',
         
          },
          {
            category: 'Lung Cancer Variations',
            casedesc:
              'A 55-year-old female diagnosed with small-cell lung cancer presents with relapse after initial chemotherapy. The tumor board is considering participation in trials for new targeted therapies. The patient is located in Chicago, USA.',
         
          },
          {
            category: 'Lung Cancer Variations',
            casedesc:
              'A 45-year-old male with extensive-stage small-cell lung cancer and a history of heavy smoking is seeking clinical trials involving combination therapies. The patient is based in London, UK.',
         
          },
          {
            category: 'Lung Cancer Variations',
            casedesc:
              'A 65-year-old female with non-small cell lung cancer (adenocarcinoma) harboring an ALK rearrangement has experienced disease progression after prior ALK inhibitor treatment. Clinical trial options for next-generation ALK inhibitors are being explored. The patient resides in Melbourne, Australia.',
         
          },  {
            category: 'Breast Cancer Variations',
            casedesc:
              'A 40-year-old female diagnosed with triple-negative breast cancer is considering clinical trials exploring immunotherapies after completing neoadjuvant chemotherapy. The patient is located in Toronto, Canada.',
         
          },

          {
            category: 'Breast Cancer Variations',
            casedesc:
              'A 55-year-old male with estrogen receptor-positive, HER2-negative breast cancer, currently on hormonal therapy, is interested in trials investigating novel endocrine therapies. The patient resides in Sao Paulo, Brazil.',
         
          },


          {
            category: 'Breast Cancer Variations',
            casedesc:
              'A 50-year-old female with HER2-positive breast cancer resistant to standard HER2-targeted therapies is actively seeking clinical trials for HER2-targeted antibody-drug conjugates. The patient is based in Los Angeles, USA.',
         
          },
          {
            category: 'Breast Cancer Variations',
            casedesc:
              'A 60-year-old male diagnosed with inflammatory breast cancer is exploring clinical trials for innovative combination therapies after completing standard chemotherapy. The patient resides in Barcelona, Spain.',
         
          },

          {
            category: 'Colorectal Cancer Variations',
            casedesc: 'A 55-year-old male with a history of hypertension presents with advanced colorectal cancer involving the sigmoid colon, accompanied by abdominal pain, weight loss, and changes in bowel habits. Metastases are observed in the liver and regional lymph nodes. Molecular profiling reveals a complex tumor genome with mutations in the APC, TP53, and KRAS genes. Additionally, microsatellite instability (MSI) is detected, suggesting potential responsiveness to immunotherapy. The molecular tumor board is challenged with developing a personalized treatment plan, taking into account the tumor\'s heterogeneity and the patient\'s overall health.'
                      
          },

          {
            category: 'Colorectal Cancer Variations',
            casedesc: 'A 40-year-old female with no significant medical history presents with early-onset colorectal cancer localized to the rectum. Family history includes a father diagnosed with colorectal cancer at 45 and a maternal grandmother with ovarian cancer. Molecular analysis reveals a germline mutation in the DNA mismatch repair (MMR) genes, indicative of Lynch syndrome. The tumor exhibits high microsatellite instability, and the molecular tumor board is tasked with determining the optimal treatment strategy, considering the patient\'s age, genetic predisposition, and potential impact on family members.'
                      
          },

          {
            category: 'Colorectal Cancer Variations',
            casedesc: 'A 65-year-old male with a history of type 2 diabetes and hypertension, previously treated for stage II colon cancer 10 years ago, now presents with metastatic colorectal cancer to the liver. Molecular profiling reveals mutations in the BRAF and PIK3CA genes, contributing to resistance to standard chemotherapeutic agents. The tumor board hypothesizes that identifying clinical trials targeting BRAF and PIK3CA mutations, as well as exploring combinations of targeted therapies and immunotherapies, could provide novel treatment avenues. They aim to search for trials assessing the efficacy of emerging therapies in the context of BRAF-mutant colorectal cancers resistant to standard treatments'
                      
          },
        ],
      };

      const categories = Array.from(new Set(dataset.cases.map((caseItem) => caseItem.category)));


      const renderCases = () => {
        const filteredCases = selectedCategory
          ? dataset.cases.filter((caseItem) => caseItem.category === selectedCategory)
          : dataset.cases;
    
        return filteredCases.map((caseItem, caseIndex) => (
          <div key={caseIndex} className="mb-3">
     <div key={caseIndex} className="mb-3">
            <p className="prose mb-0" style={{userSelect: 'none'}}>{caseItem.casedesc}</p>
            {caseItem.requestID && <a href={`/trials/${caseItem.requestID}`}>View Trials</a>}
            {!caseItem.requestID && <a href={`/request?casedesc=${encodeURIComponent(caseItem.casedesc)}`}>Request trials</a>}

            {' | '}  <a href="#" onClick={() => handleExtractData(caseItem)}>Structured Data</a>
   {(selectedCase && (caseItem.casedesc == selectedCase.casedesc)) &&  <CaseCardModal caseData={selectedCase} showModal={selectedCase!=null} onClose={() => setSelectedCase(null)} />}

  
 </div>
            </div>

          
        ));
      };
    return (
        <Container>
                  <Helmet>
        <title>Datasets</title>
        <meta name="description" content="Synthetic Patient Case Datasets for improving reliability of LLMs for Eligibility Assessment in Clinical Research. Hematologic cancer, Variant of Unknown Significance, Lung Cancer, Breast Cancer, and Colorectal Cancer variations. Representative of the jobs of Molecular Tumor Boards." />
      </Helmet>
        <Row className="mt-md-5">
          <h2>Datasets</h2>
          <p className="subtitle">Synthetic Cases Dataset - Microbiome-based therapeutics</p>
          <p>Read more about <a  style={{color: 'rgb(121, 190, 213)' }} href="https://www.nature.com/articles/s41579-021-00667-9" target="_blank">Microbiome-based therapeutics (Sorbana et al., 2022 Nature)</a></p>
          <Col sm="12" md={{ size: 12 }}>
            <div className="mb-3">
            <Button
              color="secondary"
              onClick={() => setSelectedCategory(null)}
              style={{ marginRight: '10px',marginBottom: '10px', backgroundColor: selectedCategory ? '' : '#007bff' }}
            >
              All
            </Button>
            {categories.map((category, index) => (
              <Button
                key={index}
                color="secondary"
                onClick={() => setSelectedCategory(category)}
                style={{
                  marginRight: '10px', marginBottom: '10px',
                  backgroundColor: selectedCategory === category ? '#007bff' : '',
                  borderColor: selectedCategory === category ? '#007bff' : '',
                }}
              >
                {category}
              </Button>
              ))}
            </div>
            {renderCases()}
          </Col>
        </Row>
        <div> <a className="btn btn-secondary" href="/request">New Job</a></div>
       
       
 
      </Container>
    );
};

export default DatasetCase;
  
