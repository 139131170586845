import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingIcon from './LoadingIcon'; // Import your loading icon component
import TrialResults from './TrialResults';
import { useParams } from 'react-router-dom'; // Assuming you are using React Router for routing
import config from '../config';
import { Helmet } from 'react-helmet';
import { Modal, Button, Form, Dropdown, DropdownItem, DropdownMenu } from 'react-bootstrap';
import Contact from './Contact'
import { FaMobile } from 'react-icons/fa';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FaRedo } from 'react-icons/fa';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import PatientExtractionFeedbackForm from './PatientExtractionFeedbackForm';
import TrialStreamingComponent from './TrialStreamingComponent';
import ReactGA from 'react-ga4';

const TrialTabs = ({ request, data }) => {
  const [key, setKey] = useState('unclassified');
  const { reportID } = useParams(); // Get the reportID from the URL params
  const server_url = config.server_url;
  const [trials, setTrials] = useState(data);
  const [tmp, setTmp] = useState(false);
  const [noResultFound, setNotResultFound] = useState(null);
  const [patientData, setPatientData] = useState(request);
 // console.log("patient DAta init with request", request);

  const [trialQueries, setTrialQueries] = useState(null);
  // State to manage the visibility of the queries
  const [showQueries, setShowQueries] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [caseD, setCaseD] = useState({
    ageRange: "", 
    parentDisease: "",
    subtype: "",
    biomarkers: [],
    genes: [],
    pastTreatments: "",
    treatmentOptions: "",
    countries: [],
    cities: [],
    // Add other properties as needed
  });
  const [isDescriptionInQueryVisible, setIsDescriptionInQueryVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  // Assuming caseD.modifiedDate is a string representing a date

  const [formattedDateRun, setFormattedDateRun] = useState();
  const [formattedDate, setFormattedDate] = useState();
  const [loadingCase, setLoadingCase] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showFullList, setShowFullList] = useState(false);
  const [showRedoCase, setShowRedoCase] = useState(true);
  const [isconfirmedCase, setConfirmedCase] = useState(false);

  const findModelTranslation = (model) => {
    if (model == 'HUMAN_EXPERT') {
      return "Expert verified";
    }
    if (model == 'OPENAI_MODEL_4') {
      return "TrialMatchLLM-GPT-4-1106";
    }
    if(model=='OPENAI_MODEL_4_TURBO_PREVIEW'){
      return "TrialMatchLLM-GPT-4-turbo";
    }
    if (model == 'OPENAI_MODEL_35') {
      return "TrialMatchLLM-GPT-3.5";
    }
    if (model == 'OPENAI_MODEL_16k') {
      return "TrialMatchLLM-gpt-3.5-turbo-16k";
    }
    if (model == 'FINE_TUNED_GPT_CASE_SHORT_SYSTEM_PROMT') {
      return "TrialMatchLLM-ft:gpt-3.5-turbo-1106:8lCffJTJ";
    }
    if (model == 'MISTRAL_MODEL_MEDIUM') {
      return "TrialMatchLLM-mistral-medium";
    }
    return model;
  }

  const handleTypeSelection = (selectedType, index) => {
    const updatedBiomarkers = [...caseD.biomarkers];
    updatedBiomarkers[index].type = selectedType;
    setCaseD((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
  };

  const handleNameChange = (name, index) => {
    const updatedBiomarkers = [...caseD.biomarkers];
    updatedBiomarkers[index].name = name;
    setCaseD((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
  };

  const handleValueChange = (name, index) => {
    const updatedBiomarkers = [...caseD.biomarkers];
    updatedBiomarkers[index].value = name;
    setCaseD((prevCaseD) => ({ ...prevCaseD, biomarkers: updatedBiomarkers }));
  };

  const handleShowMore = () => {
    setShowFullList(!showFullList);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFeedbackChange = (e) => setFeedback(e.target.value);

  const addBiomarker = () => {
    setCaseD((prevCaseD) => ({
      ...prevCaseD,
      biomarkers: [...prevCaseD.biomarkers, { type: '', name: '', value: '' }],
    }));
  };


  const handleExtractCaseGoodJob = () => {

    const storedEmail = localStorage.getItem('userEmail');
    var email = storedEmail;

    fetch(`${server_url}/api/report/${reportID}/case_description/${caseD.id}/good_job/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    })
      .then((response) => response.json())
      .then((data) => {
      //  console.log("feedback saved!", data);
        setConfirmedCase(true);
      });

  }


  const handleFeedbackSubmit = () => {
    // You can implement logic to handle the submitted feedback
    //console.log('Feedback submitted:', caseD);

    const storedEmail = localStorage.getItem('userEmail');
    caseD.email = storedEmail;

    fetch(`${server_url}/api/report/${reportID}/feedback/case_description/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(caseD),
    })
      .then((response) => response.json())
      .then((data) => {
      //  console.log("feedback saved!", data);
        setConfirmedCase(true);
      });


    // Close the modal after handling the feedback
    handleCloseModal();
  };
  const toggleFeedbackVisibility = () => {

  };

  const toggleDescription = () => {
    setIsDescriptionInQueryVisible(!isDescriptionInQueryVisible);
  };
  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };

  const handlePopoverShow = () => {
    setPopoverVisible(true);
  };

  const handlePopoverHide = () => {
    setPopoverVisible(false);
  };



  const handlePopoverToggle = () => {
    // Toggle the visibility of the popover
    setPopoverVisible((prevVisible) => !prevVisible);
  };


  const popover = (
    <Popover id="popover-count">
      <Popover.Body>
        <p className="mt-3 text-muted">The total counts represent the total number of <span style={{ fontWeight: '600' }}>interventional</span> trials currently <span style={{ fontWeight: '600' }}>"Recruiting"</span>
        
        {caseD.treatmentOptions.includes("microbiome") ? (
    <span></span>
  ) : (
   <span>, with interventions categorized as either  <span style={{ fontWeight: '600' }}> "Drug" OR "Biological" </span></span> 
  )} 

    found on clinicaltrials.gov. <br></br><br></br> Please note that there may be overlap in results between the searches. </p>
      </Popover.Body>
    </Popover>
  );

  function escapeRegExp(string) {
    if (string == null || string.length==0) {
      return ''; // Return empty string for null or undefined values
    }
    if (typeof string !== 'string') {
      return string; // Return   string if input is not a string
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
   
  }

  const highlightMatchingWords = () => {
  // Check if caseD is null
  if (!caseD) {
    return '';
  }

  // Extracting biomarker names and values into separate arrays
  const biomarkerNames = (caseD.biomarkers || []).map(biomarker => biomarker.name).filter(Boolean);
  const biomarkerValues = (caseD.biomarkers || []).map(biomarker => biomarker.value).filter(Boolean);

  const rxNames = caseD.pastTreatments || [];
  const optionsNames = caseD.treatmentOptionsNext || [];

  const genes = caseD.genes || [];
  const countries = caseD.countries || [];
  const cities = caseD.cities || [];

  const variableNameToTitleMap = new Map();
  const  variableNameToIconMap= new Map();

  // Check and add entries for caseD properties that may be null
  if (caseD.maincondition) {
    variableNameToTitleMap.set(caseD.maincondition.toLowerCase(), 'Main Condition');
    variableNameToIconMap.set(caseD.maincondition.toLowerCase(), '🩺');
  }
  if (caseD.parentDisease) {
    variableNameToTitleMap.set(caseD.parentDisease.toLowerCase(), 'Disease Category');
  }
  if (caseD.subtype) {
    variableNameToTitleMap.set(caseD.subtype.toLowerCase(), 'Disease Subtype or Qualifiers');
    variableNameToIconMap.set(caseD.subtype.toLowerCase(), '🏷️');
  }
    // Add entries for biomarker names to the map
    biomarkerNames.forEach(biomarkerName => {
      variableNameToTitleMap.set(biomarkerName.toLowerCase(), 'Biomarker');
      variableNameToIconMap.set(biomarkerName.toLowerCase(), '🔬');
    });

    // Add entries for biomarker values to the map
    biomarkerValues.forEach(biomarkerValue => {
      variableNameToTitleMap.set(biomarkerValue.toLowerCase(), 'Biomarker Value');
    });
  
    rxNames.forEach( Value => {
      variableNameToTitleMap.set(Value.toLowerCase(), 'Past or Active Treatment');
    });

    optionsNames.forEach( Value => {
      variableNameToTitleMap.set(Value.toLowerCase(), 'Intervention of interest (future option)');

      variableNameToIconMap.set(Value.toLowerCase(), '💊');
    });


    genes.forEach(gene => {
      variableNameToTitleMap.set(gene.toLowerCase(), 'Gene');
    });


    countries.forEach(c => {
      variableNameToTitleMap.set(c.toLowerCase(), 'Country');
      variableNameToIconMap.set(c.toLowerCase(), '📌');
    });


    cities.forEach(c => {
      variableNameToTitleMap.set(c.toLowerCase(), 'City');
      variableNameToIconMap.set(c.toLowerCase(), '📌');
    });

 /*
    const regexString = [
      ...biomarkerNames,
      ...biomarkerValues,
      ...rxNames,
      ...optionsNames,
      ...genes,
      ...countries,
      ...cities,
      ...Object.values(caseD).filter(Boolean),
    ].join('|');

*/

   // Combining all values for case-insensitive search and escpace special char such as + or -
    const regexString = [
      ...biomarkerNames.map(name => escapeRegExp(name)),
      ...biomarkerValues.map(value => escapeRegExp(value)),
      ...rxNames.map(rx => escapeRegExp(rx)),
      ...optionsNames.map(option => escapeRegExp(option)),
      ...genes.map(gene => escapeRegExp(gene)),
      ...countries.map(country => escapeRegExp(country)),
      ...cities.map(city => escapeRegExp(city)),
      ...Object.values(caseD).filter(Boolean).map(value => escapeRegExp(value)),
    ].join('|');

    const regex = new RegExp(regexString, 'gi');


    const description = (patientData?.patientDescription) || '';



    const descriptionReplaced = description.replace(regex, match => {
      const title = variableNameToTitleMap.get(match.toLowerCase());
      var icon = variableNameToIconMap.get(match.toLowerCase()); 
      if(icon==null) {
        icon ='';
      }
      return `<span class="underlined" title="${icon}${title || match}">${match}</span>`;
    });
 

    return descriptionReplaced;
  };


  const fetchDataForTrials = async (trialIDs, formData) => {
 
    if (trialIDs == null || trialIDs == "") {
      console.log("trialIDs is empty, contine");
      return;
    }

    formData.id=reportID;
    const trialIDArray = trialIDs.split(',').map(trialID => trialID.trim());
   // console.log("trialIDArray", trialIDArray);
 
    for (const trialID of trialIDArray) {

      if (trialID == null || trialID == "") {
        console.log("trialID is empty, contine");
        continue;
      }

      fetch(`${server_url}/api/report/onetrial/${trialID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();  
        })
        .then(dataRes => {
          if (dataRes == null) {
            console.log("Server returned null");
            return; // Skip to the next trial if data is null
          }
 
          // Update trials state and find index using callback form of setTrials
          setTrials(prevTrials => {
            const updatedTrials = prevTrials.slice(); // Make a copy of the trials array
            const index = updatedTrials.findIndex(trial => trial.identifier === trialID);
            if (index !== -1) {
              // Update the trial at the found index with the new data
              updatedTrials[index] = { ...updatedTrials[index], ...dataRes };
            } else {
              // If trial with trialID is not found, push the new trial to the array
              updatedTrials.push(dataRes);
            //  console.log("Could not find trial to update. Added a new trial.");
            }
            return updatedTrials; // Return the updated array to setTrials
          });
/*
          // Find the index of the trial with the matching trialID
          const index = trials.findIndex(trial => trial.identifier === trialID);
          if (index !== -1) {
            // Update the trial at the found index with the new data
            const updatedTrials = [...trials];
            updatedTrials[index] = { ...updatedTrials[index], ...dataRes };
            setTrials(updatedTrials);
          }else{
            console.log("could not find trial to update")
            trials.push(dataRes);
          } */
        })
        .catch(error => {
          console.error('Error fetching trial data:', error);
        });
    }
  };
 



  useEffect(() => {
    const fetchTrials = async () => {
      try {

        const responseQueries = await fetch(`${server_url}/api/report/${reportID}/trialqueries`);
        const queries = await responseQueries.json();
        if (queries != null) {
        //  console.log("set trial queries");
          setTrialQueries(queries.queries);
 

          if(queries.caseDesc !=null){
            setCaseD(queries.caseDesc);
          }

          if(queries.totalResults!=null && queries.totalResults==0){
            setNotResultFound(true)
          }
        }
        // Replace 'yourEndpoint' with the actual endpoint to fetch trials based on reportID
        const response = await fetch(`${server_url}/api/report/${reportID}`);
        const data = await response.json();

        if (data.request!=null && data.request.caseDesc != null) {
          setPatientData({
            patientDescription: data.request.caseDesc,
            email: data.request.email
          });
        }

        if (queries.queries != null && queries.queries.length > 0) {
          const modifiedDateRun = new Date(queries.queries[queries.queries.length - 1].modifiedDate);

          const fdr = modifiedDateRun.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          setFormattedDateRun(fdr);
        }

        if (queries.caseDesc != null) {
          const modifiedDate = new Date(queries.caseDesc.modifiedDate);
          const fd = modifiedDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
          setFormattedDate(fd);
        }

        if (
          (data.assessed != null && data.assessed !== trials) ||
          (data.trials != null && data.trials !== trials)
        ) {
          setTrials(data.assessed || data.trials);
        }
        if (data.trials != null && data.caseDesc != null) {
          setCaseD(data.caseDesc);
        }
        if (data.assessed == null) {

          var caseDesc = data.request.caseDesc;
          var trialIDs = data.request.trialIDs;
          var email = data.request.email;
          var formData = {
            caseDesc,
            trialIDs,
            email
            // Additional data from your LLM response
          };

    


         await fetchDataForTrials(trialIDs, formData);
     //   console.log("has processed all trials ID by now?");
       

/* FIXME ---- 
          // Call your backend or LLM logic here and set the result in App state
          fetch(`${server_url}/api/eligibilitycheck/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          })
            .then((response) => response.json())
            .then((data) => {

              if (data === null) {
                console.log("Server returned null.");
                setNotResultFound(true);
                // Handle the null case here if needed
              } else {
                if (data.assessed = null || data.assessed.length == 0) {
                  setNotResultFound(true);
                } else {
                  setNotResultFound(null);
                }
                if (data.assessed != null && Array.isArray(data.assessed)) {
                  setTrials(data.assessed);
                }
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setNotResultFound(true);
              //FIXME show error message?
            });
         END FIXME */
        } 

      
      } catch (error) {
        console.error('Error fetching trials:', error);
      }
    };



    //console.log("noResultFound", noResultFound);
    // Check if reportID exists in the URL and if trials need to be fetched
    var shouldUpdate = (!trials || trials.length === 0 || tmp == true) && (noResultFound == null);
    if (reportID && (shouldUpdate)) {
    //  console.log("fetching trials from tabs...", reportID);
      fetchTrials();
    } else {
      //console.log("no need to fetch trials", shouldUpdate, reportID, trials, data, tmp);
    }
  }, [reportID, trials, tmp]);  // Include 'trials' in the dependency array

  if (!trials) {
    return <div>Loading...</div>;
  }


  // Function to toggle the visibility of queries
  const toggleQueries = () => {
    setShowQueries(!showQueries);

  
      ReactGA.event({
        category: 'Link',
        action: 'Click',
        label: 'showQueries'
      });
    

  };

  // Function to render individual query information
  const renderQuery = (query) => (
    <div key={query.type}>
      <h5>{getFriendlyText(query.type)}</h5>
      <p>Total count of Trials: {query.totalResults}</p>
    </div>
  );

  const getFriendlySymbols = (type) => {
    // Add more cases as needed
    switch (type) {

      case 'WITH_COUNTRIES_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬 + 📌+ 💊';

      case 'WITH_DISTANCE_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬 + 1700 km + 💊 ';

      case 'WITHOUT_COUNTRY_AND_SUBTYPE':
        return '🩺 + 🏷️ + 🔬  + 🌍 + 💊';

        case 'WITH_COUNTRIES_AND_SUBTYPE_NO_INTEREST':
          return '🩺 + 🏷️ + 🔬 + 📌';
  
        case 'WITH_DISTANCE_AND_SUBTYPE_NO_INTEREST':
          return '🩺 + 🏷️ + 🔬 + 1700 km';
  
        case 'WITHOUT_COUNTRY_AND_SUBTYPE_NO_INTEREST':
          return '🩺 + 🏷️ + 🔬  + 🌍';

      case 'WITH_GENES':
        return '🩺 + 🧬 + 📌 + 💊';
      case 'WITH_GENES_AND_DISTANCE':
        return '🩺 + 🧬 + 1700 km + 💊';
      case 'WITHOUT_COUNTRY':
        return '🩺 + 🔬 + 🌍 + 💊';
      case 'WITH_GENE_WITHOUT_COUNTRY':
        return '🩺 + 🧬 + 🌍 + 💊';
      case 'WITH_DISTANCE':
        return '🩺 + 🔬 + 1700 km + 💊';
      case 'WITH_COUNTRIES':
        return '🩺 + 🔬 + 📌 + 💊';
      case 'WITH_COUNTRIES_BROADER_DISEASE':
        return '⚕️ + 🔬 + 📌 + 💊';
      case 'WITH_PARENT_DISEASE':
        return '⚕️ + 🔬 + 1700 km + 💊';
      case 'WITH_PARENT_DISEASE_EVERYWHERE':
        return '⚕️ + 🔬 + 🌍 + 💊';
      case 'PARENT_DISEASE_WITH_COUNTRIES_WITHOUT_BIOMARKERS':
        return '⚕️ + 📌 + 💊';
      case 'PARENT_DISEASE_WITH_COUNTRIES_WITHOUT_INTERVENTION':
        return '⚕️ + 🔬 + 📌 ';
      case 'WITH_GENES_DISTANCE_BROADER_DISEASE':
        return '⚕️ + 🧬 + 1700 km + 💊';
      case 'WITH_BIOMARKERS_NO_DISEASE':
        return '🔬 + 📌 + 💊';
      case 'WITH_BIOMARKERS_AND_DISTANCE':
        return '🔬 + 1700 km + 💊';
      case 'WITH_BIOMARKERS_EVERYWHERE':
        return '🔬 + 🌍 + 💊';
      case 'WITH_COUNTRIES_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 📌';
      case 'WITH_DISTANCE_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 1700 km';
      case 'WITHOUT_COUNTRY_WITHOUT_INTERVENTION':
        return '🩺 + 🔬 + 🌍';
      case 'WITH_COUNTRIES_NO_BIOMARKERS':
        return '🩺 + 📌 + 💊';
      case 'WITH_DISTANCE_NO_BIOMARKERS':
        return '🩺 + 1700 km + 💊';
      case 'WITHOUT_COUNTRY_NO_BIOMARKERS':
        return '🩺 + 🌍 + 💊';


        case 'WITH_COUNTRIES_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 📌';
        case 'WITH_DISTANCE_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 1700 km';
        case 'WITHOUT_COUNTRY_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺 + 🏷️ + 🌍';
  

      case 'WITH_COUNTRIES_DISEASE_ONLY':
        return '🩺 + 📌';
      case 'WITH_DISTANCE_DISEASE_ONLY':
        return '🩺 + 1700 km';
      case 'WITHOUT_COUNTRY_DISEASE_ONLY':
        return '🩺 + 🌍';

        

      default:
        return type;
    }
  };
  

  // Function to convert enum type to human-friendly text
  const getFriendlyText = (type) => {
    // Add more cases as needed
     switch (type) {
 
       case 'WITH_COUNTRIES_AND_SUBTYPE':
         return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';

       case 'WITH_DISTANCE_AND_SUBTYPE':
         return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🛣️1700km from preferred location + 💊intervention of interest';

       case 'WITHOUT_COUNTRY_AND_SUBTYPE':
         return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';

         case 'WITH_COUNTRIES_AND_SUBTYPE_NO_INTEREST':
          return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 📌Preferred location';
 
        case 'WITH_DISTANCE_AND_SUBTYPE_NO_INTEREST':
          return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🛣️1700km from preferred location';
 
        case 'WITHOUT_COUNTRY_AND_SUBTYPE_NO_INTEREST':
          return '🩺Main Disease + 🏷️ Subtype|Qualifiers + 🔬Biomarkers + 🌍Everywhere';
 

      case 'WITH_GENES':
        return '🩺Main Disease + 🧬 Genes + 📌Preferred location + 💊intervention of interest';
      case 'WITH_GENES_AND_DISTANCE':
        return '🩺Main Disease + 🧬 Genes + 🛣️1700km from preferred location + 💊intervention of interest';
      case 'WITHOUT_COUNTRY':
        return '🩺Main Disease + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_GENE_WITHOUT_COUNTRY':
        return '🩺Main Disease + 🧬 Genes + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_DISTANCE':
        return '🩺Main Disease + 🔬Biomarkers + 🛣️1700km from preferred location + 💊intervention of interest';
      case 'WITH_COUNTRIES':
        return '🩺Main Disease + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_COUNTRIES_BROADER_DISEASE':
        return '⚕️Disease Category + 🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_PARENT_DISEASE':
        return '⚕️Disease Category + 🔬Biomarkers + 🛣️1700km from preferred location + 💊intervention of interest';
      case 'WITH_PARENT_DISEASE_EVERYWHERE':
        return '⚕️Disease Category + 🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';
      case 'WITH_GENES_DISTANCE_BROADER_DISEASE':
        return '⚕️Disease Category + 🧬Genes + 1700km from preferred location + 💊intervention of interest';


        case 'PARENT_DISEASE_WITH_COUNTRIES_WITHOUT_BIOMARKERS':
          return '⚕️Disease Category + 📌Preferred location + 💊intervention of interest';
        case 'PARENT_DISEASE_WITH_COUNTRIES_WITHOUT_INTERVENTION':
          return '⚕️Disease Category + 🔬Biomarkers + 📌Preferred location ';


      case 'WITH_BIOMARKERS_NO_DISEASE':
        return '🔬Biomarkers + 📌Preferred location + 💊intervention of interest';
      case 'WITH_BIOMARKERS_AND_DISTANCE':
        return '🔬Biomarkers + 🛣️1700km from preferred location + 💊intervention of interest';
      case 'WITH_BIOMARKERS_EVERYWHERE':
        return '🔬Biomarkers + 🌍Everywhere + 💊intervention of interest';


      case 'WITH_COUNTRIES_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 📌Preferred location';
      case 'WITH_DISTANCE_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 🛣️1700km from preferred location';
      case 'WITHOUT_COUNTRY_WITHOUT_INTERVENTION':
        return '🩺Main Disease + 🔬Biomarkers + 🌍Everywhere';


        case 'WITH_COUNTRIES_NO_BIOMARKERS':
          return '🩺Main Disease + 📌Preferred location + 💊intervention of interest ';
        case 'WITH_DISTANCE_NO_BIOMARKERS':
          return '🩺Main Disease + 🛣️ 1700km from preferred location + 💊intervention of interest';
        case 'WITHOUT_COUNTRY_NO_BIOMARKERS':
          return '🩺Main Disease + 🌍Everywhere + 💊intervention of interest';



          
        case 'WITH_COUNTRIES_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺Main Disease + 🏷️Subtype|Qualifiers + 📌Preferred location';
        case 'WITH_DISTANCE_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺Main Disease + 🏷️Subtype|Qualifiers + 🛣️ 1700km from preferred location';
        case 'WITHOUT_COUNTRY_DISEASE_ONLY_AND_SUBTYPE':
          return '🩺Main Disease + 🏷️Subtype|Qualifiers + 🌍Everywhere';


          case 'WITH_COUNTRIES_DISEASE_ONLY':
            return '🩺Main Disease + 📌Preferred location';
          case 'WITH_DISTANCE_DISEASE_ONLY':
            return '🩺Main Disease + 🛣️ 1700km from preferred location';
          case 'WITHOUT_COUNTRY_DISEASE_ONLY':
            return '🩺Main Disease + 🌍Everywhere';

      default: 
        return type;
    }
  };


  const askAgain = () => {
    const requestID = reportID;
    const casedID = caseD.id;

    setLoadingCase(true);


    fetch(`${server_url}/api/report/${requestID}/casedesc/${casedID}/askAgain`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the fetched trial data
       // console.log('Fetched Redo:', data);
        //TODO refresh criteria
        setCaseD(data);
        const modifiedDate = new Date(data.modifiedDate);
        const fd = modifiedDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        setFormattedDate(fd);

      })
      .catch((error) => {
        console.error('Error during fetch:', error);
        // Handle errors as needed
      }).finally(() => {
        setLoadingCase(false);
        setShowRedoCase(false);
      });
  };

  const getAgeRangeDescription = (ageRange) => {
    switch (ageRange) {
      case 'CHILD':
        return 'Child';
      case 'ADULT':
        return 'Adult';
      case 'OLDER_ADULT':
        return 'Older Adult';
      default:
        return ageRange;
    }
  };

  let unclassifiedTrials = [];
  let eligibleTrials = [];
  let ineligibleTrials = [];
  
  if ((trials !== null) && Array.isArray(trials)) {
    unclassifiedTrials = trials.filter((trial) => trial.eligibilityStatus === null || trial.eligibilityStatus === undefined);
    //const unclassifiedTrials = trials.filter((trial) => trial.eligibilityStatus === null);
    eligibleTrials = trials.filter((trial) => trial.eligibilityStatus === 'Eligible');
    ineligibleTrials = trials.filter((trial) => trial.eligibilityStatus !== 'Eligible' && (trial.eligibilityStatus !== null && trial.eligibilityStatus !== undefined));
    // Further processing with filtered arrays
  } else {
    // Handle the case when trials is null
    console.log("Trials is null or not an array: ", trials);
  }
  

  const CustomTabHeader = ({ icon, textForMobile, text, count }) => (

    <div className="d-flex justify-content-between trial-res-tab"  >
      <div className="d-md-none"> {/* Visible on mobile devices */}
        {count > 0 && <span>{icon} </span>}
        <span>{textForMobile} ({count})</span>
      </div>
      <div className="d-none d-md-block"> {/* Visible on medium and larger screens */}
        {count > 0 && <span>{icon} </span>}
        <span>{text} ({count})</span>
      </div>
    </div>

  );

  if (eligibleTrials == null) {
    eligibleTrials = [];
  } else if (eligibleTrials.length > 0) {

    // setKey("eligible");
    //FIXME this cause infinite loop of rendering
  }
  if (ineligibleTrials == null) {
    ineligibleTrials = [];
  }

  return (
    <div className="mt-3  ">
      <Helmet>
        <title>Results Job #{reportID}</title>
      </Helmet>
      
    
      {patientData && (<>
        <b>Patient Description</b>
  <p className="mb-4">{patientData.patientDescription}</p></>
)}

      <div className="mb-4">
        <p>   {unclassifiedTrials.length > 0 && (
          <span title="TrialMatchLLM is processing those trials. This may take some time. 
    You will receive an email once the results are ready." >
            <LoadingIcon /> {unclassifiedTrials.length} Trials under assessment; 
          </span>
        )}   {eligibleTrials.length + ineligibleTrials.length} Trials assessed.     <span>


<div>
{( (!caseD.maincondition || caseD.maincondition.length==0) &&  unclassifiedTrials.length==0 && eligibleTrials.length == 0 && ineligibleTrials.length == 0 ) && (
  <p style={{color:'orange'}}>TrialMatchLLM couldn't find any condition/disease mentioned in the patient description. Clinical trials are typically matched based on specific medical conditions. Please try a new search. </p>
)}

    </div>


            {trialQueries != null && trialQueries.length > 0 && (

              <button style={{
                background: 'none',
                border: 'none',
                color: 'rgb(121, 190, 213)',
                textDecoration: 'underline',
                cursor: 'pointer',
                padding: 0,
                margin: 0,
              }} onClick={toggleQueries}>View Search Queries</button>

            )}

            <Modal show={showQueries} onHide={toggleQueries} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Trial Search Queries</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                {caseD && (
                  <div style={{ backgroundColor: 'purple', color: 'white', borderColor: 'purple', padding: '5px' }}>

                    <h5> Extracted Patient Data</h5>
                    <div>
                      <a className="secondary-link" style={{marginBottom:'8px', paddingLeft:'8px'}} onClick={toggleDescription}>
                        {isDescriptionInQueryVisible ? 'Hide Description' : 'Show Description'}{' '}<br></br>
                      </a>
                      {isDescriptionInQueryVisible && (
                        <div style={{padding:'8px'}} dangerouslySetInnerHTML={{ __html: highlightMatchingWords() }} />
                      )}

                    </div>




                    <table className="table" style={{ marginBottom: '5px' , marginTop:'14px'}} >

                      <thead className="thead-dark"  > {/* You can use thead-light for a light background */}
                        <tr>
                        <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      🩺  
      <br />
      Main Disease 
    </div>
  </th>
  <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      ⚕️
      <br />
      Category
    </div>
  </th>
  <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      🔬
      <br />
      Biomarkers
    </div>
  </th>
  <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      🧬
      <br />
      Genes
    </div>
  </th>
  <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      💊
      <br />
      Interest
    </div>
  </th>
  <th>
    <div style={{ whiteSpace: 'nowrap' }}>
      📌
      <br />
      Location
    </div>
  </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>

                          <td> {caseD.ageRange != null ? getAgeRangeDescription(caseD.ageRange) + (caseD.exactAge != null ? " (" + caseD.exactAge + "y), " : ", ") : ""}
  {caseD.maincondition || 'none found'}    {caseD.subtypes && caseD.subtypes.length > 0 && (
    <span>  <br />
        {'🏷️'}
        {caseD.subtypes.map((subtype, index) => (
            <span key={index}>{subtype}</span>
        ))}
    </span>
)}
                            {Array.isArray(caseD.metastases) && caseD.metastases.length > 0 && (
                              <span>
                                <br />
                                Metastases: {caseD.metastases.filter(Boolean).join(', ')}
                              </span>
                            )}  </td>
                          <td> {caseD.parentDisease}</td>
                          <td>
                            {Array.isArray(caseD.biomarkers) ? (
                              caseD.biomarkers.length > 0 ? (
                                caseD.biomarkers.filter(Boolean).map((biomarker, index) => (
                                  <div key={index}>{biomarker.name}</div>
                                ))
                              ) : (
                                <span className="text-muted font-italic">none found</span>
                              )
                            ) : (
                              <span className="text-muted font-italic">none found</span>
                            )}
                          </td>
                          <td>{Array.isArray(caseD.genes) ? (caseD.genes.length > 0 ? caseD.genes.filter(Boolean).join(', ') : <span className="text-muted font-italic">none found</span>) : <span className="text-muted font-italic">none found</span>}</td>
                          <td> {Array.isArray(caseD.treatmentOptions) ? (
                            caseD.treatmentOptions.length > 0 ? (
                              <>
                              {showFullList
                                ? caseD.treatmentOptions.filter(Boolean).join(', ')
                                : caseD.treatmentOptions.slice(0, 4).filter(Boolean).join(', ')}
                              {caseD.treatmentOptions.length > 4 && !showFullList && (
                                <>
                                  {' '}
                                  <span
                                    className="text-primary cursor-pointer" style={{cursor:'pointer'}}
                                    onClick={handleShowMore}
                                  >
                                    show more... 
                                  </span>
                                </>
                              )}
                            </>
                            ) : (
                              <span className="text-muted font-italic">none found</span>
                            )
                          ) : (
                            <span className="text-muted font-italic">none found</span>
                          )}</td>
                          <td>
                            {Array.isArray(caseD.countries) ? (caseD.countries.length > 0 ? caseD.countries.filter(Boolean).join(', ') : <span className="text-muted font-italic">none found</span>) : <span className="text-muted font-italic">none found</span>}{' '}
                            {Array.isArray(caseD.cities) ? (caseD.cities.length > 0 ? caseD.cities.filter(Boolean).join(', ') : null) : null}
                          </td>

                        </tr>
                      </tbody>
                    </table>
                    <div style={{ fontSize: 'small', textAlign: 'right' }}>Extracted: {formattedDate} • {findModelTranslation(caseD.llmmodel)} • {isDescriptionInQueryVisible && !isconfirmedCase && (
                      <button  onClick={() => handleExtractCaseGoodJob()}  style={{ color: 'white', backgroundColor: 'green' }} title="Confirm that AI accurately extracted patient description">
                        <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '5px' }} />
                      </button>
                    )}
                      {isconfirmedCase && <span style={{ color: 'white', backgroundColor: 'green' }}>Extraction Validated! </span>}
                      {!isconfirmedCase && <button onClick={() => handleShowModal()} title="Teach AI | Send feedback to make AI perform better for you"> <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '5px' }} />  </button>}
                      {showRedoCase && (<button title="Ask again AI" onClick={askAgain} disabled={loadingCase}>
                        {loadingCase ? <LoadingIcon /> : null}
                        <FaRedo style={{ marginRight: '3px' }} /></button>)}</div>


                  </div>
                )}


                <table className="table mt-3">
                  <thead>
                    <tr >
                      <th>  <span className="d-none d-md-inline">Search for interventional clinical trials that are currently enrolling participants<br></br><small className="text-muted" style={{fontWeight:'normal'}}>From more precise to broader</small></span>
  <span   className="d-md-none">Search</span> </th>
                      <th>Total Count   <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="right"
                        overlay={popover}
                      >
                        <span
                          className="info-icon"
                          onMouseOver={handlePopoverShow}
                          onMouseOut={handlePopoverHide}
                          onFocus={handlePopoverShow}  // Add onFocus event handler
                          onBlur={handlePopoverHide}   // Add onBlur event handler
                          onTouchStart={handlePopoverShow} // Add onTouchStart event handler for mobile
                          onTouchEnd={handlePopoverHide}   // Add onTouchEnd event handler for mobile
                          onClick={handlePopoverToggle}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} /><br></br><small className="text-muted" style={{fontWeight:'normal'}}>Interventional trials</small>
                        </span>
                      </OverlayTrigger>  </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trialQueries && trialQueries.map((query) => (
                      <tr key={query.id}  >
                        <td  className={query.totalResults === 0 ? "zero-results" : ""}>
                        <span className="d-none d-md-inline">{getFriendlyText(query.type)}</span>
  <span title={getFriendlyText(query.type)} className="d-md-none">{getFriendlySymbols(query.type)}</span>  </td>
                        <td className={query.totalResults === 0 ? "zero-results" : "non-zero-results"}>{query.totalResults}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ fontSize: 'small', textAlign: 'right' }}>Run: {formattedDateRun}</div>
                <p className="mt-4 text-muted px-3">Next, TrialMatchLLM assesses all those identified trials based on the patient's full description to determine potential eligibility. It categorizes trials as either likely eligible or not eligible and suggests the closest actively recruiting location if a preferred location is specified.<br></br>The unique <span style={{ fontWeight: '600' }}>top 5 analyzed results are displayed</span> on TrialMatchLLM, along with symbols indicating which search parameters matched.</p>                
                <p style={{ color: 'gray' }} className="mt-3 px-3">Are you missing a trial, or would you like to access the full list of results? <a onClick={toggleContactForm} className="link" style={{ cursor: 'pointer' }}>Please report feedback</a></p>      {showContactForm && (
                  <Contact page="FEEDBACK" className="px-3"></Contact>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleQueries}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Send Feedback</Modal.Title>
              </Modal.Header>
              <Modal.Body><div className="row"> <div className="col-md-8" style={{ padding: "15px" }}>
                <div style={{ position: "sticky", top: "15px" }}>
                  <div style={{ backgroundColor: "purple", color: "white", padding: "15px" }}>
                    <h5>Patient Description</h5>
                    <span dangerouslySetInnerHTML={{ __html: highlightMatchingWords() }} />
                  </div><div style={{ padding: "15px" }}> If the extracted information shown on the right is already correct, please confirm AI accuracy:
                    <Button onClick={() => handleExtractCaseGoodJob()} className="mx-2" variant="success" title="Confirm that AI accurately extracted patient description">
                      <FontAwesomeIcon icon={faThumbsUp} /> Good Job
                    </Button></div>    {isconfirmedCase && <span style={{ color: 'green' }}>Extraction Validated! You can close this dialog.</span>}
                </div>
              </div>
                {/* Right side with form controls */}
                <div className="col-md-4">
                  <p className="text-muted">Here, you teach AI to properly extract patient description, so that it can perform better next time. This is your expert feedback.</p>
                  <Form>
                    <Form.Group controlId="ageRangeDropdown">
                      <Form.Label>Age Range:</Form.Label>
                      <Form.Control
                        as="select"
                        value={caseD.ageRange}
                        onChange={(e) => setCaseD((prevCaseD) => ({
                          ...prevCaseD,
                          ageRange: e.target.value,
                        }))}
                      >
                        <option value="">Select Age Range</option>
                        <option value="CHILD">Child</option>
                        <option value="ADULT">Adult</option>
                        <option value="OLDER_ADULT">Older Adult</option>
                        <option value="NOT_PROVIDED">Not Provided</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please select an age range.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Label className="mt-3">Exact Age:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={caseD.exactAge}
                        onChange={(e) => setCaseD((prevCaseD) => ({ ...prevCaseD, exactAge: e.target.value }))}
                      />
                    <Form.Group controlId="feedbackTextArea" className="mt-3">
                      <Form.Label>Disease Category:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={caseD.parentDisease}
                        onChange={(e) => setCaseD((prevCaseD) => ({ ...prevCaseD, parentDisease: e.target.value }))}
                      />

                      <Form.Label className="mt-3">Main disease:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your feedback here..."
                        value={caseD.maincondition}
                        onChange={(e) => setCaseD((prevCaseD) => ({ ...prevCaseD, maincondition: e.target.value }))}
                      />

                      {caseD.parentDisease != null && caseD.parentDisease.toLowerCase() === "cancer" && <>
                        <Form.Label className="mt-3">Metastases:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Type your feedback here, or leave empty if none in text..."
                          value={caseD.metastases}
                          onChange={(e) => setCaseD((prevCaseD) => ({ ...prevCaseD, metastases: e.target.value }))}
                        /></>}

                      <Form.Label className="mt-3">Disease subtype & qualifiers:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Type your feedback here (e.g. chronic, newly diagnosed, luminal A, refractory, ...), or leave empty if none provided in text..."
                        value={caseD.subtypes}
                     
                        onChange={(e) => {
                          const oCondArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, subtypes: oCondArray }));
                        }}
                      />


                      <Form.Label className="mt-3">Biomarkers:</Form.Label>


                      {/* Display type in dropdown and name in input */}
                      {caseD.biomarkers && caseD.biomarkers.map((biomarker, index) => (
                        <div key={index}>
                          <Dropdown>
                            <Dropdown.Toggle variant="default" id={`dropdown-${index}`}>
                              {biomarker.type || 'Choose Type'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* Add your options for the type dropdown as needed */}
                              <Dropdown.Item onClick={() => handleTypeSelection('MOLECULAR', index)}>
                                MOLECULAR
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('MICROBIOME', index)}>
                                MICROBIOME
                              </Dropdown.Item>

                              <Dropdown.Item onClick={() => handleTypeSelection('CLINICAL', index)}>
                                CLINICAL
                              </Dropdown.Item>
                              {/* Add other options as needed */}
                            </Dropdown.Menu>
                          </Dropdown>

                          <Form.Control
                            type="text"
                            placeholder="Biomarker Name"
                            value={biomarker.name}
                            onChange={(e) => handleNameChange(e.target.value, index)}
                          />
                          <Form.Control
                            type="text"
                            placeholder="Biomarker's Patient Value"
                            value={biomarker.value}
                            onChange={(e) => handleValueChange(e.target.value, index)}
                          />
                        </div>
                      ))}

                      <Button variant="secondary" onClick={addBiomarker}>
                        Add Biomarker
                      </Button>
                      <br></br>
                      <Form.Label className="mt-3">Genes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Type your genes here, separated by commas..."
                        value={(caseD.genes && caseD.genes.join(', ')) || ''}
                        onChange={(e) => {
                          const genesArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, genes: genesArray }));
                        }}
                      />

                      
                      <Form.Label className="mt-3">Co-conditions:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's co-conditions (e.g. pregnancy) or comorbidities, separated by comma..."
                        value={caseD.otherConditions}
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, otherConditions: rxPastArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Past treatments:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type patient's past or active treatments, separated by comma..."
                        value={caseD.pastTreatments}
                        onChange={(e) => {
                          const rxPastArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, pastTreatments: rxPastArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Intervention(s) of interest:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Type interventions of interest here..."
                        value={caseD.treatmentOptions}
                        onChange={(e) => {
                          const rxArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, treatmentOptions: rxArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred country:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred countries here, separated by commas..."
                        value={(caseD.countries && caseD.countries.join(', ')) || ''}
                        onChange={(e) => {
                          const countriesArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, countries: countriesArray }));
                        }}
                      />

                      <Form.Label className="mt-3">Preferred city:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your preferred cities here, separated by commas..."
                        value={(caseD.cities && caseD.cities.join(', ')) || ''}
                        onChange={(e) => {
                          const citiesArray = e.target.value.split(',').map(value => value.trim());
                          setCaseD((prevCaseD) => ({ ...prevCaseD, cities: citiesArray }));
                        }}
                      />
                    </Form.Group>
                  </Form></div></div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleFeedbackSubmit}>
                  Submit Feedback
                </Button>
              </Modal.Footer>
            </Modal>

          </span></p>

      </div>


      <Tabs
        id="trialTabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >

     <Tab eventKey="unclassified"   title={<CustomTabHeader icon="" text="Trials to Classify"  textForMobile="Classify" count={unclassifiedTrials.length} />}  >
     {unclassifiedTrials.length > 0 && (
     <p> <LoadingIcon /> {unclassifiedTrials.length} Trials below are being assessed for eligibility. Once completed, they will be moved to either 'eligible' or 'ineligible' categories.</p>
     )}
     <TrialResults patientData={patientData} trials={unclassifiedTrials} isToClassify={true} queries={trialQueries}/>
     {unclassifiedTrials.length > 0 && patientData && patientData.email && (
<p style={{ color: 'darkgoldenrod', backgroundColor: '#ffc10724',   textAlign:'center', padding:'8px' }}>
You will receive an email at <b>{patientData.email}</b><br></br> once TrialMatchLLM has completed classifying the trials (reload the page afterward). 
</p>
)}

   </Tab>

   


        <Tab id="eligible-tab" eventKey="eligible" title={<CustomTabHeader icon="🟢" text="Potentially eligible trials" textForMobile="Potentially eligible" count={eligibleTrials.length} /> }     >
          <TrialResults patientData={patientData} trials={eligibleTrials} isToClassify={false}  queries={trialQueries} />
        </Tab>
        <Tab eventKey="ineligible" title={<CustomTabHeader icon="🚫" text="Likely not a good match" textForMobile="Ineligible" count={ineligibleTrials.length} queries={trialQueries} />}  >
          <TrialResults patientData={patientData} trials={ineligibleTrials} isToClassify={false} queries={trialQueries}  />
        </Tab>
      </Tabs>

    {/*   <TrialStreamingComponent requestId={reportID}></TrialStreamingComponent> */}

      <div> <a className="btn btn-secondary" href="/request">New Search</a></div>

    </div>

  );
};

export default TrialTabs;
