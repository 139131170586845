const config = {
 
  server_url_PROD: 'https://trialmatchllm.com',
  server_url_DEV: 'http://localhost:9079',
  server_url: 'https://trialmatchllm.com',


  ga4_ID_PROD: 'G-9BHHD4T52Y',
  ga4_DEV: 'G-1111',
  ga4_ID: 'G-9BHHD4T52Y'


  // Add more configuration properties as needed
};

export default config;